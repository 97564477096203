import React, { useEffect, useState, useRef, useContext } from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import TaskOverview from "../taskoverview/TaskOverview";
import { Link } from "react-router-dom";
import ThemeContext from "../../theme/ThemeContext"
import { useTranslation } from 'react-i18next';
import './Calendar.css';
import { formatDate, formatBirth } from '.././dateUtils';
import { Container, Row, Col } from "react-bootstrap";
function ServerDay(props) {
 
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    assignedTasks,
    ...other
  } = props;
  
  const event = assignedTasks.find((task) =>
    dayjs(task.startTime).isSame(day, "day")
  );
  const eventType = event && event.task ? event.task.type : "";

  const isSelected =
    !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

  const markerColor =
    eventType === "meeting"
      ? "blue"
      : eventType === "birthday"
      ? "pink"
      : "red";

  return (
    <Badge
    
      key={day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? <span style={{ color: markerColor }}>●</span> : undefined
      }
    >
      <PickersDay
      
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        highlighted={isSelected}
      />
    </Badge>
  );
}

export default function Calendar({ assignedTasks }) {
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { t } = useTranslation();
  console.log(assignedTasks)

  const fakeFetch = (date, { signal }) => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        const daysInMonth = date.daysInMonth();
        const daysToHighlight = [];

        for (let i = 1; i <= daysInMonth; i++) {
          const currentDate = date.date(i);
          const event = assignedTasks.find((task) =>
            dayjs(task.startTime).isSame(currentDate, "day")
          );
          if (event) {
            daysToHighlight.push(i);
          }
        }

        resolve({ daysToHighlight });
      }, 500);

      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException("aborted", "AbortError"));
      };
    });
  };

  useEffect(() => {
    fetchHighlightedDays(selectedDate);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const handleDateSelect = (event) => {
    const selectedDateString = event.target.dataset.date;
    const selectedDate = selectedDateString ? dayjs(selectedDateString) : null;
    setSelectedDate(selectedDate);
  };

  const agendaEvents = assignedTasks.filter((task) =>
    selectedDate
      ? dayjs(task.startTime).isSame(selectedDate, "day")
      : false
  );
  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;

  console.log(agendaEvents);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <div >
        <DateCalendar
         
          defaultValue={dayjs()}
          loading={isLoading}
          onChange={(newValue) => setSelectedDate(newValue)}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: (props) => (
              <ServerDay {...props} assignedTasks={assignedTasks} className="text-light"/>
            ),
          }}
          slotProps={{
            
            day: {
              highlightedDays,
              assignedTasks, // Dodali smo assignedTasks prop ovde
            },
          }}
        />
        {selectedDate && (
          <div>
            <h2 className="d-flex align-items-center justify-content-center">
              {t("Agenda for")} {selectedDate.format("DD-MM-YYYY")}:
            </h2>
            {agendaEvents.length > 0 ? (
              <Col xs={12} className="px-1">
              {agendaEvents.map((assignedtask) => (
                 <TaskOverview
                    task={assignedtask.name}
                    description={assignedtask.description}
                    time={formatDate(assignedtask.startTime)}
                    location={assignedtask.location}
                    scheduledTaskId = {assignedtask._id}
                    
                  />
              ))}
            </Col>
            ) : (
              <p className="d-flex align-items-center justify-content-center">
                {t("No events found for this date")}.
              </p>
            )}
          </div>
        )}
      </div>
    </LocalizationProvider>
  );
}
