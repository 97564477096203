import React from "react";
import { Col, Row } from 'react-bootstrap';
import Button from "../cofButton/Button";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styles from './NotificationMessage.module.css'; // Uvoz modula CSS

const NotificationMessage = (props) => {
  const { assigned, time, notification, toPath } = props;
  const { t } = useTranslation();

  const squareClass = toPath ? `${styles.square} ${styles.withPath}` : `${styles.square}`;

  const content = (
    <div className={`${styles.notificationMessageContainer} ${squareClass} ${styles.rounded} ${styles.border} ${styles.border2} ${styles.p2} ${styles.borderDark} ${styles.my3} ${styles.textLight} ${styles.borderWhite} ${toPath ? styles.withPath : ''}`}>
      <Row className={`d-flex ${styles.flexGrow}`}>
        <Col className={styles.flexGrow}>
          <h6>{t("By")} {assigned}</h6>
        </Col>
        <Col className="text-end">
          <h6> <img style={{ height: '1.4rem', marginRight: '2px', backgroundColor: "white", borderRadius: "50%", padding: "0.1rem" }} src="../../../images/back-in-time.png" alt="back in time"></img>{time}</h6>
        </Col>
      </Row>
      <Row className={`text-start ${styles.flexGrow}`}>
        <h2 style={{fontSize:"24px"}}>{notification}</h2>
      </Row>
    </div>
  );

  // Dodajte Link samo ako postoji toPath, inače koristite obični div
  return toPath ? (
    <Link to={toPath} style={{ textDecoration: 'none', color: 'inherit' }}>{content}</Link>
  ) : content;
};

export default NotificationMessage;
