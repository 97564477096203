import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from "../cofButton/Button";

const FilterModal = ({ show, onHide, tasks, onFilter, onResetFilter }) => {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({
    taskId: '', // Inicijalno bez izabranog zadatka
    taskName: '', // Inicijalno prazno ime zadatka
    startTimeFrom: '',
    startTimeTo: '',
    location: '',
  });

  const handleApplyFilter = () => {
    console.log(filterData); 
    const selectedTask = tasks.find((task) => task._id === filterData.taskId);
    console.log(selectedTask);
    onFilter({
      ...filterData,
      taskName: selectedTask ? selectedTask.name : '', // Ako nema izabranog zadatka, postavite prazno ime
    });
    
  };
 
  const handleResetFilter = () => {
    setFilterData({
      taskId: '',
      taskName: '',
      startTimeFrom: '',
      startTimeTo: '',
      location: '',
    });

    onResetFilter();
  };

  return (
    <Modal show={show} onHide={onHide} style={{color:"white"}}>
      <Modal.Header closeButton style={{backgroundColor:"#1d4e5b"}}>
        <Modal.Title>{t("Filter")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:"#1d4e5b"}}>
        <Form>
          <Form.Group controlId="task">
            <Form.Label>{t("Task")}</Form.Label>
            <Form.Control
              as="select"
              value={filterData.taskId}
              onChange={(e) =>
                setFilterData({ ...filterData, taskId: e.target.value })
              }
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            >
              <option value="">{t("SelectTask")}</option>
              {tasks.map((task) => (
                <option key={task._id} value={task._id}>
                  {task.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="startTimeFrom">
            <Form.Label>{t("StartTime")}</Form.Label>
            <Form.Control
              type="datetime-local"
              value={filterData.startTimeFrom}
              onChange={(e)  =>
                setFilterData({ ...filterData, startTimeFrom: e.target.value })
              }
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
          <Form.Group controlId="startTimeTo">
            <Form.Label>{t("StartTimeTo")}</Form.Label>
            <Form.Control
              type="datetime-local"
              value={filterData.startTimeTo}
              onChange={(e) =>
                setFilterData({ ...filterData, startTimeTo: e.target.value })
              }
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
          <Form.Group controlId="location">
            <Form.Label>{t("Location")}</Form.Label>
            <Form.Control
              type="text"
              value={filterData.location}
              onChange={(e) =>
                setFilterData({ ...filterData, location: e.target.value })
              }
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{backgroundColor:"#1d4e5b"}}>
        <Button variant="secondary"  className="rounded-0 border-0" onClick={onHide}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={handleApplyFilter} className='rounded-0 border-0'>
          {t("ApplyFilter")}
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
