import {
    USER_NOTIFICATION_REQUEST,
    USER_NOTIFICATION_SUCCESS ,
    USER_NOTIFICATION_FAIL,
} from "../constants/notificationConstants"

const initialState = {
  notifications: null,
  loading: false,
  error: null,
};

export const getNotificationReducer = (state = initialState, action) =>{
    switch (action.type) {
        case USER_NOTIFICATION_REQUEST:
          return { ...state, loading: true };
        case USER_NOTIFICATION_SUCCESS:
          return { loading: false, notifications: action.payload };
        case USER_NOTIFICATION_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }


};