import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserAssignedTasks } from "../../actions/AssignedTaskActions";
import Calendar from "../../components/calendar/Calendar";
import TaskOverview from "../../components/taskoverview/TaskOverview";

const CalendarScreen = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userAssignedTasks = useSelector((state) => state.userAssignedTasks);

  const { loading, error, assignedTasks } = userAssignedTasks;
  console.log(userAssignedTasks)
  console.log(userInfo)
  
  useEffect(() => {
    if (userInfo) {
      // Ovde pozovite akciju za dohvat svih assignedTask-ova za korisnika
      dispatch(fetchUserAssignedTasks(userInfo._id));
    }
  }, [dispatch, userInfo._id]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          <Calendar assignedTasks={assignedTasks} /> {/* Koristite podatke iz Redux store-a */}
          {/* Renderujte detalje konkrentih obaveza, ako je potrebno */}

        </>
      )}
    </div>
  );
};

export default CalendarScreen;
