import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ThemeContext from '../../theme/ThemeContext'; 
import styles from './Button.module.css'; // Uvezite lokalni modul sa stilovima

const Button = ({ children, transparent, secondary, onClick, className, style }) => {
  const theme = useContext(ThemeContext);

  const buttonClass = transparent
    ? `${styles.button} ${styles.buttonTransparent}`
    : styles.button;

  const buttonColor = secondary
    ? theme.colors.secondaryColor // Ako je prop "secondary" postavljen na true, koristi secondaryColor
    : theme.colors.primaryColor; // Inače, koristi primaryColor

  return (
    <button
      style={{
        fontFamily: theme.fonts.primary,
        fontWeight: theme.fonts.weight, 
        color: theme.colors.fontcolor,
        backgroundColor: transparent ? 'transparent' : buttonColor, // Koristi buttonColor ovdje
        borderColor: theme.colors.fontcolor,
        ...style // Dodajte stil kao props
      }}
      className={`btn ${buttonClass} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  transparent: PropTypes.bool,
  secondary: PropTypes.bool, // Dodali smo prop za sekundarni stil
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object, // Dodajte prop za stil
};

Button.defaultProps = {
  transparent: false,
  secondary: false, // Postavili smo defaultni prop za secondary na false
  onClick: () => {},
  className: '',
  style: {}, // Postavite prazan objekat kao default za stil
};

export default Button;
