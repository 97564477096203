// reducers/staffReducer.js

import {
    FETCH_TRAINERS_REQUEST,
    FETCH_TRAINERS_SUCCESS,
    FETCH_TRAINERS_FAIL,
    RESET_TRAINERS,

    FETCH_STAFF_MEMBER_REQUEST,
    FETCH_STAFF_MEMBER_SUCCESS,
    FETCH_STAFF_MEMBER_FAIL,

    DELETE_TRAINER_REQUEST,
    DELETE_TRAINER_SUCCESS,
    DELETE_TRAINER_FAIL,

    CREATE_TRAINER_REQUEST,
    CREATE_TRAINER_SUCCESS,
    CREATE_TRAINER_FAIL,
    RESET_CREATE_TRAINER_STATE,

    EDIT_TRAINER_REQUEST, 
    EDIT_TRAINER_SUCCESS,
    EDIT_TRAINER_FAIL,
    EDIT_TRAINER_RESET

  } from '../../constants/staff/clubStaffConstants';
  
  const initialTrainersState = {
    loading: false,
    trainers: [],
    error: null,
  };
  
  export const staffReducer = (state = initialTrainersState, action) => {
    switch (action.type) {
      case FETCH_TRAINERS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TRAINERS_SUCCESS:
        return {
          ...state,
          loading: false,
          trainers: action.payload,
        };
      case FETCH_TRAINERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case RESET_TRAINERS: // Dodajte slučaj za reset akciju
        return {
          ...initialTrainersState, // Vraća se početno stanje trenera
        };
      default:
        return state;
    }
  };
  
  const memberinitialState = {
    loading: false,
    error: null,
    staffMember: {},
    success: false,
  };
  
  export const staffMemberReducer = (state = memberinitialState, action) => {
    switch (action.type) {
      case FETCH_STAFF_MEMBER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_STAFF_MEMBER_SUCCESS:
        return {
          ...state,
          loading: false,
          staffMember: action.payload,
        };
      case FETCH_STAFF_MEMBER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  

  export const deleteStaffReducer = (state = { trainers: [] }, action) => {
    switch (action.type) {
      // ... ostali slučajevi ...
      case DELETE_TRAINER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_TRAINER_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
        };
      case DELETE_TRAINER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };


  const createinitialState = {
    loading: false,
    error: null,
    trainer: {},
    success: false,
  };
  export const createTrainerReducer = (state = createinitialState, action) => {
    console.log(state)
    switch (action.type) {
      case CREATE_TRAINER_REQUEST:
        return { ...state, loading: true };
      case CREATE_TRAINER_SUCCESS:
        return { ...state, loading: false, success: true, trainer: action.payload };
      case CREATE_TRAINER_FAIL:
        return { ...state, loading: false, error: action.payload };
        case RESET_CREATE_TRAINER_STATE:
          return createinitialState; // Reset to initial state
      default:
        return state;
    }
  };

  const editInitialState = {
    loading: false,
    error: null,
    trainer: {},
    success: false,
  };

  export const editTrainerReducer = (state = editInitialState, action) => {
    switch (action.type) {
      case EDIT_TRAINER_REQUEST:
        return { loading: true };
      case EDIT_TRAINER_SUCCESS:
        return { loading: false, success: true, trainer: action.payload };
      case EDIT_TRAINER_FAIL:
        return { loading: false, error: action.payload };
      case EDIT_TRAINER_RESET: 
        return {};
      default:
        return state;
    }
  };