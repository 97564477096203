import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "../../components/table/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdmins, deleteAdmin } from "../../actions/AdminsAction";
import Button from "../../components/cofButton/Button";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DELETE_ADMIN_RESET } from "../../constants/AdminsConstants";

const SysAdminsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dohvatite admine i stanje učitavanja iz Redux store-a
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const adminsState = useSelector((state) => state.admins);
  const { loading, error, admins } = adminsState;

  const admindelete = useSelector((state) => state.deleteAdmin);
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = admindelete;


  const adminCreate = useSelector((state) => state.adminCreate);
  const { success: successCreate } = adminCreate;

  // Definišite kolone za AdminScreen
  const adminColumns = [
    { label: t("Name").toUpperCase(), dataKey: "name" },
    { label: t("Surname").toUpperCase(), dataKey: "surname" },
    { label: t("Email").toUpperCase(), dataKey: "email" },
    // Dodajte dugmad za edit, info, delete, addClub, addPlayer prema potrebi...
  ];
  
  const handleDeleteAdmin = (adminId) => {
    dispatch(deleteAdmin(adminId));
  };

  const createAdminHandler = () => {
    navigate("/admin/admins/create");
  };
  const handleAdminInfo = (adminId) =>{
    navigate(`/admin/admins/${adminId}/info`)
  }

  const editAdminHandler = (adminId) => {
    navigate(`/admin/admins/${adminId}/edit`);
  };

  useEffect(() => {
    // Ovde pozovite akciju za dohvatanje admina
    dispatch(fetchAdmins());
    dispatch({type:DELETE_ADMIN_RESET});
  }, [dispatch, successDelete, successCreate]);

  // Pomoćna funkcija za formatiranje podataka za tabelu
  const formatDataForTable = (admins) => {
    return admins.map((admin) => ({
      ...admin.user,
    }));
  };

  // Definišite koje akcije želite prikazati u tabeli
  const allowedActions = [
    { icon: faInfo, label: t("Info"),style: { backgroundColor: 'rgb(0, 198, 19)' } },
    { variant: "info", icon: faEdit, label: t("Edit") },
    { variant: "danger", icon: faTrash, label: t("Delete") },
  ];
  
  const actionHandlers = {
    [t("Delete")]: handleDeleteAdmin,
    [t("Edit")]: editAdminHandler,
    [t("Info")]: handleAdminInfo
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="m-0">{t("Administrators").toUpperCase()}</h2>
        <Button className="my-3 rounded-0 border-0" onClick={createAdminHandler}>
          <i className="fas fa-plus"></i> {t("NewAdmin")}
        </Button>
      </div>
      {/* Renderujte tabelu sa svim adminima */}
      <DataTable
        columns={adminColumns}
        data={formatDataForTable(admins)}
        loading={loading}
        error={error}
        actions={allowedActions}
        actionLabels={allowedActions.map((action) => action.label)}
        buttonStyle={{ margin: "5px", width:"40px" }} // Dodali smo prop za stil dugmadi
        actionHandlers={actionHandlers}
      />
    </Container>
  );
};

export default SysAdminsScreen;
