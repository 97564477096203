import React, { useState, useEffect  } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { register } from "../../../actions/userActions";
import { useDispatch } from "react-redux";
import Button from "../../../components/cofButton/Button";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/customInput/CustomInput";
const SignUpScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [role, setRole] = useState("PLAYER"); // Dodata uloga
  const registerUser= useSelector((state) => state.userRegister);
  const { loading, error, success } = registerUser;
  const [errorAlert, setErrorAlert] = useState(false);
  console.log(registerUser)
  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(
      register({
        name: firstName,
        surname: lastName,
        email,
        password,
        role // Dodata uloga
      })
    );
  };

  const handleCheckboxChange = (event) => {
    setIsSubscribed(event.target.checked);
  };
  useEffect(() => {
    console.log(success)
    if (success) {
        navigate(`/signin`);
    } else if (error) {
        setErrorAlert(true);
    }
}, [success, error]);
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ paddingTop: "100px" }}
    >
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <h2>{t("Sign up for CoF")}</h2>
        </Col>

        <Col xs={12}>
          <Form>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput 
              type="text"
              placeholder={t("FirstName")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput 
              type="text"
              placeholder={t("LastName")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
              type="text"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput 
              type="password"
              placeholder={t("Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "10px"}}>
              <Form.Check
                type="checkbox"
                label={t("SubscribeToNewsletter")}
                checked={isSubscribed}
                onChange={handleCheckboxChange}
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "10px" }}>
              <Form.Check
                type="radio"
                label={t("Player")}
                name="role"
                value="PLAYER"
                checked={role === "PLAYER"}
                onChange={() => setRole("PLAYER")}
              />
              <Form.Check
                type="radio"
                label={t("StaffMember")}
                name="role"
                value="STAFF"
                checked={role === "STAFF"}
                onChange={() => setRole("STAFF")}
              />
            </Form.Group>

            <Button className="w-100 rounded-0 border-0" transparent={false} onClick={handleSignIn}>
            {t("Completesignup")}
            </Button>
          </Form>
        </Col>

        <Col xs={12}>
        {t("By signing up you agree to our")}{" "}
          <a href="/terms-of-service">{t("terms of service")}</a>
        </Col>

        <Col
          xs={12}
          className="mx-auto d-flex justify-content-between"
          style={{ margin: "0.5rem" }}
        >
          <p>{t("Already have an account?")}</p>
          <Link to="/signin">
            <Button variant="outline-primary" className="rounded-0 border-0">{t("SignIn")}</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpScreen;
