import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaskDetails, editTask } from "../../actions/staff/tasksListActions";
import { useParams, useNavigate } from "react-router-dom";
import CustomInput from "../../components/customInput/CustomInput"
import Button from "../../components/cofButton/Button";
import { useTranslation } from 'react-i18next';
const TaskEditScreen = () => {
  const { clubId, taskId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");

  const infotaskDetails = useSelector((state) => state.fetchTaskDetails);
  const { loading, error, taskDetails } = infotaskDetails;
  console.log(infotaskDetails);
  console.log(taskDetails);
  
  const taskEdit = useSelector((state) => state.editTask);
  const { loading: editLoading, error: editError, success } = taskEdit;

  console.log(taskEdit);

  useEffect(() => {
    if (!taskDetails || taskDetails._id !== taskId) {
      dispatch(fetchTaskDetails(clubId, taskId));
    } else {
      setName(taskDetails.name);
      setType(taskDetails.type);
      setLocation(taskDetails.location);
      setDuration(taskDetails.duration);
      setDescription(taskDetails.description);
    }
  }, [dispatch, clubId, taskId, taskDetails]);

  useEffect(() => {
    if(success) {
      console.log(success);
      navigate("/tasks/list");
    }
  }, [success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const taskData = {
      name,
      type,
      location,
      duration,
      description,
    };

    dispatch(editTask(clubId, taskId, taskData));
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <h2>{t("EditTask").toUpperCase()}</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Ime</Form.Label>
              <CustomInput
                type="text"
                placeholder="Unesite ime"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.Label>Tip</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
              >
                <option value="">Izaberite tip</option>
                <option value="TRAINING">Training</option>
                <option value="MATCHDAY">Matchday</option>
                <option value="DINNER">Dinner</option>
                <option value="QUARANTINE">Quarantine</option>
                <option value="TEAMBUILDING">Teambuilding</option>
                <option value="FITNESS_TRAINING">Fitness Training</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="location">
              <Form.Label>Lokacija</Form.Label>
              <CustomInput
                type="text"
                placeholder="Unesite lokaciju"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="duration">
              <Form.Label>Trajanje</Form.Label>
              <CustomInput
                type="text"
                placeholder="Unesite trajanje"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Opis</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Unesite opis"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6", border:"none"}}
              />
            </Form.Group>
            <Button type="submit" variant="primary" className="rounded-0 border-0 mt-2" disabled={editLoading}>
              {editLoading ? "Izmena..." : t("EditTask")}
            </Button>
            {editError && <p className="text-danger mt-3">{editError}</p>}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default TaskEditScreen;
