import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getClubSettings, updateClubSettings } from '../../actions/staff/clubSettingActions';
import { useTranslation } from 'react-i18next';
import Button from "../../components/cofButton/Button";
import CustomInput from '../../components/customInput/CustomInput';
import { Container, Alert  } from 'react-bootstrap';
const FeatureClubSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    dailyStatusLimit: 0,
    hanldeBadFitnessStatus: '',
    hanldeBadMentalStatus: '',
    clubManager: '',
  });
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const settingClub = useSelector((state) => state.clubSettings);
console.log(userInfo)

  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);

  const { settings, loading, error } = settingClub;
  console.log(settings)

  useEffect(() => {
    dispatch(getClubSettings(userInfo.club));
  }, [dispatch, userInfo.club]);

  useEffect(() => {
    if (settings && settings.clubSettings) {
      const { dailyStatusLimit, hanldeBadFitnessStatus, hanldeBadMentalStatus, clubManager } = settings.clubSettings;

      setFormData({
        dailyStatusLimit: dailyStatusLimit || 0,
        hanldeBadFitnessStatus: hanldeBadFitnessStatus?._id || '',
        hanldeBadMentalStatus: hanldeBadMentalStatus?._id || '',
        clubManager: clubManager?._id || '',
      });
    }
  }, [settings]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ograničavanje vrednosti unosa za dailyStatusLimit
    if (name === "dailyStatusLimit") {
      const numValue = parseInt(value, 10);
      if (numValue < 1 || numValue > 24) {
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    const dataToSend = {
      ...formData,
      dailyStatusLimit: String(formData.dailyStatusLimit),
    };

    dispatch(updateClubSettings(userInfo.club, dataToSend))
      .then(() => {
        setIsUpdateSuccessful(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderDoctorOptions = () => {
    if (settings && settings.clubManagerCandidates) {
      return settings.clubManagerCandidates.map((doctor) => (
        <option key={doctor._id} value={doctor._id}>
          {`${doctor.name} ${doctor.surname}`}
        </option>
      ));
    }
    return null;
  };

  const renderMentalDoctorOptions = () => {
    if (settings && settings.hanldeBadMentalStatusCandidates) {
      return settings.hanldeBadMentalStatusCandidates.map((doctor) => (
        <option key={doctor._id} value={doctor._id}>
          {`${doctor.name} ${doctor.surname}`}
        </option>
      ));
    }
    return null;
  };

  const renderSecretaryOptions = () => {
    if (settings && settings.clubManagerCandidates) {
      return settings.clubManagerCandidates.map((secretary) => (
        <option key={secretary._id} value={secretary._id}>
          {`${secretary.name} ${secretary.surname}`}
        </option>
      ));
    }
    return null;
  };

  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }

  return (
    <div>
      <h2>{t("ClubSettings").toUpperCase()}</h2>

      {isUpdateSuccessful && (
        <div className="alert alert-success">
          {t('Uspešno ste sačuvali izmene')}
        </div>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
        <h6>Vas klub je {settings && settings.clubSettings.club.name}</h6>
        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="dailyStatusLimit">
            <Form.Label>{t("DailyStatusLimit")}</Form.Label>
            <Form.Control
              type="number"
              name="dailyStatusLimit"
              value={formData.dailyStatusLimit}
              onChange={handleInputChange}
              min="1"
              max="24"
              style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
            />
          </Form.Group>
          <Form.Group controlId="hanldeBadFitnessStatus">
            <Form.Label>{t("PhysicalDoctor")}</Form.Label>
            <Form.Control
              as="select"
              name="hanldeBadFitnessStatus"
              value={formData.hanldeBadFitnessStatus}
              onChange={handleInputChange}
              style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
            >
              <option value="">{t("SelectPhysicalDoctor")}</option>
              {renderDoctorOptions()}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="hanldeBadMentalStatus">
            <Form.Label>{t("MentalDoctor")}</Form.Label>
            <Form.Control
              as="select"
              name="hanldeBadMentalStatus"
              value={formData.hanldeBadMentalStatus}
              onChange={handleInputChange}
              style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
            >
              <option value="">{t("SelectMentalDoctor")}</option>
              {renderMentalDoctorOptions()}
            </Form.Control>
          </Form.Group>
          
          <Form.Group controlId="clubManager">
            <Form.Label>{t("Secretary")}</Form.Label>
            <Form.Control
              as="select"
              name="clubManager"
              value={formData.clubManager}
              onChange={handleInputChange}
              style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
            >
              <option value="">{t("SelectSecretary")}</option>
              {renderSecretaryOptions()}
            </Form.Control>
          </Form.Group>
          <Button type="submit" className="my-2 rounded-0 border-0">{t("SaveSettings")}</Button>
        </Form>
        </div>
      )}
    </div>
  );
};

export default FeatureClubSettings;
