import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';
import { subscribeToNotifications, unsubscribeFromNotifications } from '../actions/pushNotificationActions';

const NotificationToggle = ({ context }) => {
    const dispatch = useDispatch();
    const subscribeLoading = useSelector(state => state.notificationSubscribe);
    const { loading, error, success: SuccessSubscribe } = subscribeLoading;
    const unsubscribeLoading = useSelector(state => state.notificationUnsubscribe.loading);
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    console.log(subscribeLoading)
    useEffect(() => {
      // Ovdje možete postaviti početno stanje prekidača ako je potrebno
      // setNotificationEnabled(...)
    }, []);
  
    const resetNotificationPermissions = async () => {
      console.log("Resetovanje dozvola za notifikacije...");
    
      try {
        // Proverite trenutno stanje dozvola
        const currentPermission = Notification.permission;
    
        // Ako dozvole već granted ili denied, zatražite ponovno odobrenje
        if (currentPermission === 'granted' || currentPermission === 'denied') {
          Notification.requestPermission(function(permission) {
            if (permission === "granted") {
              console.log("Dozvole za notifikacije su resetovane.");
            } else {
              console.log("Korisnik je odbio ponovno odobrenje za notifikacije.");
            }
          });
        } else {
          // Ako dozvole nisu granted ili denied, ne morate ih resetovati, već samo ispisati poruku
          console.log("Dozvole za notifikacije već nisu aktivne.");
        }
      } catch (error) {
        console.error("Greška prilikom resetovanja dozvola za notifikacije:", error);
      }
    };
    
    
    
    
    
    
    
    
    
    
    const handleNotificationToggle = () => {
      if (notificationEnabled) {
        unsubscribe();
      } else {
        subscribe();
      }
      resetNotificationPermissions(); // Poziv resetNotificationPermissions() u zavisnosti od statusa obaveštenja
    };
    
    
    const subscribe = async () => {
      try {
        console.log("subscribe");
        let sw = await navigator.serviceWorker.ready;
  
        let push = await sw.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BKROG9SRCPvXzP8ib7FPpe3gXUAR_fVr8Ljvb__5W-qTtjirTwnIC-NS3XmlS7RWBuzeCxP6TpbQIrobKPDpZsY",
        });
  
        const formattedPush = push.toJSON();
  
        const subscriptionData = {
          endpoint: formattedPush.endpoint,
          p256dh: formattedPush.keys.p256dh,
          auth: formattedPush.keys.auth,
        };
  
        dispatch(subscribeToNotifications(subscriptionData));
        setNotificationEnabled(true);
      } catch (error) {
        console.error("Error subscribing to notifications:", error);
      }
    };
  
    const unsubscribe = async () => {
      try {
        console.log("unsubscribe");
        dispatch(unsubscribeFromNotifications());
        setNotificationEnabled(false); // Postavljamo na false kada se korisnik odjavi
      } catch (error) {
        console.error("Error unsubscribing from notifications:", error);
      }
    };
    console.log(context)
    useEffect(() => {
      // Proverite da li je korisnik pretplaćen na notifikacije
      if (context && context.isSubscribed) {
        // Ažurirajte stanje prekidača na osnovu statusa pretplate
        setNotificationEnabled(true);
      }
    }, [context]);
    return (
      <div>
        <Switch
          checked={notificationEnabled}
          onChange={handleNotificationToggle}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
        />
      </div>
    );
  };
  
  export default NotificationToggle;