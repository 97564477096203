import{
    SUBMIT_AGAIN_REQUEST,
    SUBMIT_AGAIN_SUCCESS,
    SUBMIT_AGAIN_FAIL,
  SUBMIT_AGAIN_RESET
} from "../constants/submitAgainConstants";

const initialState = {
  loading: false,
  error: null,
  success: false,
  dailyStatus: {},
};

export const submiAgaintReducer = (state = initialState, action) =>{
    switch (action.type) {
        case SUBMIT_AGAIN_REQUEST:
          return { ...state, loading: true };
        case SUBMIT_AGAIN_SUCCESS:
          return { loading: false, success:true, dailyStatus: action.payload };
        case SUBMIT_AGAIN_FAIL:
          return { loading: false, error: action.payload };
          case SUBMIT_AGAIN_RESET:
            return initialState;
        default:
          return state;

}
};