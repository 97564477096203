import React, { useEffect } from 'react';
import { Row, Col, Container, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/table/DataTable';
import { fetchTrainers, deleteTrainer } from '../../actions/staff/clubStaffActions'; // Dodajte import za akciju brisanja
import { useTranslation } from 'react-i18next';
import Button from "../../components/cofButton/Button";
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate, formatBirth } from '../../components/dateUtils';

const StaffScreen = () => {
  const { t } = useTranslation();
  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const { loading, error, trainers, success: successDelete } = useSelector(
    (state) => state.staff
  );
    
  useEffect(() => {
    dispatch(fetchTrainers(userInfo.club));
  }, [dispatch, userInfo.club, successDelete]);
  
  const navigate = useNavigate();
  console.log(userInfo);
  console.log(userInfo.club)
  console.log(trainers)

  const createTrainerHandler = () => {
    navigate(`/club/staff/create`);
  };

  const mappedTrainers = trainers ? trainers.map((trainer) => ({
    ...trainer,
    position: t(trainer.position),
  })) : [];

  const infoStuffHandler = (staffId) => {
    console.log(staffId);
    navigate(`/club/${userInfo.club}/staff/${staffId}`);

  };
  
   const editStaffHandler = (staffId) => {
    navigate(`/club/${userInfo.club}/staff/${staffId}/edit`);
   };

  const deleteTrainerHandler = (trainerId) => {
    if (window.confirm('Da li ste sigurni da želite da obrišete ovog trenera?')) {
      dispatch(deleteTrainer(userInfo.club, trainerId));
    }
  };

  const actionHandlers = {
    [t("Info")]: infoStuffHandler,
    [t("Delete")]: deleteTrainerHandler,
    [t("Edit")]: editStaffHandler,
  };

  const allowedActions = [
    { icon: faInfo,style: { backgroundColor: 'rgb(0, 198, 19)' }, label: t("Info") },
    { variant: "info", icon: faEdit, label: t("Edit") },
    { variant: "danger", icon: faTrash, label: t("Delete") },
  ];

  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }

  return (
    <div>
      <Row className="my-3">
        <Col>
          <h2>{t("TrainerList").toUpperCase()}</h2>
        </Col>
        <Col>
          <Button className="float-end rounded-0 border-0" onClick={createTrainerHandler}>
            <i className="fas fa-plus"></i> {t("CreateNewTrainer")}
          </Button>
        </Col>
      </Row>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataTable
          columns={[
            { label: t("Name").toUpperCase(), dataKey: 'name' },
            { label: t("Surname").toUpperCase(), dataKey: 'surname' },
            { label: t("Position").toUpperCase(), dataKey: 'position' },
            { label: t("Birthdate").toUpperCase(), dataKey: 'birthdate', format: formatBirth },
            { label: t("Education").toUpperCase(), dataKey: 'education' },
          ]}
          data={mappedTrainers}
          actions={allowedActions}
          actionLabels={[t("Info"), t("Edit"),  t("Delete")]}
          buttonStyle={{  marginRight: "5px",width: '40px' }}
          actionHandlers={actionHandlers}
        />
      )}
    </div>
  );
};

export default StaffScreen;
