import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadDocument } from '../actions/uploadAction';

const FileUploadComponent = ({ scheduledTaskId }) => { // Prima scheduledTaskId kao prop
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
  };

  const handleFileUpload = () => {
    if (!file) {
      setError('Please choose a file.');
      return;
    }

    setLoading(true);

    // Ovde pozivamo akciju uploadDocument i prosleđujemo callback funkciju koja će se pozvati nakon što server obradi zahtev
    dispatch(uploadDocument(file, scheduledTaskId, (data) => { // Prosleđujemo scheduledTaskId
      console.log('Server response:', data);
      setLoading(false);
    }));
  };

  return (
    <div>
      <h1>File Upload</h1>
      <input type="file" onChange={handleFileChange} />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleFileUpload} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
};

export default FileUploadComponent;
