import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editAdmin, fetchAdminById } from "../../actions/AdminsAction";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Button from "../../components/cofButton/Button";
import CustomInput from "../../components/customInput/CustomInput"

const EditAdminScreen = () => {
  const { adminId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const adminDetails = useSelector((state) => state.adminDetails);
  const { loading, error, admin } = adminDetails;

  const adminEdit = useSelector((state) => state.editAdmin);
  const { loading: editLoading, error: editError, success: editAdminSuccess } = adminEdit;

  useEffect(() => {
    if (!admin || !admin.length || admin.length === 0 || !admin[0].user || admin[0].user._id !== adminId) {
      dispatch(fetchAdminById(adminId));
    } else {
      setName(admin[0].user.name);
      setSurname(admin[0].user.surname);
      setEmail(admin[0].user.email);
      setPassword(admin[0].user.password);
    }
  }, [dispatch, adminId, admin, editAdminSuccess]);

  useEffect(() => {
    // Ako je upload uspešan, želimo da ponovo učitamo stranicu
    if (editAdminSuccess) {
      // Ovde možete implementirati logiku za ponovno učitavanje stranice
      window.location.reload(); // Ovo je jednostavno ponovno učitavanje stranice
    }
  }, [editAdminSuccess]); // useEffect će se ponovo izvršiti kada se promeni successUpload promenljiva


  const handleSubmit = (e) => {
    e.preventDefault();
    
    const adminData = {
      name,
      surname,
      email,
      password,
    };

    dispatch(editAdmin(adminData, adminId));
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={6} >
          <h2 className="text-center mt-3">{t("Edit Admin")}</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>{t("Name")}</Form.Label>
              <CustomInput
                type="text"
                placeholder={t("Enter name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="surname">
              <Form.Label>{t("Surname")}</Form.Label>
              <CustomInput
                type="text"
                placeholder={t("Enter surname")}
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>{t("Email")}</Form.Label>
              <CustomInput
                type="email"
                placeholder={t("Enter email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            {userInfo._id === adminId && ( // Conditionally render based on user ID
              <Form.Group controlId="password">
                <Form.Label>{t("Password")}</Form.Label>
                <CustomInput
                  type="password"
                  placeholder={t("Enter password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            )}
            <Button type="submit" variant="primary" disabled={editLoading} className="mt-3 rounded-0 border-0">
              {editLoading ? "Izmena..." : t("Edit Admin")}
            </Button>
            {editError && <p className="text-danger mt-3">{editError}</p>}
            {editAdminSuccess && <p className="text-success mt-3">Admin is edited</p>}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAdminScreen;
