import {React, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import Button from "../../components/cofButton/Button";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClub, editClub } from "../../actions/clubActions";
import CustomInput from "../../components/customInput/CustomInput";
import { useNavigate } from "react-router-dom";
import { RESET_CREATE_CLUB_STATE, EDIT_CLUB_RESET, GET_CLUB_RESET } from '../../constants/clubConstants'
const EditClubScreen = ()=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState('');
      const [pib, setPib] = useState('');
      const[address, setAddress]=useState('');
      const[email, setEmail]=useState('');
      const { clubId } = useParams();
      useEffect(()=>{
        dispatch({type: GET_CLUB_RESET});
      },[dispatch, clubId])
        const userLogin = useSelector((state) => state.userLogin);
        const { userInfo } = userLogin;
        const clubInfo = useSelector((state) => state.getClub);
        const { loading, error, clubs } = clubInfo;
        const clubEdit = useSelector((state) => state.editClub);
  const { loading: editLoading, error: editError, success } = clubEdit;

        console.log(clubs)
        useEffect(() => {if(!clubs){
            dispatch(getClub(clubId));
        } else{
            setName(clubs.name);
            setPib(clubs.pib);
            setAddress(clubs.address);
            setEmail(clubs.email);
        }
            
          }, [dispatch,clubs, clubId]);

      
      const handleUpdateInfo=(e) =>{
        e.preventDefault();
        const clubData = {
            name,
            pib,
            address,
            email
          };
      
          dispatch(editClub(clubData, clubId));
        
      }
      useEffect(() => {
        if (success) {
          // Preusmerite se na rutu "/admin/clubs"
          navigate("/admin/clubs");
          dispatch({ type: RESET_CREATE_CLUB_STATE });
          dispatch({ type: EDIT_CLUB_RESET})
        }
      }, [success, navigate]);
    return(

     <div>
        <Row className="text-center py-5">
            <Col>
            <h1> 
            {t("Edit Club")}
        </h1>
            </Col>
        </Row>
        <Row  className="justify-content-md-center">
                <Col xs={4} md={2} >
                <h4> {t("Name")}:</h4>
                </Col>
             <Col xs={8} className=" w-50"> 
             <Form.Group style={{ marginBottom: "10px" }}>
             <CustomInput 
                type="text"
                placeholder={t("Name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                />
                                </Form.Group>
             </Col>
            </Row>
            <Row  className="justify-content-md-center">
                <Col xs={4} md={2} >
                <h4> {t("PIB")}:</h4>
                </Col>
             <Col xs={8} className=" w-50"> 
             <Form.Group style={{ marginBottom: "10px" }}>
                <CustomInput
                 type="text"
                 placeholder={t("PIB")}
                 value={pib}
                 onChange={(e) => setPib(e.target.value)} />
                                </Form.Group>
             </Col>
            </Row>
            <Row  className="justify-content-md-center">
                <Col xs={4} md={2} >
                <h4> {t("Address")}:</h4>
                </Col>
             <Col xs={8} className=" w-50"> 
             <Form.Group style={{ marginBottom: "10px" }}>
                <CustomInput
                 type="text"
                 placeholder={t("Address")}
                 value={address}
                 onChange={(e) => setAddress(e.target.value)} />
                                </Form.Group>
             </Col>
            </Row>
            <Row  className="justify-content-md-center">
                <Col xs={4} md={2} >
                <h4> {t("clubEmail")}:</h4>
                </Col>
             <Col xs={8} className=" w-50"> 
             <Form.Group style={{ marginBottom: "10px" }}>
                <CustomInput
                 type="text"
                 placeholder={t("clubEmail")}
                 value={email}
                 onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
             </Col>
            </Row>
            <Row className="justify-content-center">
            <Button
                                className="w-75 rounded-0 border-0"
                                transparent={false}
                                onClick={handleUpdateInfo}
                            >
                                {t("UpdateInfo")}
                            </Button>
            </Row>
        
     </div>
    )
}
export default EditClubScreen;