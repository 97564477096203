import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/table/DataTable";
import {
  fetchTasksList,
  deleteTask,
} from "../../actions/staff/tasksListActions";
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Button from "../../components/cofButton/Button";
import { Container, Alert  } from 'react-bootstrap';

const TaskListScreen = () => {
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const { loading, error, tasks, success } = useSelector(
    (state) => state.tasks
  );
 
  const deleteTaskState = useSelector((state) => state.deleteTask)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = deleteTaskState;
  

  useEffect(() => {
    dispatch(fetchTasksList(userInfo.club));
  }, [dispatch, userInfo.club, successDelete]);

  const navigate = useNavigate();

  const createActionHandler = () => {
    navigate("/staff/tasks/create");
  };

  const mappedTasks = tasks
    ? tasks.map((task) => ({
        ...task,
        type: t(task.type),
      }))
    : [];

  const infoActionHandler = (taskId) => {
    navigate(`/club/${userInfo.club}/task/${taskId}`);
  };

  const editTaskHandler = (taskId) => {
    navigate(`/club/${userInfo.club}/task/${taskId}/edit`);
  };

  const deleteTaskHandler = async (taskId) => {
    if (
      window.confirm("Da li ste sigurni da želite da obrišete ovaj zadatak?")
    ) {
      dispatch(deleteTask(userInfo.club, taskId));
    }
  };

  const actionHandlers = {
    [t("Info")]: infoActionHandler,
    [t("Edit")]: editTaskHandler,
    [t("Delete")]: deleteTaskHandler,
  };

  const allowedActions = [
    {  icon: faInfo,style: { backgroundColor: 'rgb(0, 198, 19)' }, label: t("Info") },
    { variant: "info", icon: faEdit, label: t("Edit") },
    { variant: "danger", icon: faTrash, label: t("Delete") },
  ];

  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }
 

  return (
    <div>
      <Row className="my-3">
        <Col>
          <h2>{t("TaskList").toUpperCase()}</h2>
        </Col>
        <Col>
          <Button className="float-end rounded-0 border-0" onClick={createActionHandler}>
            <i className="fas fa-plus"></i> {t("CreateNewTask")}
          </Button>
        </Col>
      </Row>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataTable
          columns={[
            { label: t("Name").toUpperCase(), dataKey: "name" },
            { label: t("Type").toUpperCase(), dataKey: "type" },
            { label: t("Location").toUpperCase(), dataKey: "location" },
            { label: t("Duration").toUpperCase(), dataKey: "duration" },
            { label: t("Description").toUpperCase(), dataKey: "description" },
          ]}
          data={mappedTasks}
          actions={allowedActions}
          actionLabels={[t("Info"), t("Edit"), t("Delete")]}
          buttonStyle={{ marginRight: "5px", width: '40px' }}
          actionHandlers={actionHandlers}
        />
      )}
    </div>
  );
};

export default TaskListScreen;
