import {
CREATE_NEW_GROUP_FAIL,
CREATE_NEW_GROUP_REQUEST,
CREATE_NEW_GROUP_SUCCESS,
RESET_CREATE_NEW_GROUP_STATE
} from "../../constants/staff/playersGroupsConstatnts"

const initialState = {
  loading: false,
  playersGroups: [],
  error: null,
  success: false,
};

export const createNewGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_NEW_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        playersGroups: action.payload,
        error: null,
        success: true,
      };
    case CREATE_NEW_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        playersGroups: [],
        error: action.payload,
        success: false,
      };
    case RESET_CREATE_NEW_GROUP_STATE:
      return initialState; // Reset to initial state
    default:
      return state;
  }
};
