import { React, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/cofButton/Button";
import {
  fetchStaffMemberInfo,
  editStaffMemberInfo,
} from "../../actions/staff/info/staffInfoActions";
import { fetchContext } from "../../actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import PassportInfo from "../../components/common/PassportInfo";
import { EDIT_STAFF_MEMBER_INFO_RESET } from "../../constants/staff/info/staffInfoConstants";
import { editPassportInfo } from "../../actions/passportInfoActions";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CustomInput from "../../components/customInput/CustomInput";
import { Dropdown } from "react-bootstrap";
import { subscribeToNotifications, unsubscribeFromNotifications } from "../../actions/pushNotificationActions";
import NotificationStatusCheck from "../../components/NotificationStatusCheck";
import NotificationToggle from "../../components/NotificationToggle";
const StaffInfoScreen = () => {
    const { i18n } = useTranslation();

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
  };

  const languageOptions = [
    { value: "en", label: "English", flag: "../../img/united.png" },
    { value: "srb", label: "Serbian", flag: "https://example.com/flags/serbian.png" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  
  const subscribeLoading = useSelector(state => state.notificationSubscribe.loading);
  
  const unsubscribeLoading = useSelector(state => state.notificationUnsubscribe.loading);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [notification, setNotification] = useState({
    notificationSubscription: null,
  });
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const StaffInfo = useSelector((state) => state.fetchStaffMemberInfo);
  const { loading, error, staffMember } = StaffInfo;
  console.log(StaffInfo);
  const EditStaff = useSelector((state) => state.editStaffMemberInfo);
  const {
    loading: editLoading,
    error: editError,
    success: successUpdate,
  } = EditStaff;
  const editPassport = useSelector((state) => state.editPassportInfo);
  const {
    loading: passportLoading,
    error: passportError,
    success: passportUpdate,
  } = editPassport;

  const fetchUserContext = useSelector((state) => state.fetchUserContext);
  const {userInfo: context } = fetchUserContext;
  const handlePassportUpdate = (updatedInfo) => {
    const passportInfoData = {
      passportnum: updatedInfo.passportnum,
      nationality: updatedInfo.nationality,
      passportExpirationDate: updatedInfo.passportExpirationDate,
      passportPicture: updatedInfo.passportPicture,
    };
    console.log(updatedInfo);
    dispatch(editPassportInfo(userInfo._id, passportInfoData));
  };
  useEffect(() => {
    console.log("poziva se useEffect");
    dispatch(fetchContext(userInfo._id));
    dispatch(fetchStaffMemberInfo(userInfo._id));
  }, [dispatch, userInfo]);

  console.log(context)

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setSelectedImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [education, setEducation] = useState("");
  const [Position, setPosition] = useState("");
  const [age, setAge] = useState("");
  useEffect(() => {
    console.log(StaffInfo);
    console.log("poziva se useeffect u passportInfo");
    if (StaffInfo?.trainers?.staffPersonalInfo) {
      console.log("setuje se state ");
      setEducation(StaffInfo.trainers.staffPersonalInfo.education);
      setAge(StaffInfo.trainers.staffPersonalInfo.birthdate);
      setSelectedImage(StaffInfo.trainers.staffPersonalInfo.picture);
    }
  }, [StaffInfo]);

  const handleUpdateInfo = (e) => {
    e.preventDefault();
    console.log("radi");
    const StaffInfoData = {
      education: education,
      birthdate: age,
      picture: selectedImage,
    };

    dispatch(editStaffMemberInfo(userInfo._id, StaffInfoData));
  };
  useEffect(() => {
    if (successUpdate) {
      dispatch(fetchStaffMemberInfo(userInfo._id));
      dispatch({ type: EDIT_STAFF_MEMBER_INFO_RESET });
    }
  }, [dispatch, successUpdate]);



  useEffect(() => {
    // Ovdje možete postaviti početno stanje prekidača ako je potrebno
    // setNotificationEnabled(...)
  }, []);

  const resetNotificationPermissions = () => {
    console.log("Resetovanje dozvola za notifikacije...");
    localStorage.removeItem('notificationPermission');
    console.log("Dozvole za notifikacije su resetovane.");
    // Dodajte dodatnu logiku ako je potrebno za resetovanje dozvola na strani klijenta
  };
  
  const handleNotificationToggle = () => {
    if (notificationEnabled) {
      unsubscribe();
      resetNotificationPermissions();
    } else {
      subscribe();
    }
  };
  
  const subscribe = async () => {
    try {
      console.log("subscribe");
      let sw = await navigator.serviceWorker.ready;

      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BKROG9SRCPvXzP8ib7FPpe3gXUAR_fVr8Ljvb__5W-qTtjirTwnIC-NS3XmlS7RWBuzeCxP6TpbQIrobKPDpZsY",
      });

      const formattedPush = push.toJSON();

      const subscriptionData = {
        endpoint: formattedPush.endpoint,
        p256dh: formattedPush.keys.p256dh,
        auth: formattedPush.keys.auth,
      };

      dispatch(subscribeToNotifications(subscriptionData));
      setNotificationEnabled(true);
    } catch (error) {
      console.error("Error subscribing to notifications:", error);
    }
  };

  const unsubscribe = async () => {
    try {
      console.log("unsubscribe");
      dispatch(unsubscribeFromNotifications());
      setNotificationEnabled(false);
    } catch (error) {
      console.error("Error unsubscribing from notifications:", error);
    }
  };

  

  return (
    <div className="text-center py-5">
       <h2>{t("Settings").toUpperCase()}</h2>
            <Row><Col className="text-end" xs={8} lg={6}><h4> {t("ChooseLanguage")}:</h4></Col>
            <Col className="text-start">
            <Dropdown className="dropdown" >
              <Dropdown.Toggle variant="light" id="language-dropdown" style={{ "backgroundColor":"rgba(0,0,0,0)", "color":"#00c613", "border":"none", zIndex:"33"}}>
                {i18n.language === "en" ? "Eng" : "Ser"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{backgroundColor:  "#1d4e5b" , "border":"none",marginTop: windowWidth <= 760 ? "1rem" : ""}}>
                {languageOptions.map((option) => (
                  <Dropdown.Item key={option.value} onClick={() => changeLanguage(option)} style={{"color":"#00c613"}}>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
            </Row>
            <Row className="pb-5">
                <Col className="text-end" xs={8} lg={6}><h4>{t("Notifications")}:</h4></Col>
                <Col>
                <Col className="text-start pt-1">
                <NotificationToggle context={context} />
    </Col>
                </Col>
            </Row>
      {selectedImage && (
        <img
          src={`${selectedImage}`}
          alt="Selected"
          style={{ width: "150px", height: "150px", borderRadius: "50%" }}
        />
      )}
      <Col xs={8} lg={3} className=" mx-auto py-3">
        
      </Col>

      <Row className="justify-content-center py-3" >
        <Col xs={10} md={8}>

          <Form.Control
          id="image-file"
          type="file"
          label={t("Choose File")}
          custom
          onChange={uploadFileHandler}
          style={{backgroundColor:"#415f74", border:"none", borderRadius:"0", color:"white"}}
        ></Form.Control>
          <h4 style={{paddingTop:"10px"}}> {t("Education")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Education")}
              value={education}
              onChange={(e) => setEducation(e.target.value)}
            />
          </Form.Group>

          <h4> {t("age")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("age")}
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
          </Form.Group>
          <Button className="w-100 rounded-0 border-0" transparent={false} onClick={handleUpdateInfo}>
        {t("UpdateInfo")}
      </Button>
        </Col>
        
      </Row>
     
      <PassportInfo
        passportData={StaffInfo?.trainers?.staffPassport}
        updatePassportInfo={handlePassportUpdate}
      />
    </div>
  );
};

export default StaffInfoScreen;
