const theme = {
    colors: {
    primaryColor: '#00C523',
    secondaryColor:'#d5fad7',
    fontcolor: '#0FFFFFF',
    background: 'd5fad7'
    
      
    },
    fonts: {
      primary: "'Red Hat Display', sans-serif",
      weight: 'bold',
      // ostali fontovi...
    }
  };
  
  export default theme;
  