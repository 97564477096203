import{
EDIT_PASSPORT_INFO_FAIL,
EDIT_PASSPORT_INFO_REQUEST,
EDIT_PASSPORT_INFO_SUCCESS,
EDIT_PASSPORT_INFO_RESET

} from '../constants/passportInfoConstants'

const editInitialState = {
    loading: false,
    error: null,
    success: false,
  };

  export const editPassportInfoReducer = (state = editInitialState, action) => {
    switch (action.type) {
      case EDIT_PASSPORT_INFO_REQUEST:
        return { loading: true };
      case EDIT_PASSPORT_INFO_SUCCESS:
        return { 
          ...state, 
          loading: false,
           success: true,
            player: action.payload };
      case EDIT_PASSPORT_INFO_FAIL:
        return { loading: false, error: action.payload };
        case EDIT_PASSPORT_INFO_RESET: 
        return {editInitialState};
      default:
        return state;
    }
  };
 