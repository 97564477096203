import React from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Notification from "../../components/common/Notification";
import NotificationMessage from "../../components/notification/NotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { getUserNotifications } from "../../actions/notificationActions";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { formatDate, formatBirth } from '../../components/dateUtils';
const NotificationScreen = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const notificationList = useSelector((state) => state.notificationList);
  const { notifications } = notificationList;

  console.log(userInfo)
  console.log(notifications)

  useEffect(() => {
    console.log(`useEffect`)
    // Check if notifications are empty, if so, dispatch the action to fetch user notifications
    if (notifications === null || notifications.length === 0) {
      dispatch(getUserNotifications(userInfo._id));
    }
  }, [dispatch, userInfo._id, notifications]);


  return (
    <div>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ paddingTop: "100px" }}
      >
        <Row>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <h2>{t("NotificationScreen")}</h2>
          </Col>
          {notifications && notifications.map((notification) => (
            <NotificationMessage
              assigned={notification.assignedBy ? notification.assignedBy.name : "assigned by system"}
              time={formatDate(notification.createdAt)}
              notification={notification.text}
              toPath={notification.link ? notification.link : null}
            />
          ))}
        </Row>

      </Container>

    </div>

  );
};

export default NotificationScreen;
