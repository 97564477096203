import React, { useEffect,useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/cofButton/Button";
import {createClub} from '../../actions/clubActions'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { RESET_CREATE_CLUB_STATE } from '../../constants/clubConstants'
import CustomInput from "../../components/customInput/CustomInput"
const CreateClubScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clubName, setClubName] = useState("");
  const [pib, setPib] = useState("");
  const [address, setAddress] = useState("");
  const [clubEmail, setClubEmail] = useState("");
  const [ownerInputMode, setOwnerInputMode] = useState("none"); // 'none', 'id', 'data'

  const [ownerId, setOwnerId] = useState("");

  const [ownerName, setOwnerName] = useState("");
  const [ownerLastName, setOwnerLastName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerPassword, setOwnerPassword] = useState("");

  const CreateClub = useSelector((state) => state.clubCreate);
  const { loading, error, success: createsuccess } = CreateClub;

  const handleOwnerInputModeChange = (mode) => {
    setOwnerInputMode(mode);
  };

  const handleClubCreate = (e) => {
    e.preventDefault();
    let ownerInfo = null;

    if (ownerInputMode === "data") {
      ownerInfo = {
        firstName: ownerName,
        lastName: ownerLastName,
        email: ownerEmail,
        password: ownerPassword,
      };
    } else if (ownerInputMode === "id") {
      ownerInfo = { id: ownerId };
    }
  
    const clubData = {
      name: clubName,
      pib: pib,
      address: address,
      owner: ownerInfo,
      email: clubEmail,
    };
     dispatch(createClub(clubData));
  };
  useEffect(() => {
    if (createsuccess) {
      // Preusmerite se na rutu "/admin/clubs"
      navigate("/admin/clubs");
      dispatch({ type: RESET_CREATE_CLUB_STATE })
    }
  }, [createsuccess, navigate]);


  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ paddingTop: "100px" }}
    >
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <h2>{t("NewClub").toUpperCase()}</h2>
        </Col>

        <Col xs={12}>
          <Form>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput 
              type="text"
              placeholder={t("ClubName")}
              value={clubName}
              onChange={(e) => setClubName(e.target.value)}/>
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
             type="text"
             placeholder={t("PIB")}
             value={pib}
             onChange={(e) => setPib(e.target.value)} />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput 
             type="text"
             placeholder={t("Address")}
             value={address}
             onChange={(e) => setAddress(e.target.value)} />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput 
             type="text"
             placeholder={t("clubEmail")}
             value={clubEmail}
             onChange={(e) => setClubEmail(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="ownerInputModeRadio">
  <Form.Check
    type="radio"
    label={t("NoOwnerData")}
    checked={ownerInputMode === "none"}
    onChange={() => handleOwnerInputModeChange("none")}
  />
  <Form.Check
    type="radio"
    label={t("OwnerID")}
    checked={ownerInputMode === "id"}
    onChange={() => handleOwnerInputModeChange("id")}
  />
  <Form.Check
    type="radio"
    label={t("OwnerData")}
    checked={ownerInputMode === "data"}
    onChange={() => handleOwnerInputModeChange("data")}
  />
</Form.Group>



{ownerInputMode === "id" && (
  <>
    <Form.Group style={{ marginBottom: "10px" }}>
    <CustomInput 
    type="text"
    placeholder={t("OwnerID")}
    value={ownerId}
    onChange={(e) => setOwnerId(e.target.value)}/>
    </Form.Group>
  </>
)}

{ownerInputMode === "data" && (
            <Container>
              <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput 
               type="text"
               placeholder={t("Owner'sFirstName")}
               value={ownerName}
               onChange={(e) => setOwnerName(e.target.value)}/>
              </Form.Group>
              <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
               type="text"
               placeholder={t("Owner'sLastName")}
               value={ownerLastName}
               onChange={(e) => setOwnerLastName(e.target.value)} />
              </Form.Group>
              <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
              type="email"
              placeholder={t("OwnerEmail")}
              value={ownerEmail}
              onChange={(e) => setOwnerEmail(e.target.value)} />
              </Form.Group>
              <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
              type="password"
              placeholder={t("OwnerPassword")}
              value={ownerPassword}
              onChange={(e) => setOwnerPassword(e.target.value)} />
              </Form.Group>
            </Container>
            )}

            <Button
              className="w-100 rounded-0 border-0"
              transparent={false}
              onClick={handleClubCreate}
            >
               {t("CreateClub")}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateClubScreen;
