// constants/staffConstants.js

export const FETCH_TRAINERS_REQUEST = 'FETCH_TRAINERS_REQUEST';
export const FETCH_TRAINERS_SUCCESS = 'FETCH_TRAINERS_SUCCESS';
export const FETCH_TRAINERS_FAIL = 'FETCH_TRAINERS_FAIL';
export const RESET_TRAINERS = "RESET_TRAINERS";

// constants/staffMemberConstants.js
export const FETCH_STAFF_MEMBER_REQUEST = 'FETCH_STAFF_MEMBER_REQUEST';
export const FETCH_STAFF_MEMBER_SUCCESS = 'FETCH_STAFF_MEMBER_SUCCESS';
export const FETCH_STAFF_MEMBER_FAIL = 'FETCH_STAFF_MEMBER_FAIL';

// Dodajte ove konstante za brisanje trenera
export const DELETE_TRAINER_REQUEST = 'DELETE_TRAINER_REQUEST';
export const DELETE_TRAINER_SUCCESS = 'DELETE_TRAINER_SUCCESS';
export const DELETE_TRAINER_FAIL = 'DELETE_TRAINER_FAIL';

// constants/staffConstants.js

export const CREATE_TRAINER_REQUEST = 'CREATE_TRAINER_REQUEST';
export const CREATE_TRAINER_SUCCESS = 'CREATE_TRAINER_SUCCESS';
export const CREATE_TRAINER_FAIL = 'CREATE_TRAINER_FAIL';
export const RESET_CREATE_TRAINER_STATE = 'RESET_CREATE_TRAINER_STATE';

export const EDIT_TRAINER_REQUEST = 'EDIT_TRAINER_REQUEST';
export const EDIT_TRAINER_SUCCESS = 'EDIT_TRAINER_SUCESS';
export const EDIT_TRAINER_FAIL = 'EDIT_TRAINER_FAIL';
export const EDIT_TRAINER_RESET = 'EDIT_TRAINER_RESET';
