import axios from 'axios';
import {
  NOTIFICATION_SUBSCRIBE_REQUEST,
  NOTIFICATION_SUBSCRIBE_SUCCESS,
  NOTIFICATION_SUBSCRIBE_FAIL,
  NOTIFICATION_UNSUBSCRIBE_REQUEST,
  NOTIFICATION_UNSUBSCRIBE_SUCCESS,
  NOTIFICATION_UNSUBSCRIBE_FAIL,
} from '../constants/pushNotificationsConstants';

export const subscribeToNotifications = (subscriptionData) => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTIFICATION_SUBSCRIBE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      // Implementirajte API poziv za pretplatu na notifikacije s prilagođenom strukturom tijela
      console.log(subscriptionData)
      const { data } = await axios.post('/api/users/notification/web/device', subscriptionData, config);
  
      dispatch({ type: NOTIFICATION_SUBSCRIBE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: NOTIFICATION_SUBSCRIBE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };

export const unsubscribeFromNotifications = () => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTIFICATION_UNSUBSCRIBE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      // Implementirajte API poziv za odjavu od notifikacija
      await axios.delete('/api/users/notification/web/device', config);
  
      dispatch({ type: NOTIFICATION_UNSUBSCRIBE_SUCCESS });
    } catch (error) {
      dispatch({
        type: NOTIFICATION_UNSUBSCRIBE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };