import { RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_RESET

} from "../constants/resetPasswordConstants";


const InitialState = {
    loading: false,
    error: null,
    success: false,
  };

  export const resetPasswordReducer = (state = InitialState, action) => {
    switch (action.type) {
      case RESET_PASSWORD_REQUEST:
        return { ...state, loading: true };
      case RESET_PASSWORD_SUCCESS:
        return { 
          ...state, 
          loading: false,
           success: true,
            player: action.payload };
      case RESET_PASSWORD_FAIL:
        return {...state, loading: false, error: action.payload };
        case RESET_PASSWORD_RESET: 
        return InitialState;
      default:
        return state;
    }
  };
 