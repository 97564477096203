import {React, useState,useEffect} from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { fetchAdminById } from "../../actions/AdminsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const AdminInfoScreen = ()=>{
    const {t} = useTranslation();
    const { adminId } = useParams();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
    const adminDetails = useSelector((state) => state.adminDetails);
    const { loading, error, admin } = adminDetails;
    console.log(admin)
    useEffect(() => {
      if (!admin || !admin.length || admin.length === 0 || !admin[0].user || admin[0].user._id !== adminId) {
        // Dohvatimo informacije o adminu iz Redux store-a
        dispatch(fetchAdminById(adminId));
      } else {
        // Postavimo podatke o adminu u state
        setName(admin[0].user.name);
        setSurname(admin[0].user.surname);
        setEmail(admin[0].user.email);
       
      }
    }, [dispatch, adminId, admin]);
    return(
        <div>

<Container className="text-center mt-5">

      <h2>{t("Admin Info")}</h2>
      
        <div>
          <p>{t("Name")}: {name}</p>
          <p>{t("Surname")}: {surname} </p>
          <p>{t("Email")}: {email} </p>
        </div>
     
    </Container>




    
        </div>
    )
}

export default AdminInfoScreen;