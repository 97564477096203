import{
    FETCH_PLAYERS_REQUEST,
    FETCH_PLAYERS_SUCCESS,
    FETCH_PLAYERS_FAIL,
    RESET_PLAYERS,

    FETCH_PLAYER_INFO_REQUEST,
  FETCH_PLAYER_INFO_SUCCESS,
  FETCH_PLAYER_INFO_FAIL,

  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAIL,
  CREATE_PLAYER_REQUEST,
  CREATE_PLAYER_SUCCESS,
  CREATE_PLAYER_FAIL,
  RESET_CREATE_PLAYER_STATE,
} from "../../constants/staff/clubPlayerConstants";


const initialPlayersState = {
  loading: false,
  players: [],
  error: null,
};

export const playersReducer = (state = initialPlayersState, action) => {
  switch (action.type) {
    case FETCH_PLAYERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        players: action.payload,
        success: true,
      };
    case FETCH_PLAYERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case RESET_PLAYERS: // Dodajte slučaj za reset akciju
      return {
        ...initialPlayersState, // Vraća se početno stanje igrača
      };
    default:
      return state;
  }
};


export const deletePlayerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PLAYER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case DELETE_PLAYER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


  
  
  const initialStateplayer = {
    loading: false,
    error: null,
    playerInfo: null,
  };
  export const playerInfoReducer = (state = initialStateplayer, action) => {
    switch (action.type) {
      case FETCH_PLAYER_INFO_REQUEST:
        return { ...state, loading: true };
      case FETCH_PLAYER_INFO_SUCCESS:
        return { ...state, loading: false, playerInfo: action.payload };
      case FETCH_PLAYER_INFO_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  const createPlayerInitialState = {
    loading: false,
    error: null,
    players: [],
    success: false,
  };
  
  export const CreatePlayerReducer = (state = createPlayerInitialState, action) => {
    switch (action.type) {
      case CREATE_PLAYER_REQUEST:
        return { ...state, loading: true };
      case CREATE_PLAYER_SUCCESS:
        return { ...state, loading: false, success: true, players: action.payload };
      case CREATE_PLAYER_FAIL:
        return { ...state, loading: false, error: action.payload };
      case RESET_CREATE_PLAYER_STATE:
        return createPlayerInitialState; // Reset to initial state
      default:
        return state;
    }
  };