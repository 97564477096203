import axios from "axios";
import { FETCH_PASSWORD_CONTEXT_REQUEST,
         FETCH_PASSWORD_CONTEXT_SUCCESS,
         FETCH_PASSWORD_CONTEXT_FAIL,
         FETCH_PASSWORD_CONTEXT_RESET
} from "../constants/resetPasswordConstants";


export const fetchPasswordContext = (resetpasswordtoken) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_PASSWORD_CONTEXT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/resetpassword/${resetpasswordtoken}`, config);
  
      dispatch({ type: FETCH_PASSWORD_CONTEXT_SUCCESS , payload: data });
    } catch (error) {
      dispatch({ type:FETCH_PASSWORD_CONTEXT_FAIL, payload: error.message });
    }
  };