import { combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { userLoginReducer, userRegisterReducer } from "./reducers/userReducers";

import { getNotificationReducer } from "./reducers/notificationReducers";

import { getWeeklyoverviewReducer } from "./reducers/weeklyoverviewReducers";
import {
  userAssignedTasksReducer,
  assignedTaskReducer,
} from "./reducers/assignedTaskReducer";

import { submitReducer } from "./reducers/submitReducers";
import {
  adminsReducer,
  deleteAdminReducer,
  adminCreateReducer,
  adminEditReducer,
  adminDetailsReducer,
} from "./reducers/adminsReducer";

import {
  playersReducer,
  playerInfoReducer,
  deletePlayerReducer,
} from "./reducers/staff/clubPlayerReducer";

import {
  staffReducer,
  staffMemberReducer,
  deleteStaffReducer,
  createTrainerReducer,
  editTrainerReducer,
} from "./reducers/staff/clubStaffReducer";

import { getDailyStatusReducer } from "./reducers/dailyStatusReducers";
import { clubCreateReducer, getClubsReducer, getClubReducer} from "./reducers/clubReducer";
import { deleteClubReducer, editClubReducer } from "./reducers/clubReducer";

import { submiAgaintReducer } from "./reducers/submitAgainReducers";
import { getClubDetailsReducer } from "./reducers/clubDetailsReducers";
import { CreatePlayerReducer } from "./reducers/staff/clubPlayerReducer";
import { notificationSubscribeReducer, notificationUnsubscribeReducer } from "./reducers/pushNotificationReducer";
import {
  tasksListReducer,
  fetchTaskDetailsReducer,
  deleteTaskReducer,
  editTaskReducer,
  createTaskReducer,
} from "./reducers/staff/tasksListReducer";
import {scheduledTasksReducer,
   ScheduledTaskInfoReducer,
    unassignMemberReducer,
      editScheduledTaskReducer,
      createBatchScheduledTasksReducer,
      filterScheduledTasksReducer,
      confirmAttendanceReducer,
      declineAttendanceReducer,
      markAsResolvedReducer,
    } from "./reducers/staff/scheduledReducer";
import { createScheduledTaskReducer, deleteScheduledTaskReducer } from "./reducers/staff/scheduledReducer";
import { unassignedUserReducer , assignUserReducer, fetchUnassignedStaffReducer } from "./reducers/staff/scheduledReducer";
import { userContextReducer } from "./reducers/userReducers";
import { fetchPlayerInfoReducer, editPlayerInfoReducer } from "./reducers/player/playerReducer";
import { fetchStaffMemberInfoReducer, editStaffMemberInfoReducer } from "./reducers/staff/staffMemberInfoReducer";
import { editPassportInfoReducer } from "./reducers/passportInfoReducer";
import { clubSettingsReducer, clubSettingsUpdateReducer } from "./reducers/staff/clubSettingsReducer";
import { resetPasswordReducer } from "./reducers/resetPasswordReducers";
import { fetchPasswordReducer } from "./reducers/fetchPasswordReducer";
import { editPasswordReducer } from "./reducers/editPasswordReducer";

import { playersGroupsReducer, playersGroupDetailsReducer, unassignMemberReducerGroup, 
  playersGroupAssignReducer, editPlayersGroupReducer, deletePlayersGroupReducer} from "./reducers/staff/playersGroupsReducer";
import { createNewGroupReducer } from "./reducers/staff/createNewGroupReducer";
import { scheduledTaskCommentReducer, createScheduledTaskCommentReducer } from "./reducers/staff/scheduledTaskCommentsReducer";
import { documentUploadReducer, documentListReducer  } from "./reducers/uploadReducer";
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  notificationList: getNotificationReducer,
  weeklyoverview: getWeeklyoverviewReducer,
  userAssignedTasks: userAssignedTasksReducer,
  submit: submitReducer,
  submitAgain: submiAgaintReducer,
  documentUpload: documentUploadReducer,
  documentList: documentListReducer,

  userAssignedTask: assignedTaskReducer,
  admins: adminsReducer,
  deleteAdmin: deleteAdminReducer,
  adminCreate: adminCreateReducer,
  editAdmin: adminEditReducer,
  adminDetails: adminDetailsReducer,
  clubs: getClubsReducer,
  clubDetails: getClubDetailsReducer,
  dailyStatus: getDailyStatusReducer,
  clubCreate: clubCreateReducer,
  deleteClub: deleteClubReducer,

  players: playersReducer,
  playerInfo: playerInfoReducer,
  deletePlayer: deletePlayerReducer,
  createPlayer: CreatePlayerReducer,

  staff: staffReducer,
  staffMember: staffMemberReducer,
  deleteStaff: deleteStaffReducer,
  staffMemberEdit: editTrainerReducer,
  createTrainer: createTrainerReducer,
  tasks: tasksListReducer,
  fetchTaskDetails: fetchTaskDetailsReducer,
  deleteTask: deleteTaskReducer,
  editTask: editTaskReducer,
  createTask: createTaskReducer,
  scheduledTasks: scheduledTasksReducer,
  scheduledTaskInfo :ScheduledTaskInfoReducer,
  unassignMember : unassignMemberReducer,
  createScheduledTask: createScheduledTaskReducer,
  deleteScheduledTask: deleteScheduledTaskReducer,
  fetchUnassignedUser: unassignedUserReducer,
  fetchUnassignedStaff: fetchUnassignedStaffReducer,
  assignUser: assignUserReducer,
  createBatchScheduledTasks: createBatchScheduledTasksReducer,
  filterScheduledTasks:filterScheduledTasksReducer,
  fetchUserContext:userContextReducer,
  fetchPlayerInfo:fetchPlayerInfoReducer,
  editPlayerInfo: editPlayerInfoReducer,
  fetchStaffMemberInfo: fetchStaffMemberInfoReducer,
  editStaffMemberInfo: editStaffMemberInfoReducer,
  editScheduledTask:editScheduledTaskReducer,
  confirmAttendance: confirmAttendanceReducer,
  declineAttendance: declineAttendanceReducer,
  markAsResolved: markAsResolvedReducer,
  editPassportInfo:editPassportInfoReducer,
  clubSettings: clubSettingsReducer,
  resetPassword: resetPasswordReducer,
  clubSettingsUpdate: clubSettingsUpdateReducer,
  fetchPassword:fetchPasswordReducer,
  editPassword: editPasswordReducer,
  playersGroups: playersGroupsReducer,
  playersGroupDetails: playersGroupDetailsReducer,
  unassignMemberGroup: unassignMemberReducerGroup,
  playersGroupAssign: playersGroupAssignReducer,
  editPlayersGroup: editPlayersGroupReducer,
  createNewGroup: createNewGroupReducer,
  
  deletePlayersGroup: deletePlayersGroupReducer,
  editClub: editClubReducer,
  getClub: getClubReducer,

  notificationSubscribe: notificationSubscribeReducer,
  notificationUnsubscribe: notificationUnsubscribeReducer,
  scheduledTaskComment:scheduledTaskCommentReducer,
  createScheduledTaskComment:createScheduledTaskCommentReducer,
  
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
