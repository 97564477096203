import axios from "axios";
import {
  USER_WEEKLY_OVERVIEW_REQUEST,
  USER_WEEKLY_OVERVIEW_SUCCESS,
  USER_WEEKLY_OVERVIEW_FAIL
} from "../constants/weeklyoverviewConstants"

export const getUserWeeklyoverview = (userId) => async (dispatch, getState) =>{
   try {
    dispatch({
        type: USER_WEEKLY_OVERVIEW_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
  
      const { data } = await axios.get(
       `/api/users/${userId}/dailystatus/overview`,
        config
      );
  
      dispatch({
        type: USER_WEEKLY_OVERVIEW_SUCCESS,
        payload: data,
      });

   } catch (error) {
    dispatch({
      type: USER_WEEKLY_OVERVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }

}