import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import DataTable from '../../components/table/DataTable'; // Podesite putanju prema DataTable komponenti
import { useTranslation } from 'react-i18next';
const AdminHomeScreen = ({ context }) => {
  const { t } = useTranslation();
  // Proverite da li context postoji i da li sadrži clubs
  if (!context || !context.clubs) {
    return (
      <Container>
        <p>{t("Data is not available")}.</p>
      </Container>
    );
  }

  const { clubs } = context;

  // Formatiranje podataka za prikaz u DataTable-u
  const formattedClubs = clubs.map((club) => ({
    name: club.name,
    pib: club.pib,
    playerCount: club.playerCount,
    staffCount: club.staffCount,
    licenseExpirationDate: club.licenseExpirationDate,
    
  }));

  // Definišite kolone za DataTable
  const adminColumns = [
    { label: t("Name").toUpperCase(), dataKey: 'name' },
    { label: t("PIB").toUpperCase(), dataKey: 'pib' },
    { label: t("Number of players").toUpperCase(), dataKey: 'playerCount' },
    { label: t("Staff members").toUpperCase(), dataKey: 'staffCount' },
    { label: t("Licence experation").toUpperCase(), dataKey: 'licenseExpirationDate' },
  ];
  const buttonStyle = {
    width: '100px', // Postavite željenu širinu dugmadi
  };
  return (
    <Container>
      <h2>{t("Club informations").toUpperCase()}</h2>
      {/* Prikazivanje DataTable komponente sa formatiranim podacima */}
      <DataTable
        columns={adminColumns}
        data={formattedClubs}
        actions={[]}
        actionLabels={[]}
        buttonStyle={buttonStyle}
        actionHandlers={{}}
        className="preserve-columns"
      />
    </Container>
  );
};

export default AdminHomeScreen;