import {React, useState} from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/cofButton/Button";
import { Link } from "react-router-dom";
import { resetPassword } from "../../../actions/resetPasswordActions";
import { useDispatch } from "react-redux";
import { useSelector} from "react-redux";
import CustomInput from "../../../components/customInput/CustomInput";

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const resetPasswordState = useSelector((state) => state.resetPassword);
  const handleSignIn = (e) => {
     e.preventDefault()
    // Implementacija logike za prijavu
    dispatch (resetPassword(email));
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ paddingTop: "100px" }}
    >
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <h2>Reset your password</h2>
        </Col>
        <Col
          xs={12}
        >
          <p>Enter your email address and we will send you a password reset link</p>
        </Col>

        <Col xs={12}>
        
          <Form>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
              type="text" placeholder="Email" value={email}
              onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button className="w-100 rounded-0 border-0" onClick={handleSignIn}>
              Reset password
            </Button>
          </Form>
        </Col>




      </Row>
    </Container>
  );
};

export default ResetPasswordScreen;
