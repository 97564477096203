// Reducer (reducers/playersGroupsReducer.js)

import {
    FETCH_PLAYERS_GROUPS_REQUEST,
    FETCH_PLAYERS_GROUPS_SUCCESS,
    FETCH_PLAYERS_GROUPS_FAILURE,

    PLAYERS_GROUPS_DETAILS_REQUEST,
    PLAYERS_GROUPS_DETAILS_SUCCESS,
    PLAYERS_GROUPS_DETAILS_FAIL,

    UNASSIGN_MEMBER_REQUEST,
    UNASSIGN_MEMBER_SUCCESS,
    UNASSIGN_MEMBER_FAIL,

    ASSIGN_PLAYER_REQUEST,
    ASSIGN_PLAYER_SUCCESS,
    ASSIGN_PLAYER_FAIL,

    EDIT_PLAYERS_GROUP_REQUEST,
    EDIT_PLAYERS_GROUP_SUCCESS,
    EDIT_PLAYERS_GROUP_FAIL,

    DELETE_PLAYERS_GROUP_REQUEST,
    DELETE_PLAYERS_GROUP_SUCCESS,
    DELETE_PLAYERS_GROUP_FAIL,
  } from '../../constants/staff/playersGroupsConstatnts';
  
  const initialState = {
    loading: false,
    playersGroups: [],
    error: '',
  };
  
  export const playersGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PLAYERS_GROUPS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_PLAYERS_GROUPS_SUCCESS:
        return {
          ...state,
          loading: false,
          playersGroups: action.payload,
          error: '',
        };
      case FETCH_PLAYERS_GROUPS_FAILURE:
        return {
          ...state,
          loading: false,
          playersGroups: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  export const playersGroupDetailsReducer = (state = { group: {} }, action) => {
    switch (action.type) {
      case PLAYERS_GROUPS_DETAILS_REQUEST:
        return { ...state, loading: true };
      case PLAYERS_GROUPS_DETAILS_SUCCESS:
        return { loading: false, group: action.payload };
      case PLAYERS_GROUPS_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const unassignMemberReducerGroup = (state = {}, action) => {
    switch (action.type) {
      case UNASSIGN_MEMBER_REQUEST:
        return { loading: true };
      case UNASSIGN_MEMBER_SUCCESS:
        return { loading: false, success: true };
      case UNASSIGN_MEMBER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const playersGroupAssignReducer = (state = {}, action) => {
    switch (action.type) {
      case ASSIGN_PLAYER_REQUEST:
        return { loading: true };
      case ASSIGN_PLAYER_SUCCESS:
        return { loading: false, success: true };
      case ASSIGN_PLAYER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const editPlayersGroupReducer = (state = {}, action) => {
    switch (action.type) {
      case EDIT_PLAYERS_GROUP_REQUEST:
        return { loading: true };
      case EDIT_PLAYERS_GROUP_SUCCESS:
        return { loading: false, success: true };
      case EDIT_PLAYERS_GROUP_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const deletePlayersGroupReducer = (state = {success: false}, action) => {
    switch (action.type) {
      case DELETE_PLAYERS_GROUP_REQUEST:
        return { loading: true };
      case DELETE_PLAYERS_GROUP_SUCCESS:
        return { loading: false, success: true };
      case DELETE_PLAYERS_GROUP_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };