export const FETCH_PLAYERS_REQUEST = 'FETCH_PLAYERS_REQUEST';
export const FETCH_PLAYERS_SUCCESS = 'FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_FAIL = 'FETCH_PLAYERS_FAIL';
export const RESET_PLAYERS = 'RESET_PLAYERS';


// constants/PlayerInfoConstants.js

export const FETCH_PLAYER_INFO_REQUEST = 'FETCH_PLAYER_INFO_REQUEST';
export const FETCH_PLAYER_INFO_SUCCESS = 'FETCH_PLAYER_INFO_SUCCESS';
export const FETCH_PLAYER_INFO_FAIL = 'FETCH_PLAYER_INFO_FAIL';

export const DELETE_PLAYER_REQUEST = 'DELETE_PLAYER_REQUEST';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_FAIL = 'DELETE_PLAYER_FAIL';

export const CREATE_PLAYER_REQUEST = "CREATE_PLAYER_REQUEST";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_FAIL = "CREATE_PLAYER_FAIL";
export const RESET_CREATE_PLAYER_STATE = 'RESET_CREATE_PLAYER_STATE';