import axios from 'axios';
import { FETCH_SCHEDULED_TASK_COMMENT_FAIL,
    FETCH_SCHEDULED_TASK_COMMENT_SUCCESS,
    FETCH_SCHEDULED_TASK_COMMENT_REQUEST,
    CREATE_SCHEDULED_TASK_COMMENT_REQUEST,
    CREATE_SCHEDULED_TASK_COMMENT_SUCCESS,
    CREATE_SCHEDULED_TASK_COMMENT_FAIL,
    CREATE_SCHEDULED_TASK_COMMENT_RESET

} from '../../constants/staff/scheduledTaskCommentsConstants';

export const getScheduledTaskComment = (commentId) => {
    return async (dispatch, getState) => {
      dispatch({ type:  FETCH_SCHEDULED_TASK_COMMENT_REQUEST });
  
      try {
        const {
          userLogin: { userInfo },
        } = getState();
  
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
  
        // Ovde napravite GET zahtev sa postavkama zaglavlja
        const response = await axios.get(
          `/api/staff/clubs/${commentId}/settings`,
          config
        );
            console.log(response);
        dispatch({
          type:  FETCH_SCHEDULED_TASK_COMMENT_SUCCESS,
          payload: response.data, // Ovo su dohvaćeni podaci sa servera
        });
      } catch (error) {
        dispatch({
          type: FETCH_SCHEDULED_TASK_COMMENT_FAIL,
          payload: error.message, // Možete postaviti i dodatne informacije o grešci
        });
      }
    };
  };
  

  
  export const createScheduledTaskComment = (scheduledtaskid, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_SCHEDULED_TASK_COMMENT_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.post(
        `/api/staff/clubs/:clubid/scheduledtasks/${scheduledtaskid}/comments`, // Update the URL as needed
        formData,
        config
      );
  
      dispatch({ type: CREATE_SCHEDULED_TASK_COMMENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_SCHEDULED_TASK_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };