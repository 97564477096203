import {
    FETCH_PASSWORD_CONTEXT_FAIL,
    FETCH_PASSWORD_CONTEXT_REQUEST,
    FETCH_PASSWORD_CONTEXT_RESET,
    FETCH_PASSWORD_CONTEXT_SUCCESS

} from '../constants/resetPasswordConstants'

const InitialState = {
    loading: false,
    error: null,
    success: false,
  };

  export const fetchPasswordReducer = (state = InitialState, action) => {
    switch (action.type) {
      case FETCH_PASSWORD_CONTEXT_REQUEST:
        return { ...state, loading: true };
      case FETCH_PASSWORD_CONTEXT_SUCCESS:
        return { 
          ...state, 
          loading: false,
           success: true,
            player: action.payload };
      case FETCH_PASSWORD_CONTEXT_FAIL:
        return {...state, loading: false, error: action.payload };
        case FETCH_PASSWORD_CONTEXT_RESET: 
        return InitialState;
      default:
        return state;
    }
  };
 