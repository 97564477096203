// reducers/adminReducers.js

// reducers/adminReducers.js
import {
    FETCH_ADMINS_REQUEST,
    FETCH_ADMINS_SUCCESS,
    FETCH_ADMINS_FAIL,
    EDIT_ADMIN_REQUEST,
    EDIT_ADMIN_SUCCESS,
    EDIT_ADMIN_FAIL,
    DELETE_ADMIN_REQUEST,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAIL,
    DELETE_ADMIN_RESET,
    CREATE_ADMIN_REQUEST,
    CREATE_ADMIN_SUCCESS,
    CREATE_ADMIN_RESET,
    CREATE_ADMIN_FAIL,
    FETCH_ADMIN_REQUEST,
    FETCH_ADMIN_SUCCESS,
    FETCH_ADMIN_FAIL,
  } from "../constants/AdminsConstants";
  
  const initialState = {
    loading: false,
    error: null,
    admins: [],
  };
  
  export const adminsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ADMINS_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_ADMINS_SUCCESS:
        return { ...state, loading: false, admins: action.payload };
      case FETCH_ADMINS_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  

  
  
  const deleteAdminInitialState = {
    admins: null,
    loading: false,
    error: null,
    success: false,
  };
  
  // Reducer
  export const deleteAdminReducer = (state = deleteAdminInitialState, action) => {
    switch (action.type) {
      case DELETE_ADMIN_REQUEST:
        return { ...state, loading: true, error: null, success: false };
      case DELETE_ADMIN_SUCCESS:
        // Ažurirajte stanje za brisanje admina
        const updatedAdmins = state.admins && state.admins.filter(
          (admin) => admin._id !== action.payload
        );
        return { ...state, loading: false, success: true, admins: updatedAdmins };
      case DELETE_ADMIN_FAIL:
        return { ...state, loading: false, error: action.payload, success: false };
      case DELETE_ADMIN_RESET:
        return { ...deleteAdminInitialState, success: false };
      default:
        return state;
    }
  };
  
  const createAdminInitialState = {
    loading: false,
    error: null,
    success: null,
    admin: null,
  };
  
  // Reducer
  export const adminCreateReducer = (state = createAdminInitialState, action) => {
    switch (action.type) {
      case CREATE_ADMIN_REQUEST:
        return { ...state, loading: true, success: null, error: null };
      case CREATE_ADMIN_SUCCESS:
        return { ...state, loading: false, success: true, admin: action.payload };
      case CREATE_ADMIN_FAIL:
        return { ...state, loading: false, error: action.payload, success: false };
      case CREATE_ADMIN_RESET:
        return createAdminInitialState;
      default:
        return state;
    }
  };

  
export const adminEditReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_ADMIN_REQUEST:
      return { loading: true };
    case EDIT_ADMIN_SUCCESS:
      return { loading: false, success: true, admin: action.payload };
    case EDIT_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminDetailsReducer = (state = { admin: {} }, action) => {
  switch (action.type) {
    case FETCH_ADMIN_REQUEST:
      return { ...state, loading: true };
    case FETCH_ADMIN_SUCCESS:
      return { loading: false, admin: action.payload };
    case FETCH_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
