import {React, useEffect, useState} from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import Button from "../components/cofButton/Button";
import { fetchPasswordContext } from "../actions/fetchPasswordContextAction";
import { editPassword } from "../actions/editPasswordAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate } from "react-router-dom";
import { EDIT_PASSWORD_RESET } from "../constants/resetPasswordConstants";


const NewPasswordScreen = () =>{
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetpasswordtoken } = useParams();
  const fetchPassword = useSelector((state) => state.fetchPassword);
  const { loading, error, password} = fetchPassword;
  const editPasswordInfo = useSelector((state) =>state.editPassword);
  const { loading:editLoading , error:editError, success} = editPasswordInfo;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true); // To track if passwords match
  const [resetPasswordState, setResetPasswordState] = useState({
    success: false,
    error: false,
  });
  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
       const newPasswordName={
        password:newPassword
       }
       dispatch(editPassword(resetpasswordtoken,newPasswordName))
      setResetPasswordState({
        success: true,
        error: false,
      });
    } else {
      // Passwords don't match, display an error message
      setPasswordsMatch(false);
      setResetPasswordState({
        success: false,
        error: true,
      });
    }
  };
  useEffect(()=>{
  dispatch(fetchPasswordContext(resetpasswordtoken))

  }, [dispatch, resetpasswordtoken])
  
  useEffect(() => {
    if(success){
      console.log('poziva se useeffect')
      // dispatch({ type: EDIT_PASSWORD_RESET })
      navigate("/signin");
    }
    else if(editError){
      console.log('postoji greska')
    }
       
    }, [dispatch, navigate,success,editError])
   return(
    <Container
    className="d-flex align-items-center justify-content-center"
    style={{ paddingTop: "100px" }}
  >
    <Row>
      <Col
        xs={12}
        className="d-flex align-items-center justify-content-center"
      >
        <h2>Edit your password</h2>
      </Col>
      <Col
        xs={12}
      >
        <p>Enter your new password</p>
      </Col>

      <Col xs={12}>
      {resetPasswordState.success && (
            <div className="alert alert-success">
              Password reset successful!
            </div>
          )}
          {resetPasswordState.error && (
            <div className="alert alert-danger">
              Passwords do not match. Please try again.
            </div>
          )}
        <Form>
          <Form.Group style={{ marginBottom: "10px" }}>
            <Form.Control type="text" placeholder="New password"
             value={newPassword}
             onChange={(e) => setNewPassword(e.target.value)}/>
          </Form.Group>
          <Form.Group style={{ marginBottom: "10px" }}>
            <Form.Control type="text" placeholder="Confirm new password"
             value={confirmPassword}
             onChange={(e) => setConfirmPassword(e.target.value)}/>
          </Form.Group>
          <Button className="w-100 rounded-0 border-0" onClick={handlePasswordChange}>
            Reset password
          </Button>
        </Form>
      </Col>




    </Row>
  </Container>

   )


}
export default NewPasswordScreen;