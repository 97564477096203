import axios from "axios";
import {
  USER_ASSIGNEDTASKS_REQUEST,
  USER_ASSIGNEDTASKS_SUCCESS,
  USER_ASSIGNEDTASKS_FAIL,


  USER_ASSIGNEDTASK_REQUEST,
  USER_ASSIGNEDTASK_SUCCESS,
  USER_ASSIGNEDTASK_FAIL,
} from "../constants/AssignedTasksConstants";

export const fetchUserAssignedTasks = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ASSIGNEDTASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log("Sending request to fetch assigned tasks...");

    const { data } = await axios.get(`/api/users/${userId}/assignedtasks`, config);

    console.log("Received data:", data);

    dispatch({
      type: USER_ASSIGNEDTASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error("Error fetching assigned tasks:", error.message);

    dispatch({
      type: USER_ASSIGNEDTASKS_FAIL,
      payload: error.message,
    });
  }
};




