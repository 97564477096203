import axios from "axios";
import {
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS
} from '../constants/resetPasswordConstants'

export const resetPassword = (email) => async (dispatch, getState) =>{
    try {
     dispatch({
         type: RESET_PASSWORD_REQUEST,
     });
     const {
       userLogin: { userInfo },
     } = getState();
 
     const config = {
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${userInfo.token}`,
       },
     };
     const requestData = {
        email: email,
    };
       const { data } = await axios.post(
       ` /api/resetpassword/resetpasswordrequest`,
         requestData,
         config
       );
     
       dispatch({
         type: RESET_PASSWORD_SUCCESS,
         payload: data,
       });
 
    } catch (error) {
     dispatch({
       type: RESET_PASSWORD_FAIL,
       payload:
         error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
     });
   }
 
 }