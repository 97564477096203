import axios from 'axios';
import{
    CREATE_NEW_GROUP_FAIL,
    CREATE_NEW_GROUP_REQUEST,
    CREATE_NEW_GROUP_SUCCESS
} from "../../constants/staff/playersGroupsConstatnts"
import { logout } from '../userActions'
export const createNewGroup = (clubId, groupData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_NEW_GROUP_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.post(`/api/staff/clubs/${clubId}/playersgroups`,groupData,  config)
  
      dispatch({
        type: CREATE_NEW_GROUP_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CREATE_NEW_GROUP_FAIL,
        payload: message,
      })
    }
  }
  