export const GET_CLUBS_REQUEST = "GET_CLUBS_REQUEST";
export const GET_CLUBS_SUCCESS = "GET_CLUBS_SUCCESS";
export const GET_CLUBS_FAIL = "GET_CLUBS_FAIL";
export const GET_CLUBS_RESET = "GET_CLUBS_RESET";

export const CREATE_CLUB_REQUEST = "CREATE_CLUB_REQUEST";
export const CREATE_CLUB_SUCCESS = "CREATE_CLUB_SUCCESS";
export const CREATE_CLUB_FAIL = "CREATE_CLUB_FAIL";
export const RESET_CREATE_CLUB_STATE = 'RESET_CREATE_CLUB_STATE';


export const DELETE_CLUB_REQUEST = 'DELETE_CLUB_REQUEST';
export const DELETE_CLUB_SUCCESS = 'DELETE_CLUB_SUCCESS';
export const DELETE_CLUB_FAIL = 'DELETE_CLUB_FAIL';
export const DELETE_CLUB_RESET = 'DELETE_CLUB_RESET';

 export const EDIT_CLUB_FAIL = 'EDIT_CLUB_FAIL';
 export const EDIT_CLUB_SUCCESS = 'EDIT_CLUB_SUCCESS';
 export const EDIT_CLUB_REQUEST = 'EDIT_CLUB_REQUEST';
 export const EDIT_CLUB_RESET = 'EDIT_CLUB_RESET';

 export const GET_CLUB_REQUEST = "GET_CLUB_REQUEST";
export const GET_CLUB_SUCCESS = "GET_CLUB_SUCCESS";
export const GET_CLUB_FAIL = "GET_CLUB_FAIL";
export const GET_CLUB_RESET = "GET_CLUB_FAIL_RESET";