export const FETCH_PLAYERS_GROUPS_REQUEST = 'FETCH_PLAYERS_GROUPS_REQUEST';
export const FETCH_PLAYERS_GROUPS_SUCCESS = 'FETCH_PLAYERS_GROUPS_SUCCESS';
export const FETCH_PLAYERS_GROUPS_FAILURE = 'FETCH_PLAYERS_GROUPS_FAILURE';

// playersGroupsConstants.js
export const PLAYERS_GROUPS_DETAILS_REQUEST = 'PLAYERS_GROUPS_DETAILS_REQUEST';
export const PLAYERS_GROUPS_DETAILS_SUCCESS = 'PLAYERS_GROUPS_DETAILS_SUCCESS';
export const PLAYERS_GROUPS_DETAILS_FAIL = 'PLAYERS_GROUPS_DETAILS_FAIL';

export const UNASSIGN_MEMBER_REQUEST = 'UNASSIGN_MEMBER_REQUEST';
export const UNASSIGN_MEMBER_SUCCESS = 'UNASSIGN_MEMBER_SUCCESS';
export const UNASSIGN_MEMBER_FAIL = 'UNASSIGN_MEMBER_FAIL';

export const CREATE_NEW_GROUP_REQUEST = 'CREATE_NEW_GROUP_REQUEST';
export const CREATE_NEW_GROUP_SUCCESS = 'CREATE_NEW_GROUP_SUCCESS';
export const CREATE_NEW_GROUP_FAIL = 'CREATE_NEW_GROUP_FAIL';
export const RESET_CREATE_NEW_GROUP_STATE = 'RESET_CREATE_NEW_GROUP_STATE';

export const ASSIGN_PLAYER_REQUEST = 'ASSIGN_PLAYER_REQUEST';
export const ASSIGN_PLAYER_SUCCESS = 'ASSIGN_PLAYER_SUCCESS';
export const ASSIGN_PLAYER_FAIL = 'ASSIGN_PLAYER_FAIL';


export const EDIT_PLAYERS_GROUP_REQUEST = 'EDIT_PLAYERS_GROUP_REQUEST';
export const EDIT_PLAYERS_GROUP_SUCCESS = 'EDIT_PLAYERS_GROUP_SUCCESS';
export const EDIT_PLAYERS_GROUP_FAIL = 'EDIT_PLAYERS_GROUP_FAIL';

export const DELETE_PLAYERS_GROUP_REQUEST = 'DELETE_PLAYERS_GROUP_REQUEST';
export const DELETE_PLAYERS_GROUP_SUCCESS = 'DELETE_PLAYERS_GROUP_SUCCESS';
export const DELETE_PLAYERS_GROUP_FAIL = 'DELETE_PLAYERS_GROUP_FAIL';


