import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchScheduledTaskInfo } from "../../actions/staff/scheduledActions";
import ThemeContext from "../../theme/ThemeContext";
import { markAsResolved, confirmAttendance, declineAttendance } from "../../actions/staff/scheduledActions";
import Button from "../../components/cofButton/Button";
import { useTranslation } from 'react-i18next';
import { formatDate, formatBirth } from '../../components/dateUtils';

const AssignedTaskScreen = () => {
  const { scheduledtaskid } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const taskInfo = useSelector((state) => state.scheduledTaskInfo);
  const { loading, error, scheduledTask } = taskInfo;
  console.log(scheduledTask);
  console.log(userInfo)
  // Dohvatite vrednosti boja i fontova iz ThemeContext-a
  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (userInfo) {
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduledtaskid));
    }
  }, [dispatch, userInfo.club, scheduledtaskid]);
 
  // Stil za td elemente
  const tdStyle = {
    border: `1px solid ${colors.primaryColor}`,
    padding: "10px",
  };

  // Funkcija za pronalaženje zadatka na osnovu korisnikove uloge
  const findTaskForUserRole = () => {
    if (userInfo.roles && userInfo.roles.length > 0) {
      const role = userInfo.roles[0].role;

      if (role === "PLAYER") {
        return scheduledTask?.assignments.find((assignment) => assignment.assignedTo._id === userInfo._id);
      } else if (role === "STAFF") {
        return scheduledTask?.staffAssignments.find((staffAssignment) => staffAssignment.assignedTo._id === userInfo._id);
      }
    }

    return null;
  };

  const foundTask = findTaskForUserRole();

  // Dohvat assignedBy i assignedTo u zavisnosti od korisnikove uloge
  const assignedBy = foundTask?.assignedBy || (foundTask?.staffAssignments[0]?.assignedBy || {});
  const assignedTo = foundTask?.assignedTo || (foundTask?.staffAssignments[0]?.assignedTo || {});

  const showNotification = (message, type) => {
    setNotification(message, type);
    // Ne koristimo setTimeout kako bismo obaveštenje ostavili vidljivim
  };

  const handleMarkAsResolved = () => {
    dispatch(markAsResolved(userInfo._id, scheduledTask._id));
    showNotification("Task Marked as Resolved.", "RESOLVED");
  };
  

  const handleConfirmAttendance = () => {
    dispatch(confirmAttendance(userInfo._id, scheduledTask._id));
    showNotification("Potvrdili ste prisustvo.");
  };

  const handleDeclineAttendance = () => {
    dispatch(declineAttendance(userInfo._id, scheduledTask._id));
    showNotification("Odbili ste prisustvo.");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
      {/* Renderujte podatke za jedinstveni zadatak */}
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <div className="pt-5">
          <table
            style={{
              borderCollapse: "collapse",
              border: `1px solid ${colors.secondaryColor}`,
              fontFamily: fonts.primary,
              color: colors.fontcolor,
              backgroundColor: colors.background,
            }}
          >
            <thead>
              <tr>
                <th style={tdStyle}>Attribute</th>
                <th style={tdStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tdStyle}>{t("AssignedBy")}</td>
                <td style={tdStyle}>{assignedBy?.name} {assignedBy?.surname}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Assigned To")}</td>
                <td style={tdStyle}>{assignedTo?.name} {assignedTo?.surname}</td>
              </tr>
              <tr>
              <td style={tdStyle}>{t("CreatedAt")}</td>
              <td style={tdStyle}>{formatDate(scheduledTask?.createdAt)}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("Updated At")}</td>
              <td style={tdStyle}>{formatDate(scheduledTask?.updatedAt)}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("TaskID")}</td>
              <td style={tdStyle}>{scheduledTask?._id}</td>
            </tr>
            <tr>
              <td style={tdStyle}>Status</td>
              <td style={tdStyle}>{scheduledTask?.status}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("TaskName")}</td>
              <td style={tdStyle}>{scheduledTask?.name}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("TaskType")}</td>
              <td style={tdStyle}>{scheduledTask?.type}</td>
            </tr>
            
            <tr>
              <td style={tdStyle}>{t("TaskLocation")}</td>
              <td style={tdStyle}>{scheduledTask?.task?.location}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("Task description")}</td>
              <td style={tdStyle}>{scheduledTask?.description}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("Task Start Time")}</td>
              <td style={tdStyle}>{formatDate(scheduledTask?.startTime)}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("Attendance Confirmation Until")}</td>
              <td style={tdStyle}>{formatDate(scheduledTask?.attendanceConfirmationUntil || "N/A")}</td>
            </tr>
            <tr>
              <td style={tdStyle}>{t("Deadline")}</td>
              <td style={tdStyle}>{formatDate(scheduledTask?.deadline || "N/A")}</td>
            </tr>
          </tbody>
        </table>
          {/* Dugmad za potvrdu i odbijanje prisustva */}
          {scheduledTask?.attendanceConfirmationUntil && (
            <div style={{ marginTop: "20px" }} className="mx-2">
              <p>Attendance Confirmation Until: {formatDate(scheduledTask?.attendanceConfirmationUntil)}</p>
              <Button
                style={{
                  backgroundColor: colors.primaryColor,
                  color: colors.buttonTextColor,
                  padding: "10px 20px",
                  border: "none",
                  cursor: "pointer",
                  marginRight: "40px",
                }}
                className="rounded-0 border-0"
                onClick={handleConfirmAttendance}
              >
                Confirm Attendance
              </Button>
              <Button
                style={{
                  backgroundColor: colors.secondaryColor,
                  color: colors.buttonTextColor,
                  padding: "10px 20px",
                  border: "none",
                  cursor: "pointer",
                }}
                className="rounded-0 border-0 my-2"
                onClick={handleDeclineAttendance}
              >
                Decline Attendance
               
              </Button>
              {notification && <div style={{ marginTop: "10px", color: "green" }}>{notification}</div>}
            </div>
          )}
          {/* Dugme za označavanje zadatka kao rešenog */}
          {scheduledTask?.deadline && scheduledTask?.status !== "RESOLVED" && (
            <div style={{ marginTop: "20px" }} className="mx-2">
            <p>Deadline: {formatDate(scheduledTask?.deadline)}</p>
            <Button
              style={{
                backgroundColor: colors.primaryColor,
                color: colors.buttonTextColor,
                padding: "10px 20px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleMarkAsResolved}
            >
              Mark as Resolved
            </Button>
            {/* Prikazivanje obaveštenja */}
            {notification && (
              <div style={{ marginTop: "10px", color: notification.type === "RESOLVED" ? "green" : notification.type === "confirmed" ? "blue" : "red" }}>
                {notification.message}
              </div>
            )}
          </div>
          
          )}
        </div>
      )}
    </div>
  );
};

export default AssignedTaskScreen;
