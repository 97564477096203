export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET'


export const FETCH_PASSWORD_CONTEXT_REQUEST = 'FETCH_PASSWORD_CONTEXT_REQUEST'
export const FETCH_PASSWORD_CONTEXT_SUCCESS = 'FETCH_PASSWORD_CONTEXT_SUCCESS'
export const FETCH_PASSWORD_CONTEXT_FAIL = 'FETCH_PASSWORD_CONTEXT_FAIL'
export const FETCH_PASSWORD_CONTEXT_RESET = 'FETCH_PASSWORD_CONTEXT_RESET'

export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST'
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS'
export const EDIT_PASSWORD_FAIL = 'EDIT_PASSWORD_FAIL'
export const EDIT_PASSWORD_RESET = 'EDIT_PASSWORD_RESET'