import axios from "axios";

import {
    CREATE_PLAYER_REQUEST,
    CREATE_PLAYER_SUCCESS,
    CREATE_PLAYER_FAIL,

} from "../constants/staff/clubPlayerConstants"
import { logout } from './userActions'
export const createPlayer = (clubid, playerData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_PLAYER_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      console.log(userInfo.token)
      const { data } = await axios.post(`/api/staff/clubs/${clubid}/players`, playerData ,config )
  
      dispatch({
        type: CREATE_PLAYER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CREATE_PLAYER_FAIL,
        payload: message,
      })
    }
  }