// U fajlu sa akcijama (npr. actions.js)
import axios from 'axios';
import {
    GET_CLUB_SETTINGS_REQUEST,
    GET_CLUB_SETTINGS_SUCCESS,
    GET_CLUB_SETTINGS_FAILURE,
    CLUB_SETTINGS_UPDATE_REQUEST,
    CLUB_SETTINGS_UPDATE_SUCCESS,
    CLUB_SETTINGS_UPDATE_FAIL,
  } from '../../constants/staff/clubSettingsConstanst';
  
  
  export const getClubSettings = (clubId) => {
    return async (dispatch, getState) => {
      dispatch({ type: GET_CLUB_SETTINGS_REQUEST });
  
      try {
        const {
          userLogin: { userInfo },
        } = getState();
  
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
  
        // Ovde napravite GET zahtev sa postavkama zaglavlja
        const response = await axios.get(
          `/api/staff/clubs/${clubId}/settings`,
          config
        );
            console.log(response);
        dispatch({
          type: GET_CLUB_SETTINGS_SUCCESS,
          payload: response.data, // Ovo su dohvaćeni podaci sa servera
        });
      } catch (error) {
        dispatch({
          type: GET_CLUB_SETTINGS_FAILURE,
          payload: error.message, // Možete postaviti i dodatne informacije o grešci
        });
      }
    };
  };
  

  // Akcija za ažuriranje postavki kluba
export const updateClubSettings = (clubId, formData) => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch({ type: CLUB_SETTINGS_UPDATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(
        `/api/staff/clubs/${clubId}/settings`,
        formData,
        config
      );
  
      dispatch({ type: CLUB_SETTINGS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CLUB_SETTINGS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };