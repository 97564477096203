import {
    GET_CLUB_SETTINGS_REQUEST,
    GET_CLUB_SETTINGS_SUCCESS,
    GET_CLUB_SETTINGS_FAILURE,
    CLUB_SETTINGS_UPDATE_REQUEST,
    CLUB_SETTINGS_UPDATE_SUCCESS,
    CLUB_SETTINGS_UPDATE_FAIL,
  } from '../../constants/staff/clubSettingsConstanst';
  
  const initialState = {
    settings: null, // Inicijalno postavite na null ili prazan objekat
    loading: false,
    error: null,
  };
  
  export const clubSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CLUB_SETTINGS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_CLUB_SETTINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          settings: action.payload,
        };
      case GET_CLUB_SETTINGS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const updateInitialState = {
    // Početno stanje
    // ...
    isUpdating: false,
    updateSuccess: false,
    error: null,
  };
  
  export const clubSettingsUpdateReducer = (state = updateInitialState, action) => {
    switch (action.type) {
      case CLUB_SETTINGS_UPDATE_REQUEST:
        return {
          ...state,
          isUpdating: true,
        };
      case CLUB_SETTINGS_UPDATE_SUCCESS:
        return {
          ...state,
          isUpdating: false,
          updateSuccess: true,
        };
      case CLUB_SETTINGS_UPDATE_FAIL:
        return {
          ...state,
          isUpdating: false,
          error: action.payload,
        };
      // Dodajte ostale slučajeve akcija prema potrebi
      default:
        return state;
    }
  };
  

  