import {
  // ... ostale konstante
  USER_ASSIGNEDTASKS_REQUEST,
  USER_ASSIGNEDTASKS_SUCCESS,
  USER_ASSIGNEDTASKS_FAIL,

  USER_ASSIGNEDTASK_REQUEST,
  USER_ASSIGNEDTASK_SUCCESS,
  USER_ASSIGNEDTASK_FAIL,
} from "../constants/AssignedTasksConstants";

export const userAssignedTasksReducer = (state = { loading: false, assignedTasks: [], error: null }, action) => {
  switch (action.type) {
    // ... ostali slučajevi
    case USER_ASSIGNEDTASKS_REQUEST:
      return { ...state, loading: true };
    case USER_ASSIGNEDTASKS_SUCCESS:
      console.log("Assigning fetched data to state:", action.payload);
      return { ...state, loading: false, assignedTasks: action.payload, error: null };
    case USER_ASSIGNEDTASKS_FAIL:
      console.error("Error fetching assigned tasks:", action.payload);
      return { ...state, loading: false, assignedTasks: [], error: action.payload };
    default:
      return state;
  }
};


const initialState = {
  loading: false,
  error: null,
  assignedTask: null,
};

export const assignedTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ASSIGNEDTASK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        assignedTask: null,
      };
    case USER_ASSIGNEDTASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assignedTask: action.payload,
      };
    case USER_ASSIGNEDTASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assignedTask: null,
      };
    default:
      return state;
  }
};