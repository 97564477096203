import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Importujte `useSelector` hook za pristup stanju
import { getDocument } from '../actions/uploadAction'; // Importujte akciju za dohvatanje uploadovanih fajlova

const UploadedFilesComponent = ({ scheduledTaskId }) => {
  
  const dispatch = useDispatch();
  
  // Koristite `useSelector` hook za pristup stanju
 
  const documentsState = useSelector((state) => state.documentList);
  const { documents } = documentsState;
 
  
  // Učitavanje uploadovanih fajlova kada se komponenta montira ili kada `scheduledTaskId` promeni
  useEffect(() => {
    console.log('TEST');
    console.log(documents === 0);
    // Provera da li je dokument učitan pre mapiranja
    if (documents) {
        dispatch(getDocument(scheduledTaskId));
    }
}, [dispatch, scheduledTaskId]);

  // Funkcija za preuzimanje fajla
  const handleDownload = (file) => {
    
    window.open(file.url,'_blank')
  };

  return (
    <div>
      <h3>Uploaded Files:</h3>
      {/* Provera da li je uploadedFiles definisan pre mapiranja */}
      {documents && documents.map((file, index) => (
        <div key={index}>
          <p>{file.name}</p> {/* Prikaz naziva fajla */}
          <button onClick={() => handleDownload(file)}>Download</button> {/* Dugme za preuzimanje */}
        </div>
      ))}
    </div>
  );
};

export default UploadedFilesComponent;
