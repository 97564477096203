// actions/staffActions.js

import axios from 'axios';
import {
  FETCH_TRAINERS_REQUEST,
  FETCH_TRAINERS_SUCCESS,
  FETCH_TRAINERS_FAIL,

  FETCH_STAFF_MEMBER_REQUEST,
  FETCH_STAFF_MEMBER_SUCCESS,
  FETCH_STAFF_MEMBER_FAIL,

  DELETE_TRAINER_REQUEST,
  DELETE_TRAINER_SUCCESS,
  DELETE_TRAINER_FAIL,

  CREATE_TRAINER_REQUEST,
  CREATE_TRAINER_SUCCESS,
  CREATE_TRAINER_FAIL,

  EDIT_TRAINER_REQUEST,
  EDIT_TRAINER_SUCCESS,
  EDIT_TRAINER_FAIL,

} from '../../constants/staff/clubStaffConstants';
import { logout } from '../userActions'

export const fetchTrainers = (clubId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_TRAINERS_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/staff/clubs/${clubId}/staff`, config);
  
      dispatch({
        type: FETCH_TRAINERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_TRAINERS_FAIL,
        payload: error.message,
      });
    }
  };
  export const fetchStaffMember = (clubId, staffMemberId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_STAFF_MEMBER_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/staff/clubs/${clubId}/staff/${staffMemberId}`, config);
  
      dispatch({
        type: FETCH_STAFF_MEMBER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_STAFF_MEMBER_FAIL,
        payload: error.message,
      });
    }
  };

  // Akcija za brisanje trenera
  export const deleteTrainer = (clubId, trainerId) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_TRAINER_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      
  
      await axios.delete(`/api/staff/clubs/${clubId}/staff/${trainerId}`, config);
  
      dispatch({ type: DELETE_TRAINER_SUCCESS });
  
      // Nakon brisanja uspešno pozovite akciju za dohvatanje trenera
      dispatch(fetchTrainers(clubId)); // Ovde ažurirate stanje sa novim podacima
    } catch (error) {
      dispatch({
        type: DELETE_TRAINER_FAIL,
        payload: error.response?.data.message || error.message,
      });
    }
  };

  export const createTrainer = (clubId, trainerData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_TRAINER_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.post(`/api/staff/clubs/${clubId}/staff`, trainerData, config);
  
      dispatch({
        type: CREATE_TRAINER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === 'Not authorized, token failed') {
        dispatch(logout());
      }
      dispatch({
        type: CREATE_TRAINER_FAIL,
        payload: message,
      });
    }
  };
  
  export const editTrainer = (trainerData, trainerId, clubId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_TRAINER_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(`/api/staff/clubs/${clubId}/staff/${trainerId}`, trainerData, config); // Promenjen endpoint i HTTP metoda
  
      dispatch({
        type: EDIT_TRAINER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_TRAINER_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
  
  