// actions/adminActions.js
import axios from "axios";

import { GET_CLUBS_REQUEST,
    GET_CLUBS_SUCCESS ,
    GET_CLUBS_FAIL,
    CREATE_CLUB_REQUEST,
    CREATE_CLUB_SUCCESS,
    CREATE_CLUB_FAIL,
    DELETE_CLUB_FAIL,
    DELETE_CLUB_SUCCESS,
    DELETE_CLUB_REQUEST,
    EDIT_CLUB_FAIL,
    EDIT_CLUB_REQUEST,
    EDIT_CLUB_SUCCESS,
    GET_CLUB_FAIL,
    GET_CLUB_REQUEST,
    GET_CLUB_SUCCESS
 } from "../constants/clubConstants"

 import { logout } from './userActions'

export const getClubs = () => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_CLUBS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get("/api/admin/clubs", config);
  
      dispatch({ type: GET_CLUBS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GET_CLUBS_FAIL, payload: error.message });
    }
  };



  export const createClub = (clubData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_CLUB_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.post(`/api/admin/clubs`, clubData, config)
  
      dispatch({
        type: CREATE_CLUB_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: CREATE_CLUB_FAIL,
        payload: message,
      })
    }
  }
  
  export const deleteClub = (clubId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_CLUB_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.delete(`/api/admin/clubs/${clubId}`, config)
  
      dispatch({
        type: DELETE_CLUB_SUCCESS,
        payload: clubId,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: DELETE_CLUB_FAIL,
        payload: message,
      })
    }
  }

  export const editClub = (clubData, clubId,) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_CLUB_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.put(`/api/admin/clubs/${clubId}`, clubData, config)
  
      dispatch({
        type: EDIT_CLUB_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: EDIT_CLUB_FAIL,
        payload: message,
      })
    }
  }

  export const getClub = (clubId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_CLUB_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/admin/clubs/${clubId}`,config);
  
      dispatch({ type: GET_CLUB_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GET_CLUB_FAIL, payload: error.message });
    }
  };
