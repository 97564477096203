import axios from 'axios';
import {
  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
} from "../constants/submitConstants";

export const submitDailyStatus = (userId, requestBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUBMIT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log(requestBody)

    const { data } =
     await axios.post(
      `/api/users/${userId}/dailystatus`,
      requestBody,
      config
    );

    dispatch({
      type: SUBMIT_SUCCESS,
      payload: data,
    });

    return data; // Return the response data to the caller
  } catch (error) {
    dispatch({
      type: SUBMIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    throw error; // Re-throw the error to the caller
  }
};
