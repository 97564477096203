// Akcija (actions/staff/playersGroupsActions.js)

import axios from 'axios';
import {
    FETCH_PLAYERS_GROUPS_REQUEST,
    FETCH_PLAYERS_GROUPS_SUCCESS,
    FETCH_PLAYERS_GROUPS_FAILURE,

    PLAYERS_GROUPS_DETAILS_REQUEST,
    PLAYERS_GROUPS_DETAILS_SUCCESS,
    PLAYERS_GROUPS_DETAILS_FAIL,

    UNASSIGN_MEMBER_REQUEST,
    UNASSIGN_MEMBER_SUCCESS,
    UNASSIGN_MEMBER_FAIL,

    ASSIGN_PLAYER_REQUEST,
  ASSIGN_PLAYER_SUCCESS,
  ASSIGN_PLAYER_FAIL,

  EDIT_PLAYERS_GROUP_REQUEST,
  EDIT_PLAYERS_GROUP_SUCCESS,
  EDIT_PLAYERS_GROUP_FAIL,

  DELETE_PLAYERS_GROUP_REQUEST,
  DELETE_PLAYERS_GROUP_SUCCESS,
  DELETE_PLAYERS_GROUP_FAIL,
  } from '../../constants/staff/playersGroupsConstatnts';
  
  export const fetchPlayersGroups = (clubId) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_PLAYERS_GROUPS_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
    
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    
      const response = await axios.get(`/api/staff/clubs/${clubId}/playersgroups`, config);
  
      dispatch({
        type: FETCH_PLAYERS_GROUPS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PLAYERS_GROUPS_FAILURE,
        payload: error.response.data.message || 'Something went wrong.',
      });
    }
  };

  export const fetchPlayersGroupDetails = (clubId, playersGroupId) => async (dispatch, getState) => {
    try {
      dispatch({ type: PLAYERS_GROUPS_DETAILS_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/staff/clubs/${clubId}/playersgroups/${playersGroupId}`, config);
  
      dispatch({
        type: PLAYERS_GROUPS_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PLAYERS_GROUPS_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const unassignMember = (clubId, playersGroupId, userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: UNASSIGN_MEMBER_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      // Izvršite DELETE zahtev prema API-ju sa dodatnom konfiguracijom
      await axios.delete(`/api/staff/clubs/${clubId}/playersgroups/${playersGroupId}/users/${userId}`, config);
  
      dispatch({ type: UNASSIGN_MEMBER_SUCCESS });
    } catch (error) {
      dispatch({
        type: UNASSIGN_MEMBER_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };



  export const assignPlayer = (clubId, groupId, userId, assignedById) => async (dispatch, getState) => {
    try {
      dispatch({ type: ASSIGN_PLAYER_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const body = {
        assignedById,
      };
  
      await axios.post(`/api/staff/clubs/${clubId}/playersgroups/${groupId}/users/${userId}`, body, config);
  
      dispatch({ type: ASSIGN_PLAYER_SUCCESS });
    } catch (error) {
      dispatch({
        type: ASSIGN_PLAYER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const editPlayersGroup = (clubId, groupId, groupData) => async (dispatch, getState) => {
    try {
      dispatch({ type: EDIT_PLAYERS_GROUP_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { createdBy, name, members } = groupData;
  
      const body = {
        createdBy,
        name,
        members,
      };
  
      await axios.put(`/api/staff/clubs/${clubId}/playersgroups/${groupId}`, body, config);
  
      dispatch({ type: EDIT_PLAYERS_GROUP_SUCCESS });
    } catch (error) {
      dispatch({
        type: EDIT_PLAYERS_GROUP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const deletePlayersGroup = (clubId, groupId) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_PLAYERS_GROUP_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      // Make the DELETE request without a request body
      await axios.delete(`/api/staff/clubs/${clubId}/playersgroups/${groupId}`, config);
  
      dispatch({ type: DELETE_PLAYERS_GROUP_SUCCESS });
    } catch (error) {
      dispatch({
        type: DELETE_PLAYERS_GROUP_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };
  