import { 
    FETCH_PLAYER_INFO_FAIL,
    FETCH_PLAYER_INFO_REQUEST,
    FETCH_PLAYER_INFO_SUCCESS,
    EDIT_PLAYER_INFO_FAIL,
    EDIT_PLAYER_INFO_REQUEST,
    EDIT_PLAYER_INFO_SUCCESS,
    EDIT_PLAYER_INFO_RESET,


} from "../../constants/player/playerConstants";

const editInitialState = {
    loading: false,
    error: null,
    success: false,
  };

  export const editPlayerInfoReducer = (state = editInitialState, action) => {
    switch (action.type) {
      case EDIT_PLAYER_INFO_REQUEST:
        return { loading: true };
      case EDIT_PLAYER_INFO_SUCCESS:
        console.log(action.payload)
        console.log(state)
        return { 
          ...state, 
          loading: false,
           success: true,
            player: action.payload };
      case EDIT_PLAYER_INFO_FAIL:
        return { loading: false, error: action.payload };
        case EDIT_PLAYER_INFO_RESET: 
        return {editInitialState};
      default:
        return state;
    }
  };
  const initialState = {
    loading: false,
    error: null,
  };
  
  export const fetchPlayerInfoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PLAYER_INFO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PLAYER_INFO_SUCCESS:
        return {
          ...state,
          loading: false,
          player: action.payload,
        };
      case FETCH_PLAYER_INFO_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };