import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TaskOverview from "../../components/taskoverview/TaskOverview";
import Button from "../../components/cofButton/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate, formatBirth } from '../../components/dateUtils';
const PlayerHomeScreen = ({ userInfo, context }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  

  const goToDailySurvey = () => {
    navigate("/daily-survey");
  };

  return (
    <Container className="text-center" style={{ paddingTop: "100px" }}>
      <Row style={{ marginBottom: "80px" }}>
        <Col xs={12}>
          <h2 style={{ marginBottom: "80px" }}>
            {t("welcome")}, {userInfo.name} {userInfo.surname}!
          </h2>
          {context && context.dailyStatus && context.dailyStatus.length > 0 ? (
            <div>
              <p>{t("tnxTheCompleteDaileySurvey")}</p>
              <p>{t("Your last daily input is")}:</p>
              <h5>{t("Fitness status today")}: {context.dailyStatus[0].physicalStatus}</h5>
              <h5>{t("Mental status today")}: {context.dailyStatus[0].mentalStatus}</h5>
              <h5>{t("ocenakvalitetasna")}: {context.dailyStatus[0].sleepStatus}</h5>

              <Button
                className={`w-50 w-sm-100 mt-3 rounded-0 border-0`}
                transparent={false}
                onClick={goToDailySurvey}
              >
                {t("Edit Daily Status")}
              </Button>
            </div>
          ) : (
            <>
              <p>{t("notCompletedailySurvey")}</p>
              <Button
                className={`w-50 w-sm-100 rounded-0 border-0`}
                transparent={false}
                onClick={goToDailySurvey}
              >
                {t("completeSurvey")}
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="px-1">
          <h3>{t("taskForToday")}</h3>
          {context &&
            context.scheduledTasksForToday &&
            context.scheduledTasksForToday.length > 0 ? (
            context.scheduledTasksForToday.map((task) => (
              <TaskOverview
                key={task._id}
                task={task.name}
                description={task.description}
                time={formatDate(task.startTime)}
                location={task.location}
                scheduledTaskId={task._id}
              />
            ))
          ) : (
            <p>{t("noTasksforToday")}</p>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="px-1">
          <h3>{t("Last7days")}</h3>
          {context &&
            context.scheduledTasksForThisWeek &&
            context.scheduledTasksForThisWeek.length > 0 ? (
            context.scheduledTasksForThisWeek.map((task) => (
              <TaskOverview
                key={task._id}
                task={task.name}
                description={task.description}
                time={formatDate(task.startTime)}
                location={task.location}
                scheduledTaskId={task._id}
              />
            ))
          ) : (
            <p>{t("noTasksFor7")}</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PlayerHomeScreen;
