import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import "./CustomInput.css"; // Your custom styles

const CustomInput = ({ type = "text", placeholder, value, onChange, signinScreen }) => {
  const icon = type === "text" ? faUser : faLock;
  const iconColor = "#00C613";
  return (
    <div className={`custom-input-container ${signinScreen ? 'signin-screen' : ''}`}>
      {signinScreen && <FontAwesomeIcon icon={icon} className="icon" style={{ color: iconColor, fontSize: "24px", marginLeft: "5px" }} />}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`custom-input ${signinScreen ? 'with-icon' : ''} ${signinScreen ? 'username-label' : ''}`} // Add your custom class for styling
      />
    </div>
  );
};

export default CustomInput;
