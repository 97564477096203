import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useNavigate } from "react-router-dom";
import { fetchPlayerInfo } from "../../actions/staff/clubPlayerActions";
import WeeklyChart from "../common/WeeklyChart";
import NotificationMessage from "../../components/notification/NotificationMessage";
import ThemeContext from "../../theme/ThemeContext";
import TaskOverview from '../../components/taskoverview/TaskOverview';
import { useTranslation } from 'react-i18next';
import { formatDate, formatBirth } from '../../components/dateUtils';

const PlayerHomeScreen = () => {
  const { playerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log(userInfo);

  const { loading, error, playerInfo } = useSelector(
    (state) => state.playerInfo
  );

  console.log(playerInfo)

  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;

  useEffect(() => {
    dispatch(fetchPlayerInfo(userInfo.club, playerId));
  }, [dispatch, playerId]);

  // Generisanje datuma za poslednjih sedam dana
  const currentDate = new Date();
  const lastSevenDays = [...Array(7).keys()].map((days) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - days);
    return date.toISOString().slice(0, 10); // Formatiranje u "YYYY-MM-DD" formatu
  });

  // Generisanje podataka za grafikone
  const physicalData = playerInfo
    ? lastSevenDays.map((date, index) => ({
        date,
        rating: playerInfo.weeklyPhysicalHistory[index],
      }))
    : [];

  const mentalData = playerInfo
    ? lastSevenDays.map((date, index) => ({
        date,
        rating: playerInfo.weeklyMentalHistory[index],
      }))
    : [];

  // Stil za tablu
  const tdStyle = {
    padding: "8px",
    
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
  };

  const handleTaskClick = (taskId) => {
    // Navigacija na željenu rutu
    navigate(`/club/${userInfo.club}/scheduledtask/${taskId}`);
  };

  return (
    <div>
      <h2 style={containerStyle}>{t("Player Information").toUpperCase()}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        playerInfo && (
          <div>
            <div style={containerStyle}>
              <table
                style={{
                  borderCollapse: "collapse",
                  border: `1px solid ${colors.secondaryColor}`,
                  fontFamily: fonts.primary,
                  color: colors.fontcolor,
                  backgroundColor: colors.background,
                  margin: "20px 0",
                  textAlign: "left",
                }}
              >
                <tbody>
                  <tr>
                    <td style={tdStyle}>{t("Name")}:</td>
                    <td style={tdStyle}>{playerInfo.name}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Surname")}:</td>
                    <td style={tdStyle}>{playerInfo.surname}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("age")}:</td>
                    <td style={tdStyle}>{playerInfo.age}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Position")}:</td>
                    <td style={tdStyle}>{playerInfo.position}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Prikaz grafikona za fizičku spremnost */}
            <h3
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
                marginTop: "60px",
              }}
            >
              {t("Physical State")}
            </h3>
            <WeeklyChart data={physicalData} />
            <h4
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
              }}
            >
              {t("Average evaluation")}: {playerInfo.averagePhysicalRating.toFixed(2)}
            </h4>

            {/* Prikaz grafikona za mentalnu snagu */}
            <h3
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
                marginTop: "60px",
              }}
            >
              {t("Mental Condition")}
            </h3>
            <WeeklyChart data={mentalData} />
            <h4
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
                paddingBottom: '30px',
              }}
            >
              {t("Average evaluation")}: {playerInfo.averageMentalRating.toFixed(2)}
            </h4>

            {/* Prikaz notifikacija */}
            <div style={containerStyle}>
              <h3
                style={{
                  textAlign: "center",
                  color: theme.colors.fontcolor,
                  fontFamily: theme.fonts.primary,
                  marginTop: "60px",
                }}
              >
               {t("Notifications")}
              </h3>
              {playerInfo.notifications.map((notification, index) => (
                <NotificationMessage
                  key={index}
                  assigned={notification.send_to.name}
                  time={formatDate(notification.createdAt)}
                  notification={notification.text}
                  style={{
                    // Stilovi za notifikaciju
                    padding: "8px",
                    border: `1px solid ${colors.primaryColor}`,
                    marginBottom: "10px",
                    backgroundColor: colors.background,
                    color: colors.fontcolor,
                  }}
                />
              ))}
              
            </div>
            <h2>{t("Assigned Tasks")}</h2>
            {playerInfo.assignedTasks.map((assignedTask) => (
              <TaskOverview
              key={assignedTask._id}
              task={assignedTask.name}
              description={assignedTask.description}
              time={formatDate(assignedTask.startTime)}
              location={assignedTask.location}
              scheduledTaskId={assignedTask._id}
              onTaskClick={handleTaskClick} //
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default PlayerHomeScreen;
