import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlayersGroupDetails, unassignMember, assignPlayer } from '../../actions/staff/playersGroupsActions';
import { useParams } from 'react-router-dom';
import DataTable from '../../components/table/DataTable';
import  Button  from '../../components/cofButton/Button';
import { useTranslation } from 'react-i18next';
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate, formatBirth } from '../../components/dateUtils';
const PlayersGroupsDetailsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { playersGroupId } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
    console.log(userInfo);
  const playersGroupDetails = useSelector((state) => state.playersGroupDetails);
  const { loading, error, group } = playersGroupDetails;

  const playersGroupDetailsUnassign = useSelector((state) => state.unassignMemberGroup);
  const { success: successDelete } = playersGroupDetailsUnassign;

  const playersGroupDetailsAssign = useSelector((state) => state.playersGroupAssign);
  const { success: successCreate} = playersGroupDetailsAssign;

  const [selectedMember, setSelectedMember] = useState('');

  useEffect(() => {
    dispatch(fetchPlayersGroupDetails(userInfo.club, playersGroupId));
  }, [dispatch, userInfo.club, playersGroupId, successDelete, successCreate]);

  const columns = [
    { label: t("Member").toUpperCase(), dataKey: 'name' },
    { label: t("AssignedBy").toUpperCase(), dataKey: 'assignedBy' },
    { label: t("MemberFrom").toUpperCase(), dataKey: 'memberFrom',format: formatBirth },
  ];

  const membersData = group && group.members
  ? group.members.map(member => ({
    name: member.member && member.member.name && member.member.surname
      ? `${member.member.name} ${member.member.surname}`
      : '',
    assignedBy: member.assignedBy && member.assignedBy.name ? member.assignedBy.name : '',
    memberFrom: member.memberFrom,
    _id: member.member._id,
  }))
  : [];
 


  const handleUnassign = (userId) => {
    dispatch(unassignMember(userInfo.club, playersGroupId, userId));
  };

  const handleMemberSelect = (event) => {
    setSelectedMember(event.target.value);
  };

  const handleAssign = () => {
    if (selectedMember) {
      dispatch(assignPlayer(userInfo.club, playersGroupId, selectedMember, userInfo._id));
      setSelectedMember('');
    }
  };

  const actionHandlers = {
    [t("RemoveFromGroup")]: handleUnassign,
  };

  const actions = [{ variant: "danger", icon: faTrash, label: t("Delete") }];

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
          <div>
            <p>{t("Club" )}: {group.club ? group.club.name : " "}</p>
            <p>{t("CreatedAt")}: {formatDate(group.createdAt)}</p>
            <p>{t("CreatedBy")}: {group.createdBy ? group.createdBy.name + " " + group.createdBy.surname : " "}</p>
          </div>

          <div>
          <select value={selectedMember} onChange={handleMemberSelect}  style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}>
  <option value="">{t("SelectMember")}</option>
  {group && group.potentialMembers
    ? group.potentialMembers
      .filter((member) => !membersData.some((data) => data._id === member._id))
      .map((member) => (
        <option key={member._id} value={member._id}>
          {member.name} {member.surname}
        </option>
      ))
    : null}
</select>
        
          <Button onClick={handleAssign} className={"mx-2 my-2 rounded-0 border-0"} >{t("AddGroupMember")}</Button>
        </div>

          {group && group.members ? (
            <DataTable
              columns={columns}
              data={membersData}
              actions={actions}
              actionLabels={[t("RemoveFromGroup")]}
              buttonStyle={{ marginRight: '5px'}}
              actionHandlers={actionHandlers}
            />
          ) : (
            <p>{t("NoMembersFound")}.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PlayersGroupsDetailsScreen;
