import axios from "axios";
import {
    FETCH_STAFF_MEMBER_INFO_REQUEST,
    FETCH_STAFF_MEMBER_INFO_FAIL,
    FETCH_STAFF_MEMBER_INFO_SUCCESS,
    EDIT_STAFF_MEMBER_INFO_FAIL,
    EDIT_STAFF_MEMBER_INFO_REQUEST,
    EDIT_STAFF_MEMBER_INFO_SUCCESS
} from '../../../constants/staff/info/staffInfoConstants';

export const editStaffMemberInfo = (userid, StaffInfoData) => async (dispatch, getState) =>{
    try {
     dispatch({
         type: EDIT_STAFF_MEMBER_INFO_REQUEST,
     });
     const {
       userLogin: { userInfo },
     } = getState();
 
     const config = {
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${userInfo.token}`,
       },
     };
   
       const { data } = await axios.put(
       `/api/staff/users/${userid}/personalinfo`,
       StaffInfoData,
         config
       );
   
       dispatch({
         type: EDIT_STAFF_MEMBER_INFO_SUCCESS,
         payload: data,
       });
 
    } catch (error) {
     dispatch({
       type: EDIT_STAFF_MEMBER_INFO_FAIL,
       payload:
         error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
     });
   }
 
 }

 export const fetchStaffMemberInfo = (userid) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_STAFF_MEMBER_INFO_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/staff/users/${userid}/personalinfo`, config);
  
      dispatch({ type: FETCH_STAFF_MEMBER_INFO_SUCCESS , payload: data });
    } catch (error) {
      dispatch({ type: FETCH_STAFF_MEMBER_INFO_FAIL, payload: error.message });
    }
  };