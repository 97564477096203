import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "./menu/Sidebar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap"; // Dodali smo Dropdown komponentu
import { useSelector } from 'react-redux';
import "./Header.css"
export default function Header() {
  const { i18n } = useTranslation();

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
  };
  const userLogin = useSelector((state) => state.userLogin);
const { userInfo } = userLogin;

  const languageOptions = [
    { value: "en", label: "English", flag: "../../img/united.png" },
    { value: "srb", label: "Serbian", flag: "https://example.com/flags/serbian.png" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Row className="pt-3 pb-3">
        <Col xs={3} className="d-flex align-items-center justify-content-center" >
          <Sidebar />
        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-center">
          <Link to={`/`}>
            <img
            className="cofimage"
              src="/../../images/LogoCoF.png"
              alt="Concierge of Football logo"
              
            />
          </Link>
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-center">
           <div>
    {userInfo ? (
      <Link to={`/notifications`}>
        <FontAwesomeIcon icon={faBell} className="bellicon" style={{ color: "#00c613" }} />
      </Link>
    ) : (
      <div>
        {/* Ovdje možete staviti neki drugi sadržaj ili poruku */}
      </div>
    )}
  </div>
          
        </Col>
      </Row>
    </div>
  );
}
