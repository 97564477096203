// documentReducer.js

import {
    UPLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,

    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAIL,
  } from '../../src/constants/uploadConstants';
  
  const initialState = {
    loading: false,
    success: false,
    error: null,
  };
  
  export const documentUploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOAD_DOCUMENT_REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          error: null,
        };
      case UPLOAD_DOCUMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: null,
        };
      case UPLOAD_DOCUMENT_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  const getinitialState = {
    loading: false,
    documents: [],
    error: null,
  };
  
  export const documentListReducer = (state = getinitialState, action) => {
    switch (action.type) {
      case GET_DOCUMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_DOCUMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          documents: action.payload,
          error: null,
        };
      case GET_DOCUMENT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  