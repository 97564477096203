export const SCHEDULED_TASKS_REQUEST = 'SCHEDULED_TASKS_REQUEST';
export const SCHEDULED_TASKS_SUCCESS = 'SCHEDULED_TASKS_SUCCESS';
export const SCHEDULED_TASKS_FAIL = 'SCHEDULED_TASKS_FAIL';
export const SCHEDULED_TASKS_RESET = 'SCHEDULED_TASKS_RESET';

export const CREATE_SCHEDULED_TASKS_REQUEST = 'CREATE_TASKS_REQUEST';
export const CREATE_SCHEDULED_TASKS_SUCCESS = 'CREATE_TASKS_SUCCESS';
export const CREATE_SCHEDULED_TASKS_FAIL = 'CREATE_TASKS_FAIL';
export const CREATE_SCHEDULED_TASKS_RESET = 'CREATE_SCHEDULED_TASKS_RESET';

// scheduledTaskConstants.js
export const FETCH_SCHEDULED_TASK_INFO_REQUEST = 'FETCH_SCHEDULED_TASK_INFO_REQUEST';
export const FETCH_SCHEDULED_TASK_INFO_SUCCESS = 'FETCH_SCHEDULED_TASK_INFO_SUCCESS';
export const FETCH_SCHEDULED_TASK_INFO_FAIL = 'FETCH_SCHEDULED_TASK_INFO_FAIL';
export const UNASSIGN_MEMBER_REQUEST = 'UNASSIGN_MEMBER_REQUEST';
export const UNASSIGN_MEMBER_SUCCESS = 'UNASSIGN_MEMBER_SUCCESS';
export const UNASSIGN_MEMBER_FAIL = 'UNASSIGN_MEMBER_FAIL';
export const UNASSIGN_MEMBER_RESET = 'UNASSIGN_MEMBER_RESET';

export const EDIT_SCHEDULED_TASK_REQUEST = 'EDIT_SCHEDULED_TASK_REQUEST';
export const EDIT_SCHEDULED_TASK_SUCCESS = 'EDIT_SCHEDULED_TASK_SUCCESS';
export const EDIT_SCHEDULED_TASK_FAIL = 'EDIT_SCHEDULED_TASK_FAIL';
export const EDIT_SCHEDULED_TASK_RESET = 'EDIT_SCHEDULED_TASK_RESET';


export const FETCH_UNASSIGNED_USER_SCHEDULED_TASK_REQUEST='FETCH_UNASSIGNED_USER_SCHEDULED_TASK_REQUEST'
export const FETCH_UNASSIGNED_USER_SCHEDULED_TASK_SUCCESS='FETCH_UNASSIGNED_USER_SCHEDULED_TASK_SUCCESS'
export const FETCH_UNASSIGNED_USER_SCHEDULED_TASK_FAIL='FETCH_UNASSIGNED_USER_SCHEDULED_TASK_FAIL'
export const FETCH_UNASSIGNED_USER_SCHEDULED_TASK_RESET='FETCH_UNASSIGNED_USER_SCHEDULED_TASK_RESET'
export const ASSIGN_USER_REQUEST='ASSIGN_USER_REQUEST';
export const ASSIGN_USER_SUCCESS='ASSIGN_USER_SUCCESS';
export const ASSIGN_USER_FAIL='ASSIGN_USER_FAIL';
export const ASSIGN_USER_RESET='ASSIGN_USER_RESET';

// staffActionTypes.js

export const FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_REQUEST = 'FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_REQUEST';
export const FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_SUCCESS = 'FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_SUCCESS';
export const FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_FAIL = 'FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_FAIL';
export const FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_RESET = 'FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_RESET';


// scheduledConstants.js

export const CREATE_BATCH_SCHEDULED_TASKS_REQUEST = 'CREATE_BATCH_SCHEDULED_TASKS_REQUEST';
export const CREATE_BATCH_SCHEDULED_TASKS_SUCCESS = 'CREATE_BATCH_SCHEDULED_TASKS_SUCCESS';
export const CREATE_BATCH_SCHEDULED_TASKS_FAIL = 'CREATE_BATCH_SCHEDULED_TASKS_FAIL';
export const RESET_CREATE_BATCH_SCHEDULED_TASKS_STATE = 'RESET_CREATE_BATCH_SCHEDULED_TASKS_STATE';

// Dodajte druge konstante prema potrebi

// scheduledConstants.js
export const FILTER_SCHEDULED_TASKS_REQUEST = 'FILTER_SCHEDULED_TASKS_REQUEST';
export const FILTER_SCHEDULED_TASKS_SUCCESS = 'FILTER_SCHEDULED_TASKS_SUCCESS';
export const FILTER_SCHEDULED_TASKS_FAILURE = 'FILTER_SCHEDULED_TASKS_FAILURE';
export const FILTER_SCHEDULED_TASKS_RESET = 'FILTER_SCHEDULED_TASKS_RESET';

// constants/scheduledActionsConstants.js

export const CONFIRM_ATTENDANCE_REQUEST = "CONFIRM_ATTENDANCE_REQUEST";
export const CONFIRM_ATTENDANCE_SUCCESS = "CONFIRM_ATTENDANCE_SUCCESS";
export const CONFIRM_ATTENDANCE_FAIL = "CONFIRM_ATTENDANCE_FAIL";

export const DECLINE_ATTENDANCE_REQUEST = "DECLINE_ATTENDANCE_REQUEST";
export const DECLINE_ATTENDANCE_SUCCESS = "DECLINE_ATTENDANCE_SUCCESS";
export const DECLINE_ATTENDANCE_FAIL = "DECLINE_ATTENDANCE_FAIL";

export const MARK_AS_RESOLVED_REQUEST = "MARK_AS_RESOLVED_REQUEST";
export const MARK_AS_RESOLVED_SUCCESS = "MARK_AS_RESOLVED_SUCCESS";
export const MARK_AS_RESOLVED_FAIL = "MARK_AS_RESOLVED_FAIL";
