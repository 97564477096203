export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAIL = "FETCH_ADMINS_FAIL";

export const EDIT_ADMIN_REQUEST = "EDIT_ADMIN_REQUEST";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAIL = "EDIT_ADMIN_FAIL";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";
export const DELETE_ADMIN_RESET = 'DELETE_ADMIN_RESET';

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";
export const CREATE_ADMIN_RESET = 'CREATE_ADMIN_RESET';

export const FETCH_ADMIN_REQUEST = "FETCH_ADMIN_REQUEST";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAIL = "FETCH_ADMIN_FAIL";