import React, { useEffect } from "react";
import { Container, Form, Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../actions/userActions";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/cofButton/Button";
import { useTranslation } from 'react-i18next';
import CustomInput from "../../../components/customInput/CustomInput";
import {subscribeToNotifications, unsubscribeFromNotifications} from "../../../actions/pushNotificationActions"
const SignInScreen = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = "/"; // Promenjen fallback URL

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  <style>
  {`
  @media screen and (max-width: 767px) {
    .custom-input.smaller-width,
    .custom-button {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }

    

  }
  
  @media screen and (max-width: 480px) {
    body .custom-input.smaller-width,
    body .custom-button {
      max-width: 250px;
    }
  }
  `}
</style>

return (
  <Container
    className="d-flex align-items-center justify-content-center"
    style={{ paddingTop: "100px" }}
  >
    <Row className="w-100">
      <Col xs={12} className="text-center mb-3">
        <h2>{t("Sign In to CoF")}</h2>
      </Col>

      <Col xs={12} lg={8} className="mx-auto">
        {error && <Alert variant="danger">{error}</Alert>}
        <Form className="text-center">
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Username")}
              onChange={(e) => setEmail(e.target.value)}
              signinScreen={true}
              className="custom-input smaller-width"
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "20px" }}>
            <CustomInput
              type="password"
              placeholder={t("Password")}
              onChange={(e) => setPassword(e.target.value)}
              signinScreen={true}
              className="custom-input smaller-width"
            />
          </Form.Group>
          <Button
            className="w-100 rounded-0 border-0 custom-button"
            transparent={false}
            onClick={handleSignIn}
          >
            {t("SignIn")}
          </Button>
        </Form>

        <div className="d-flex justify-content-between w-100 mt-3">
          <Link to="/reset-password" style={{ color: "rgb(0,197,35)" }}>
            {t("Forgot your password?")}
          </Link>
          <Link to="/signup" style={{ color: "rgb(0,197,35)" }}>
            {t("Sign Up")}
          </Link>
        </div>
      </Col>
    </Row>
  </Container>
);



};

export default SignInScreen;
