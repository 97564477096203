import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Alert } from "react-bootstrap";
import Button from "../../components/cofButton/Button";
import { useDispatch, useSelector } from "react-redux";
import { createTrainer } from "../../actions/staff/clubStaffActions";
import { useNavigate } from "react-router-dom";
import { RESET_CREATE_TRAINER_STATE } from '../../constants/staff/clubStaffConstants'
import { useTranslation } from 'react-i18next';
import CustomInput from "../../components/customInput/CustomInput"

const CreateStaffMemberScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [trainerName, setTrainerName] = useState("");
    const [trainerLastName, setTrainerLastName] = useState("");
    const [trainerPhone, setTrainerPhone] = useState("");
    const [trainerEmail, setTrainerEmail] = useState("");
    const [trainerPosition, setTrainerPosition] = useState("");
    const [trainerPassword, setTrainerPassword] = useState("");
    const [useExistingTrainer, setUseExistingTrainer] = useState(false);
    const [trainerId, setTrainerId] = useState("");
    const [trainerEducation, setTrainerEducation] = useState("");
    const [trainerBirthdate, setTrainerBirthdate] = useState("");
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
   console.log(userInfo)
    const trainerCreate = useSelector((state) => state.createTrainer);
    const { loading, error, success } = trainerCreate;
    
    const [errorAlert, setErrorAlert] = useState(false);

    useEffect(() => {    
        console.log("Poziva se useEffect")
        console.log(trainerCreate)
        if (success) {
            navigate(`/club/${userInfo.club}/staff`);
            dispatch({ type: RESET_CREATE_TRAINER_STATE })
        } else if (error) {
            setErrorAlert(true);
        }
    }, [success, error]);
    console.log(trainerCreate)
    const handleTrainerCreate = (e) => {
        e.preventDefault();
        if (useExistingTrainer) {
            const trainerInfo = { staffmemberid: trainerId , position: trainerPosition,};
            dispatch(createTrainer(userInfo.club, trainerInfo));
        } else {
            const trainerData = {
                name: trainerName,
                surname: trainerLastName,
                email: trainerEmail,
                position: trainerPosition,
                password: trainerPassword,
                education: trainerEducation,
                birthdate: trainerBirthdate,
            };
            console.log(trainerData)
            dispatch(createTrainer(userInfo.club, trainerData));
        }
        // Navigacija je sada u useEffect
    };
    
    return (
        <div>
            <Container
                className="d-flex align-items-center justify-content-center"
                style={{ paddingTop: "100px" }}
            >
                <Row>
                    <Col
                        xs={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <h2>{t("CreateNewTrainer").toUpperCase()}</h2>
                    </Col>
                    <Col xs={12}>
                        <Form>
                            <Form.Group controlId="existingTrainerCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    label={t("ExistingTrainer")}
                                    checked={useExistingTrainer}
                                    onChange={() => setUseExistingTrainer(!useExistingTrainer)}
                                />
                            </Form.Group>
                            {useExistingTrainer ? (
                                <div>
                                  <Form.Group style={{ marginBottom: "10px" }}>
                                    <CustomInput
                                        type="text"
                                        placeholder={t("TrainerID")}
                                        value={trainerId}
                                        onChange={(e) => setTrainerId(e.target.value)}
                                    />
                                </Form.Group>
                                 <Form.Group style={{ marginBottom: "10px" }}>
                                 <Form.Control
                  as="select"
                  value={trainerPosition}
                  onChange={(e) => setTrainerPosition(e.target.value)}
                  style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
                   >
                  <option value="">{t("SelectPosition")}</option>
                  <option value="HEAD_COACH">{t("HEAD_COACH")}</option>
                  <option value="FIRST_ASSISTENT">{t("FIRST_ASSISTENT")}</option>
                  <option value="ASSISTENT_COACH">{t("ASSISTENT_COACH")}</option>
                  <option value="ANALIST">{t("ANALIST")}</option>
                  <option value="MAIN_GOALKEEPER_COACH">{t("MAIN_GOALKEEPER_COACH")}</option>
                  <option value="GOALKEEPER_COACH">{t("GOALKEEPER_COACH")}</option>
                  <option value="MAIN_FITNESS_TRAINER">{t("MAIN_FITNESS_TRAINER")}</option>
                  <option value="FITNESS_TRAINER">{t("FITNESS_TRAINER")}</option>
                  <option value="DOCTOR">{t("DOCTOR")}</option>
                  <option value="PHISIO">{t("PHISIO")}</option>
                  <option value="NUTRICIONIST">{t("NUTRICIONIST")}</option>
                  <option value="TEAM_MENAGER">{t("TEAM_MENAGER")}</option>
                </Form.Control>
                             </Form.Group>
                                </div>
                              
                            ) : (
                                <>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                        <CustomInput
                                            type="text"
                                            placeholder={t("Name")}
                                            value={trainerName}
                                            onChange={(e) => setTrainerName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                        <CustomInput
                                            type="text"
                                            placeholder={t("Surname")}
                                            value={trainerLastName}
                                            onChange={(e) => setTrainerLastName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                        <CustomInput
                                            type="text"
                                            placeholder={t("PhoneNumber")}
                                            value={trainerPhone}
                                            onChange={(e) => setTrainerPhone(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                        <CustomInput
                                            type="text"
                                            placeholder={t("Email")}
                                            value={trainerEmail}
                                            onChange={(e) => setTrainerEmail(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                    <Form.Control
                  as="select"
                  value={trainerPosition}
                  onChange={(e) => setTrainerPosition(e.target.value)}
                  style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
                   >
                  <option value="">{t("SelectPosition")}</option>
                  <option value="HEAD_COACH">{t("HEAD_COACH")}</option>
                  <option value="FIRST_ASSISTENT">{t("FIRST_ASSISTENT")}</option>
                  <option value="ASSISTENT_COACH">{t("ASSISTENT_COACH")}</option>
                  <option value="ANALIST">{t("ANALIST")}</option>
                  <option value="MAIN_GOALKEEPER_COACH">{t("MAIN_GOALKEEPER_COACH")}</option>
                  <option value="GOALKEEPER_COACH">{t("GOALKEEPER_COACH")}</option>
                  <option value="MAIN_FITNESS_TRAINER">{t("MAIN_FITNESS_TRAINER")}</option>
                  <option value="FITNESS_TRAINER">{t("FITNESS_TRAINER")}</option>
                  <option value="DOCTOR">{t("DOCTOR")}</option>
                  <option value="PHISIO">{t("PHISIO")}</option>
                  <option value="NUTRICIONIST">{t("NUTRICIONIST")}</option>
                  <option value="TEAM_MENAGER">{t("TEAM_MENAGER")}</option>
                </Form.Control>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                        <CustomInput
                                            type="text"
                                            placeholder={t("TrainerPassword")}
                                            value={trainerPassword}
                                            onChange={(e) => setTrainerPassword(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: "10px" }}>
                                        <CustomInput
                                            type="text"
                                            placeholder={t("Education")}
                                            value={trainerEducation}
                                            onChange={(e) => setTrainerEducation(e.target.value)}
                                        />
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: "10px" }}>
                                            <CustomInput
                                                type="date"
                                                placeholder={t("enter your Birthdate")}
                                                value={trainerBirthdate}
                                                onChange={(e) => setTrainerBirthdate(e.target.value)}
                                            />
                                        </Form.Group>
                                </>
                            )}
                            <Button
                                className="w-100 rounded-0 border-0"
                                transparent={false}
                                onClick={handleTrainerCreate}
                            >
                                {t("CreateTrainer")}
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={12}>
                        {errorAlert && (
                            <Alert variant="danger" onClose={() => setErrorAlert(false)} dismissible>
                                {error}
                            </Alert>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CreateStaffMemberScreen;
