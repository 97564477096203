import {
    GET_CLUBS_DETAILS_REQUEST,
    GET_CLUBS_DETAILS_SUCCESS ,
    GET_CLUBS_DETAILS_FAIL,
} from "../constants/clubDetailsConstants"

const initialState = {
    club: null,
    loading: false,
    error: null,
  };

export const getClubDetailsReducer = (state = initialState, action) =>{
    switch (action.type) {
        case GET_CLUBS_DETAILS_REQUEST:
          return { ...state, loading: true };
        case GET_CLUBS_DETAILS_SUCCESS:
          return { loading: false, club: action.payload };
        case GET_CLUBS_DETAILS_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }


};