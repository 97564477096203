import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../components/cofButton/Button";
import DataTable from "../../components/table/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getClubs, createClub } from "../../actions/clubActions";
import { useNavigate } from "react-router-dom";
import { deleteClub } from "../../actions/clubActions";
import { useTranslation } from 'react-i18next';
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DELETE_CLUB_RESET } from "../../constants/clubConstants";
const ClubsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clubList = useSelector((state) => state.clubs);
  const { loading, error, clubs } = clubList;

  const clubCreate = useSelector((state) => state.clubCreate);
  const { success: createSuccess } = clubCreate;

  const clubDelete = useSelector((state) => state.deleteClub);
  const { success: deleteSucess} = clubDelete;
  console.log(clubDelete);
  const clubColumns = [
    { label: t("Name").toUpperCase(), dataKey: "name" },
    { label: t("PIB").toUpperCase(), dataKey: "pib" },
  ];

  const allowedActions = [
    { icon: faInfo, label: t("Info"),style: { backgroundColor: 'rgb(0, 198, 19)' } },
    { variant: "info", icon: faEdit, label: t("Edit") },
    { variant: "danger", icon: faTrash, label: t("Delete") },
  ];
    
  const handleDeleteClub = (clubId) => {
    dispatch(deleteClub(clubId));
  };

  const handleShowDetails = (clubId) => {
    navigate(`/admin/clubs/${clubId}/details`);
  };
  const handleEditClub = (clubId) =>{
    navigate(`/admin/clubs/${clubId}/editClub`);
  }
  useEffect(() => {
    dispatch(getClubs());
    dispatch({type:DELETE_CLUB_RESET});
  }, [dispatch, deleteSucess, createSuccess]); // Dodali smo createSuccess kao zavisnost

  const formatClubsForTable = (clubs) => {
    return clubs.map((club) => ({
      ...club,
    }));
  };

  const clubHandler = {
    [t("Info")]: handleShowDetails,
    [t("Delete")]: handleDeleteClub,
    [t("Edit")]: handleEditClub
  };

  const createClubHandler = () => {
    navigate("/admin/clubs/create");
  };

  return (
    <Container>
      <Row className="align-items-center">
        <Col>
          <h1>{t("Clubs").toUpperCase()}</h1>
        </Col>
        <Col className="text-right">
          <Button className="my-3 float-end rounded-0 border-0" onClick={createClubHandler}>
            <i className="fas fa-plus"></i> {t("NewClub")}
          </Button>
        </Col>
      </Row>
      {clubs && (
        <DataTable
          columns={clubColumns}
          data={formatClubsForTable(clubs)}
          loading={loading}
          error={error}
          actions={allowedActions}
          actionLabels={allowedActions.map((action) => action.label)}
          buttonStyle={{ margin: "5px", width:"40px" }}
          actionHandlers={clubHandler}
        />
      )}
    </Container>
  );
};

export default ClubsScreen;
