// actions/adminActions.js
import axios from "axios";

import {
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAIL,

  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAIL,

  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,

  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAIL,

  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_FAIL,
} from "../constants/AdminsConstants";
import { logout } from './userActions'

// Akcija za dohvatanje admina
export const fetchAdmins = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_ADMINS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get("/api/admin/admins", config);

    dispatch({ type: FETCH_ADMINS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_ADMINS_FAIL, payload: error.message });
  }
};


export const deleteAdmin = (adminId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ADMIN_REQUEST });

    // Dohvatite userInfo iz state-a
    const {
      userLogin: { userInfo },
    } = getState();

    // Postavite konfiguraciju za zahtev sa odgovarajućim headerima
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Izvršite DELETE zahtev prema API-ju za brisanje admina
    await axios.delete(`/api/admin/admins/${adminId}`, config);

    // Ažurirajte stanje nakon uspešnog brisanja
    dispatch({ type: DELETE_ADMIN_SUCCESS, payload: adminId });
  } catch (error) {
    // Ako se desi greška, dispečujte odgovarajuću akciju za neuspeh
    dispatch({ type: DELETE_ADMIN_FAIL, payload: error.message });
  }
};

export const createAdmin = (adminData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_ADMIN_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/admin/admins`, adminData, config);

    dispatch({
      type: CREATE_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: CREATE_ADMIN_FAIL,
      payload: message,
    });
  }
};

export const editAdmin = (adminData, adminId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_ADMIN_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/admin/admins/${adminId}`, adminData, config); // Promenjen endpoint i HTTP metoda

    dispatch({
      type: EDIT_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDIT_ADMIN_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const fetchAdminById = (adminId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_ADMIN_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/admin/admins/${adminId}`, config);

    dispatch({
      type: FETCH_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ADMIN_FAIL,
      payload: error.message,
    });
  }
};