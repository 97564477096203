import {
    DAILY_STATUS_REQUEST,
    DAILY_STATUS_SUCCESS ,
    DAILY_STATUS_FAIL,
    DAILY_STATUS_RESET
} from "../constants/dailyStatusConstants"

const initialState = {
    dailystatus: null,
  loading: false,
  error: null,
};

export const getDailyStatusReducer = (state = initialState, action) =>{
    switch (action.type) {
        case DAILY_STATUS_REQUEST:
          return { ...state, loading: true };
        case DAILY_STATUS_SUCCESS:
          return { loading: false, dailystatus: action.payload };
        case DAILY_STATUS_FAIL:
          return { loading: false, error: action.payload };
          case DAILY_STATUS_RESET:
          return initialState;
        default:
          return state;
      }


};