import {
    USER_WEEKLY_OVERVIEW_REQUEST,
    USER_WEEKLY_OVERVIEW_SUCCESS ,
    USER_WEEKLY_OVERVIEW_FAIL,
} from "../constants/weeklyoverviewConstants"

const initialState = {
    weeklyoverview: null,
  loading: false,
  error: null,
};

export const getWeeklyoverviewReducer = (state = initialState, action) =>{
    switch (action.type) {
        case USER_WEEKLY_OVERVIEW_REQUEST:
          return { ...state, loading: true };
        case USER_WEEKLY_OVERVIEW_SUCCESS:
          return { loading: false, weeklyoverview: action.payload };
        case USER_WEEKLY_OVERVIEW_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }


};