import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchStaffMember } from "../../actions/staff/clubStaffActions";
import NotificationMessage from "../../components/notification/NotificationMessage";
import TaskOverview from "../../components/taskoverview/TaskOverview";
import ThemeContext from "../../theme/ThemeContext";
import { useTranslation } from 'react-i18next';

const StaffMemberScreen = () => {
  const { t } = useTranslation();
  const { staffMemberId } = useParams();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const staffMemberInfo = useSelector((state) => state.staffMember);
  const { loading, error, staffMember } = staffMemberInfo;
  console.log(staffMember);
  useEffect(() => {
    dispatch(fetchStaffMember(userInfo.club, staffMemberId));
  }, [dispatch, staffMemberId, userInfo.club]);
  console.log(staffMember);
  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;

  const tdStyle = {
    padding: "8px",
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
  };

  const formatDate = (createdAt) => {
    return new Date(createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const formatBirth = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };


  return (
    <div>
      <h2 style={containerStyle}>{t("Staff Info").toUpperCase()}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        staffMember && (
          <div style={containerStyle}>
            <table
              style={{
                borderCollapse: "collapse",
                border: `1px solid ${colors.secondaryColor}`,
                fontFamily: fonts.primary,
                color: colors.fontcolor,
                backgroundColor: colors.background,
                margin: "20px 0",
              }}
            >
              <tbody style={{ textAlign: "left" }}>
                <tr>
                  <td style={tdStyle}>{t("Name")}:</td>
                  <td style={tdStyle}>{staffMember.name}</td>
                </tr>
                <tr>
                  <td style={tdStyle}>{t("Surname")}:</td>
                  <td style={tdStyle}>{staffMember.surname}</td>
                </tr>
                <tr>
                  <td style={tdStyle}>{t("email")}:</td>
                  <td style={tdStyle}>{staffMember.email}</td>
                </tr>
                <tr>
                  <td style={tdStyle}>{t("birthdate")}:</td>
                  <td style={tdStyle}>{formatBirth(staffMember.birthdate)}</td>
                </tr>
                <tr>
                  <td style={tdStyle}>{t("Position")}:</td>
                  <td style={tdStyle}>{t(staffMember.position)}</td>
                </tr>
                <tr>
                  <td style={tdStyle}>{t("Education")}</td>
                  <td style={tdStyle}>{staffMember.education}</td>
                </tr>
              </tbody>
            </table>

            {/* Prikaz notifikacija */}
            <div style={containerStyle}>
              <h3
                style={{
                  textAlign: "center",
                  color: colors.fontcolor,
                  fontFamily: fonts.primary,
                  marginTop: "60px",
                }}
              >
                {t("Notifications")}
              </h3>
              {staffMember.notifications && staffMember.notifications.length > 0 ? (
                staffMember.notifications.map((notification, index) => (
                  <NotificationMessage
                    key={index}
                    assigned={notification.assignedBy ? notification.assignedBy.name : " "}
                    time={formatDate(notification.createdAt)} // Formatiranje vremena koristeći formatDate funkciju
                    notification={notification.text}
                    style={{
                      padding: "8px",
                      border: `1px solid ${colors.primaryColor}`,
                      marginBottom: "10px",
                      backgroundColor: colors.background,
                      color: colors.fontcolor,
                    }}
                  />
                ))
              ) : (
                <p style={{ color: colors.fontcolor }}>
                  {t("There are no notifications")}
                </p>
              )}

            </div>

            {/* Prikaz dodijeljenih zadataka */}
            <div>
              <h2 style={{ color: colors.fontcolor }}>{t("Assigned Tasks")}</h2>
              {staffMember.assignedTasks && staffMember.assignedTasks.length > 0 ? (
                staffMember.assignedTasks.map((assignedTask) => (
                  <TaskOverview
                    key={assignedTask._id}
                    task={assignedTask.name}
                    description={assignedTask.description}
                    time={formatDate(assignedTask.startTime)}
                    location={assignedTask.location}
                    scheduledTaskId={assignedTask._id}
                  />
                ))
              ) : (
                <p style={{ color: colors.fontcolor }}>
                  {t("There are no assigned tasks")}
                </p>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default StaffMemberScreen;
