// reducers/taskReducer.js

import {
    FETCH_TASKS_LIST_REQUEST,
    FETCH_TASKS_LIST_SUCCESS,
    FETCH_TASKS_LIST_FAIL,
    DELETE_TASK_REQUEST,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAIL,
    DELETE_TASK_RESET,

    FETCH_TASK_DETAILS_REQUEST,
    FETCH_TASK_DETAILS_SUCCESS,
    FETCH_TASK_DETAILS_FAIL,

    EDIT_TASK_REQUEST,
    EDIT_TASK_SUCCESS,
    EDIT_TASK_FAIL,

    CREATE_TASK_REQUEST,
    CREATE_TASK_SUCCESS,
    CREATE_TASK_FAIL,
    CREATE_TASK_RESET,
  } from '../../constants/staff/tasksListConstants.js'; // Update with the correct import path
  
  const initialState = {
    loading: false,
    tasks: [],
    error: null,
  };
  
 
  export const tasksListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TASKS_LIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TASKS_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          tasks: action.payload,
        };
      case FETCH_TASKS_LIST_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  const taskinitialState = {
    loading: false,
    error: null,
    taskDetails: null,
  };
  
  export const fetchTaskDetailsReducer = (state = taskinitialState, action) => {
    switch (action.type) {
      case FETCH_TASK_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TASK_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          taskDetails: action.payload,
        };
      case FETCH_TASK_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

 // Initial state
const deleteTaskInitialState = {
  deletedTask: null,
  loading: false,
  error: null,
  success: false,
};

// Reducer
export const deleteTaskReducer = (state = deleteTaskInitialState, action) => {
  switch (action.type) {
    case DELETE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deletedTask: action.payload,
      };
    case DELETE_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case DELETE_TASK_RESET:
      return { ...deleteTaskInitialState, success: false };
    default:
      return state;
  }
};

  
export const editTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_TASK_REQUEST:
      return { loading: true };
    case EDIT_TASK_SUCCESS:
      return { loading: false, success: true, task: action.payload };
    case EDIT_TASK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const createTaskInitialState = {
  loading: false,
  error: null,
  success: false,
  task: null,
};

// Reducer
export const createTaskReducer = (state = createTaskInitialState, action) => {
  switch (action.type) {
    case CREATE_TASK_REQUEST:
      return { ...state, loading: true, success: false, error: null };
    case CREATE_TASK_SUCCESS:
      return { ...state, loading: false, success: true, task: action.payload };
    case CREATE_TASK_FAIL:
      return { ...state, loading: false, error: action.payload, success: false };
    case CREATE_TASK_RESET:
      return createTaskInitialState;
    default:
      return state;
  }
};