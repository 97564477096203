import{
    SUBMIT_REQUEST,
    SUBMIT_SUCCESS,
    SUBMIT_FAIL,
} from "../constants/submitConstants";


const initialState = {
  loading: false,
  error: null,
  success: false,
  dailyStatus: {},
};
export const submitReducer = (state = initialState, action) =>{
    switch (action.type) {
        case SUBMIT_REQUEST:
          return { ...state, loading: true };
        case SUBMIT_SUCCESS:
          return { loading: false, success:true, dailyStatus: action.payload };
        case SUBMIT_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;

}
};