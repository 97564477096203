import React, { useEffect }  from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {getClubDetails} from "../../actions/clubDetailsActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
const ClubDetailsScreen = () => {
  const {t} = useTranslation();
  const { clubId } = useParams();
  const clubDetails = useSelector((state) => state.clubDetails);
  const { loading, error, club } = clubDetails;// Update this line to use _id
  const dispatch = useDispatch();
  console.log(clubDetails);

  useEffect(() => {
    // Ovde pozovite akciju za dohvatanje admina
    dispatch(getClubDetails(clubId));
  }, [dispatch, clubId]);
  return (
    <Container className="text-center pt-5">
      <h2>{t("Club Details")}</h2>
      {club ? (
        <div>
          <p>{t("Name")}: {club.name}</p>
          <p>{t("PIB")}: {club.pib}</p>
          <p>{t("Address")}: {club.address}</p>
          <p>{t("clubEmail")}: {club.email}</p>
          {/* Display other club details as needed */}
        </div>
      ) : (
        <p>{t("NoClub")}</p>
      )}
    </Container>
  );
};

export default ClubDetailsScreen;
