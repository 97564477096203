// components/taskoverview/TaskOverview.js
import React from "react";
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';

const TaskOverview = (props) => {
  const { task, description, time, location, scheduledTaskId, onTaskClick } = props;

  const commonStyle = {
    border: `1px solid #000`,
    // Dodajte cursor: pointer samo ako je onTaskClick definisan
    cursor: onTaskClick ? 'pointer' : 'auto',
  };

  if (onTaskClick) {
    return (
      <div className="square rounded border border-2 p-2 border-dark my-3 text-light border-white" style={commonStyle} onClick={() => onTaskClick(scheduledTaskId)}>
        <Row><h3>{task}</h3></Row>
        <h6>{description}</h6>
        <h5>{time}</h5>
        <h5>{location}</h5>
      </div>
    );
  } else {
    return (
      <Link to={`/calendar/scheduledtasks/${scheduledTaskId}`} className="text-decoration-none">
        <div className="square rounded border border-2 p-2 border-dark my-3 text-light border-white">
          <Row><h3>{task}</h3></Row>
          <h6>{description}</h6>
          <h5>{time}</h5>
          <h5>{location}</h5>
        </div>
      </Link>
    );
  }
};

export default TaskOverview;
