export const FETCH_TASKS_LIST_REQUEST = 'FETCH_TASKS_LIST_REQUEST';
export const FETCH_TASKS_LIST_SUCCESS = 'FETCH_TASKS_LIST_SUCCESS';
export const FETCH_TASKS_LIST_FAIL = 'FETCH_TASKS_LIST_FAIL';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASKS_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';
export const DELETE_TASK_RESET = 'DELETE_TASK_RESET';


// actions/staff/taskConstants.js

export const FETCH_TASK_DETAILS_REQUEST = "FETCH_TASK_DETAILS_REQUEST";
export const FETCH_TASK_DETAILS_SUCCESS = "FETCH_TASK_DETAILS_SUCCESS";
export const FETCH_TASK_DETAILS_FAIL = "FETCH_TASK_DETAILS_FAIL";

export const EDIT_TASK_REQUEST = "EDIT_TASK_REQUEST";
export const EDIT_TASK_SUCCESS = "EDIT_TASK_SUCCESS";
export const EDIT_TASK_FAIL = "EDIT_TASK_FAIL";

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAIL = "CREATE_TASK_FAIL";
export const CREATE_TASK_RESET = 'CREATE_TASK_RESET';




