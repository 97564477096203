import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlayers, deletePlayer } from "../../actions/staff/clubPlayerActions";
import DataTable from "../../components/table/DataTable";
import Button from "../../components/cofButton/Button";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./PlayersScreen.css";
import { useTranslation } from 'react-i18next';
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Container, Alert  } from 'react-bootstrap';


const PlayersScreen = () => {
  const{t}= useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const { loading, error, players, success: Success } = useSelector(
    (state) => state.players
  );
 console.log(players)
  const createPlayerFunction = useSelector((state) => state.createPlayer)
  const { loading: loadingUpdate,
          error: errorUpdate,
          success:successUpdate,
      } = createPlayerFunction


  const playerDelete = useSelector((state) => state.deletePlayer);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = playerDelete;

  useEffect(() => {
    dispatch(fetchPlayers(userInfo.club));
  }, [dispatch, userInfo.club, successDelete, successUpdate]);

  const handleDeletePlayer = async (playerId) => {
    try {
      dispatch(deletePlayer(userInfo.club, playerId));
    } catch (error) {
      console.error("Error deleting player:", error);
    }
  };

  

  const navigate = useNavigate();

  const createPlayerHandler = () => {
    navigate("/club/players/create");
  };

  const infoPlayerHandler = (playerId) => {
    navigate(`/player/${playerId}`);
  };

  const actionHandlers = {
    [t("Delete")]: handleDeletePlayer,
    [t("Info")]: infoPlayerHandler,
  };

  const mappedPlayers = players.map((player) => {
    let status = player.dailyStatus;
    return {
      ...player,
      status: status,
    };
  });

  const statusStyleGetter = (player) => {
    let statusClass = "";

    if (player.status === "good") {
      statusClass = "good-status";
    } else if (player.status === "not good") {
      statusClass = "not-good-status";
    } else if (player.status === "not filled yet") {
      statusClass = "not-filled-yet-status";
    } else if (player.status === "not filled") {
      statusClass = "not-filled-status";
    }

    return statusClass;
  };

  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }
 

  return (
    <div>
      <Row className="my-3">
        <Col>
          <h2>{t("PlayerList").toUpperCase()}</h2>
        </Col>
        <Col>
          <Button className="float-end rounded-0 border-0" onClick={createPlayerHandler}>
            <i className="fas fa-plus"></i> {t("CreateNewPlayer")}
          </Button>
        </Col>
      </Row>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataTable
          columns={[
            { label: t("Name").toUpperCase(), dataKey: "name" },
            { label: t("Surname").toUpperCase(), dataKey: "surname" },
            { label: t("Position").toUpperCase(), dataKey: "position" },
            { label: t("age").toUpperCase(), dataKey: "age" },
            { label: t("Status").toUpperCase(), dataKey: "status" },
          ]}
          data={mappedPlayers}
          actions={[
            { icon: faInfo,
             label: t("Info"),
             style: { backgroundColor: 'rgb(0, 198, 19)' },
             },
            { variant: "danger",icon: faTrash, label: t("Delete") },
          ]}
          actionLabels={[t("Info"), t("Delete")]}
          buttonStyle={{ marginRight: "5px", width: '40px' }}
          actionHandlers={actionHandlers}
          statusStyleGetter={statusStyleGetter}
        />
      )}
    </div>
  );
};

export default PlayersScreen;
