// actions/taskActions.js

import axios from 'axios';
import {
  FETCH_TASKS_LIST_REQUEST,
  FETCH_TASKS_LIST_SUCCESS,
  FETCH_TASKS_LIST_FAIL,

  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAIL,

  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,

  FETCH_TASK_DETAILS_REQUEST,
  FETCH_TASK_DETAILS_SUCCESS,
  FETCH_TASK_DETAILS_FAIL,
  

  EDIT_TASK_REQUEST,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAIL,
} from '../../constants/staff/tasksListConstants.js'; // Update with correct import path

// Fetch tasks list action
export const fetchTasksList = (clubId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_TASKS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/staff/clubs/${clubId}/tasks`, config);

    dispatch({
      type: FETCH_TASKS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_TASKS_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const createTask = (clubId, taskData) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    dispatch({ type: CREATE_TASK_REQUEST });

    const { data } = await axios.post(`/api/staff/clubs/${clubId}/tasks`, taskData, config);

    dispatch({
      type: CREATE_TASK_SUCCESS,
      payload: data,
    });

    // After successful creation, fetch updated task list
    dispatch(fetchTasksList(clubId));
  } catch (error) {
    dispatch({
      type: CREATE_TASK_FAIL,
      payload: error.response?.data.message || error.message,
    });
  }
};


// Delete task action
export const deleteTask = (clubId, taskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_TASK_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/staff/clubs/${clubId}/tasks/${taskId}`, config);

    dispatch({ type: DELETE_TASK_SUCCESS, payload: taskId });
  } catch (error) {
    dispatch({
      type: DELETE_TASK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTaskDetails = (clubId, taskId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_TASK_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/staff/clubs/${clubId}/tasks/${taskId}`, config);

    dispatch({
      type: FETCH_TASK_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    
    dispatch({
      type: FETCH_TASK_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

export const editTask = (clubId, taskId, taskData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_TASK_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/staff/clubs/${clubId}/tasks/${taskId}`, taskData, config);

    dispatch({
      type: EDIT_TASK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDIT_TASK_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
