import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Alert } from 'react-bootstrap';
import { editPlayersGroup, fetchPlayersGroupDetails } from '../../actions/staff/playersGroupsActions';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomInput from "../../components/customInput/CustomInput"
import Button from '../../components/cofButton/Button';
const EditPlayersGroupsScreen = () => {
  const { t } = useTranslation();
  const { playersGroupId } = useParams();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const playersGroupDetails = useSelector((state) => state.playersGroupDetails);
  const { loading, error, group } = playersGroupDetails;

  const [name, setName] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    dispatch(fetchPlayersGroupDetails(userInfo.club, playersGroupId));
  }, [dispatch, userInfo.club, playersGroupId]);

  useEffect(() => {
    if (group) {
      setName(group.name);
    }
  }, [group]);

  const submitHandler = async (e) => {
    e.preventDefault();
    
    try {
      // Dispatch akcije za uređivanje grupe
      await dispatch(editPlayersGroup(userInfo.club, playersGroupId, { name }));
      setSuccessMessage('Uspešno ste izmenili naziv grupe igrača.');
    } catch (error) {
      // Ukoliko dođe do greške prilikom uređivanja, možete je tretirati ovde
      console.error('Greška prilikom uređivanja grupe igrača:', error);
    }
  };

  return (
    <div>
      <h2>{t("Edit Player Group").toUpperCase()}</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>{t("GroupName")}</Form.Label>
            <CustomInput
              type="text"
              placeholder="Unesite ime grupe"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Button type="submit" variant="primary" className='me-2 my-2 rounded-0 border-0'>
          {t("Update")}
          </Button>
          <Link to={`/staff/clubs/${userInfo.club}/playersgroups`}>
            <Button variant="secondary" className='my-2 rounded-0 border-0'>{t("Go Back")}</Button>
          </Link>
        </Form>
      )}
    </div>
  );
};

export default EditPlayersGroupsScreen;
