import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import DataTable from '../../components/table/DataTable';
import { fetchPlayersGroups, deletePlayersGroup } from '../../actions/staff/playersGroupsActions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from "../../components/cofButton/Button";
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Container, Alert  } from 'react-bootstrap';
const PlayersGroupsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { loading, playersGroups, error } = useSelector(
    (state) => state.playersGroups
  );
  const playersGroupDelete= useSelector((state) => state.deletePlayersGroup);
  const { success: successDelete } = playersGroupDelete;

    console.log(playersGroups)
  useEffect(() => {
    dispatch(fetchPlayersGroups(userInfo.club));
  }, [dispatch, userInfo.club, successDelete]);

  const navigate = useNavigate();

  const columns = [
    { label: t("GroupName").toUpperCase(), dataKey: 'name' },
    { label: t("NumberPlayers").toUpperCase(), dataKey: 'members' },
    { label: t("CreatedBy").toUpperCase(), dataKey: 'createdBy' },
  ];

  const allowedActions = [
    {  icon: faInfo,style: { backgroundColor: 'rgb(0, 198, 19)' }, label: t("Info") },
    { variant: "info", icon: faEdit, label: t("Edit") },
    { variant: "danger", icon: faTrash, label: t("Delete") },
  ];

  const mappedPlayersGroups = playersGroups
    ? playersGroups.map((group) => ({
        name: group.name,
        members: group.members.length,
        createdBy: group.createdBy.name,
        _id: group._id,
      }))
    : [];

    console.log(userInfo.club)

  const infoPlayersGroupsHandler = (playersGroupId) => {
    navigate(`/staff/clubs/${userInfo.club}/playersgroups/${playersGroupId}`); // Zamijenite sa odgovarajućim URL-om
  };
  
  const deletePlayersGroupHandler = (playersGroupId) => {
    console.log('Delete button clicked');
    dispatch(deletePlayersGroup(userInfo.club, playersGroupId));
  };
  
  const editTaskHandler = (playersGroupId) => {
    navigate(`/staff/clubs/${userInfo.club}/playersgroups/${playersGroupId}/edit`);
  };  
  const actionHandlers = {
    [t("Info")]: infoPlayersGroupsHandler,
    [t("Delete")]: deletePlayersGroupHandler,
    [t("Edit")]: editTaskHandler
  };
  const createNewGroupHandler = () =>{
    navigate(`/staff/clubs/${userInfo.club}/playersgroups/newgroup`);
  };


  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }
  return (
    <div>
      <Row className="my-3">
        <Col>
          <h2>{t("PlayerGroups").toUpperCase()}</h2>
        </Col>
        <Col>
        <Button className="float-end rounded-0 border-0" onClick={createNewGroupHandler}>
            <i className="fas fa-plus"></i> {t("CreateNewGroup")}
          </Button>
        </Col>
      </Row>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataTable
          columns={columns}
          data={mappedPlayersGroups}
          actions={allowedActions}
          actionLabels={[t("Info"),t("Edit"),t("Delete")]}
          buttonStyle={{ marginRight: '5px', width:'40px' }}
          actionHandlers={actionHandlers}
          onDelete={deletePlayersGroupHandler}
        />
      )}
    </div>
  );
};

export default PlayersGroupsScreen;
