import {
    NOTIFICATION_SUBSCRIBE_REQUEST,
    NOTIFICATION_SUBSCRIBE_SUCCESS,
    NOTIFICATION_SUBSCRIBE_FAIL,
    NOTIFICATION_UNSUBSCRIBE_REQUEST,
    NOTIFICATION_UNSUBSCRIBE_SUCCESS,
    NOTIFICATION_UNSUBSCRIBE_FAIL,
  } from '../constants/pushNotificationsConstants';
  
  export const notificationSubscribeReducer = (state = { loading: false, success: false, error: null }, action) => {
    switch (action.type) {
      case NOTIFICATION_SUBSCRIBE_REQUEST:
        return { ...state, loading: true };
      case NOTIFICATION_SUBSCRIBE_SUCCESS:
        return { ...state, loading: false, success: true };
      case NOTIFICATION_SUBSCRIBE_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const notificationUnsubscribeReducer = (state = { loading: false, success: false, error: null }, action) => {
    switch (action.type) {
      case NOTIFICATION_UNSUBSCRIBE_REQUEST:
        return { ...state, loading: true };
      case NOTIFICATION_UNSUBSCRIBE_SUCCESS:
        return { ...state, loading: false, success: true };
      case NOTIFICATION_UNSUBSCRIBE_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };