import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScheduledTaskInfo, editScheduledTask } from '../actions/staff/scheduledActions';
import { useTranslation } from 'react-i18next';
import Button from "../components/cofButton/Button";

const EditScheduledTaskModal = ({ show, onHide, scheduledtaskId, tasks }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [task, setTask] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [addDeadline, setAddDeadline] = useState(false);
  const [addAttendanceConfirmation, setAddAttendanceConfirmation] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState('');
  const [attendanceConfirmationDate, setAttendanceConfirmationDate] = useState('');
  const [taskType, setTaskType] = useState('');
  const [taskLocation, setTaskLocation] = useState('');
  const [taskDuration, setTaskDuration] = useState('');
  const [errors, setErrors] = useState({});

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const taskInfo = useSelector((state) => state.scheduledTaskInfo);
  const { scheduledTask } = taskInfo;

  const scheduledtaskEdit = useSelector((state) => state.editScheduledTask);
  const { loading: editLoading, error: editError, success } = scheduledtaskEdit;
 
  useEffect(() => {
    if (show && scheduledtaskId) {
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduledtaskId));
    }
  }, [dispatch, userInfo.club, show, scheduledtaskId]);

  useEffect(() => {
    if (scheduledTask) {
      setDescription(scheduledTask.description);
      setSelectedTaskId(scheduledTask._id);
      setDateTime(new Date(scheduledTask.startTime).toISOString().slice(0, -1));
      setTask(scheduledTask.name);
      setAddDeadline(scheduledTask.deadline ? new Date(scheduledTask.deadline).toISOString().slice(0, -1) : '');
      setAddAttendanceConfirmation(scheduledTask.attendanceConfirmationUntil ? new Date(scheduledTask.attendanceConfirmationUntil).toISOString().slice(0, -1) : '');
      setTaskType(scheduledTask.type);
      setTaskLocation(scheduledTask.location);
      setTaskDuration(scheduledTask.duration);
    }
  }, [scheduledTask]);

  const getMinDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, -1);
  };

  const validateTimes = () => {
    const now = new Date();
    const errors = {};

    if (new Date(dateTime) < now) {
      errors.dateTime = 'Start time cannot be in the past';
    }

    if (addDeadline && new Date(deadlineDate) < new Date(dateTime)) {
      errors.deadlineDate = 'Deadline cannot be before start time';
    }

    if (addAttendanceConfirmation && new Date(attendanceConfirmationDate) > new Date(dateTime)) {
      errors.attendanceConfirmationDate = 'Confirmation until cannot be after start time';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!validateTimes()) {
      return;
    }

    const taskData = {
      description: description,
      taskId: selectedTaskId,
      name: task,
      type: taskType,
      location: taskLocation,
      duration: taskDuration,
      startTime: new Date(dateTime),
      deadline: addDeadline ? new Date(deadlineDate) : null,
      attendanceConfirmationUntil: addAttendanceConfirmation ? new Date(attendanceConfirmationDate) : null,
    };

    try {
      dispatch(editScheduledTask(userInfo.club, scheduledtaskId, taskData));
      onHide();
    } catch (error) {
      console.error('Error updating scheduled task:', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} style={{color:"white"}}>
      <Modal.Header closeButton style={{backgroundColor:"#1d4e5b"}}>
        <Modal.Title>{t("EditScheduledTask")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:"#1d4e5b"}}>
        <Form>
          <Form.Group controlId="description">
            <Form.Label>{t("Description")}</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
          <Form.Group controlId="taskName">
            <Form.Label>{t("TaskName")}</Form.Label>
            <Form.Control
              type="text"
              value={task}
              onChange={(e) => setTask(e.target.value)}
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
          <Form.Group controlId="dateTime">
            <Form.Label>{t("DateAndTime")}</Form.Label>
            <Form.Control
              type="datetime-local"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              min={getMinDateTime()}
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
            {errors.dateTime && <p className="text-danger mt-1">{errors.dateTime}</p>}
          </Form.Group>
          <Form.Group controlId="addDeadline">
            <Form.Check
              type="checkbox"
              label={t("AddDeadline")}
              onChange={(e) => setAddDeadline(e.target.checked)}
            />
          </Form.Group>
          {addDeadline && (
            <Form.Group controlId="deadlineDate">
              <Form.Label>{t("DeadlineDate")}</Form.Label>
              <Form.Control
                type="datetime-local"
                value={deadlineDate}
                onChange={(e) => setDeadlineDate(e.target.value)}
                min={dateTime}
                style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
              />
              {errors.deadlineDate && <p className="text-danger mt-1">{errors.deadlineDate}</p>}
            </Form.Group>
          )}
          <Form.Group controlId="addAttendanceConfirmation">
            <Form.Check
              type="checkbox"
              label={t("AddAttendanceConfirmation")}
              onChange={(e) => setAddAttendanceConfirmation(e.target.checked)}
            />
          </Form.Group>
          {addAttendanceConfirmation && (
            <Form.Group controlId="attendanceConfirmationDate">
              <Form.Label>{t("AttendanceConfirmationDate")}</Form.Label>
              <Form.Control
                type="datetime-local"
                value={attendanceConfirmationDate}
                onChange={(e) => setAttendanceConfirmationDate(e.target.value)}
                max={dateTime}
                style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
              />
              {errors.attendanceConfirmationDate && <p className="text-danger mt-1">{errors.attendanceConfirmationDate}</p>}
            </Form.Group>
          )}
          <Form.Group controlId="taskType">
            <Form.Label>{t("TaskType")}</Form.Label>
            <Form.Control
              as="select"
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            >
              <option value="">{t("SelectType")}</option>
              <option value="TRAINING">{t("TRAINING")}</option>
              <option value="MATCHDAY">{t("MATCHDAY")}</option>
              <option value="DINNER">{t("DINNER")}</option>
              <option value="QUARANTINE">{t("QUARANTINE")}</option>
              <option value="TEAMBUILDING">{t("TEAMBUILDING")}</option>
              <option value="FITNESS_TRAINING">{t("FITNESS_TRAINING")}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="taskLocation">
            <Form.Label>{t("TaskLocation")}</Form.Label>
            <Form.Control
              type="text"
              value={taskLocation}
              onChange={(e) => setTaskLocation(e.target.value)}
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
          <Form.Group controlId="taskDuration">
            <Form.Label>{t("TaskDuration")}</Form.Label>
            <Form.Control
              type="text"
              value={taskDuration}
              onChange={(e) => setTaskDuration(e.target.value)}
              style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{backgroundColor:"#1d4e5b"}}>
        <Button variant="secondary" onClick={onHide} className='rounded-0 border-0'>{t("Cancel")}</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={editLoading} className='rounded-0 border-0'>
          {editLoading ? 'Updating...' : t("Update")}
        </Button>
        {editError && <p className="text-danger mt-3">{editError}</p>}
      </Modal.Footer>
    </Modal>
  );
};

export default EditScheduledTaskModal;
