import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Alert} from "react-bootstrap";
import Button from "../../components/cofButton/Button";
import { useDispatch, useSelector } from "react-redux";
import { createPlayer} from "../../actions/createPlayerActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { RESET_CREATE_PLAYER_STATE } from '../../constants/staff/clubPlayerConstants'
import CustomInput from "../../components/customInput/CustomInput"

const CreatePlayerScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [playerName, setPlayerName] = useState("");
    const [playerLastName, setPlayerLastName] = useState("");
    const [playerPhone, setPlayerPhone] = useState("");
    const [playerEmail, setPlayerEmail] = useState("");
    const [playerPassword, setPlayerPassword] = useState("");
    const [playerId, setPlayerId] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [useExistingPlayer, setUseExistingPlayer] = useState(false);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    console.log(userInfo);

    const createPlayerFunction = useSelector((state) => state.createPlayer)
    const { loading: loadingUpdate,
            error: errorUpdate,
            success:successUpdate,
        } = createPlayerFunction
     
    useEffect(() => {
    if(successUpdate){
        navigate('/club/players');
        dispatch({ type: RESET_CREATE_PLAYER_STATE })
    }
    if(errorUpdate)
    { setErrorAlert(true);}
    }, [dispatch, navigate,successUpdate,errorUpdate])



    const [playerInputMode, setPlayerInputMode] = useState("data");
    const handlePlayerInputModeChange = (mode) => {
        setPlayerInputMode(mode);
      };

  const handlePlayerCreate  = (e) => {
    e.preventDefault();
    let playerInfo = null;
    if (useExistingPlayer) {
        playerInfo = { userid: playerId };
        dispatch(createPlayer(userInfo.club,playerInfo));
      }
     else {
        const playerData = {
            name: playerName,
            surname: playerLastName,
            phonenumber: playerPhone,
            email: playerEmail,
            password: playerPassword,
          };
           dispatch(createPlayer(userInfo.club,playerData));
    }
     };
   
   

    return(

        <div>
            <Container
                className="d-flex align-items-center justify-content-center"
                style={{ paddingTop: "100px" }}
            >
                <Row>
                    <Col
                        xs={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <h2>{t("Create new player").toUpperCase()}</h2>
                    </Col>

                    <Col xs={12}> 
                    <Form>
                    <Form.Group controlId="existingPlayerCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    label={t("Use existing player (provide ID)")}
                                    checked={useExistingPlayer}
                                    onChange={() => setUseExistingPlayer(!useExistingPlayer)}
                                />
                            </Form.Group>

                            { useExistingPlayer ? (
                               <Form.Group style={{ marginBottom: "10px" }}>
                               <CustomInput
                                   type="text"
                                   placeholder={t("Player ID")}
                                   value={playerId}
                                   onChange={(e) => setPlayerId(e.target.value)}
                               />
                           </Form.Group>
                            ) : (
                               <div>
                               <Form.Group style={{ marginBottom: "10px" }}>
                                <CustomInput
                                    type="text"
                                    placeholder={t("Name")}
                                    value={playerName}
                                    onChange={(e) => setPlayerName(e.target.value)}
                                    
                                />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: "10px" }}>
                                <CustomInput
                                    type="text"
                                    placeholder={t("Surname")}
                                    value={playerLastName}
                                    onChange={(e) => setPlayerLastName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: "10px" }}>
                                <CustomInput
                                    type="text"
                                    placeholder={t("PhoneNumber")}
                                    value={playerPhone}
                                    onChange={(e) => setPlayerPhone(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: "10px" }}>
                                <CustomInput
                                    type="text"
                                    placeholder={t("Email")}
                                    value={playerEmail}
                                    onChange={(e) => setPlayerEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: "10px" }}>
                                <CustomInput
                                    type="password"
                                    placeholder={t("Password")}
                                    value={playerPassword}
                                    onChange={(e) => setPlayerPassword(e.target.value)}
                                />
                            </Form.Group>
                               </div>

                            ) }
                            
                            <Button
                                className="w-100 rounded-0 border-0"
                                transparent={false}
                                onClick={handlePlayerCreate}
                            >
                                {t("CreatePlayer")}
                            </Button>
                            <Col xs={12}>
                        {errorAlert && (
                            <Alert variant="danger" onClose={() => setErrorAlert(false)} dismissible>
                                {errorUpdate}
                            </Alert>
                        )}
                    </Col>
                    </Form>
                    </Col>

                </Row>
            </Container>

        </div>
    )



}

export default CreatePlayerScreen;