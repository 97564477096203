import axios from "axios";
import {
  USER_NOTIFICATION_REQUEST,
  USER_NOTIFICATION_SUCCESS,
  USER_NOTIFICATION_FAIL
} from "../constants/notificationConstants"

export const getUserNotifications = (userId) => async (dispatch, getState) =>{
   try {
    dispatch({
        type: USER_NOTIFICATION_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
  
      const { data } = await axios.get(
      `/api/users/${userId}/notifications`,
        config
      );
  
      dispatch({
        type: USER_NOTIFICATION_SUCCESS,
        payload: data,
      });

   } catch (error) {
    dispatch({
      type: USER_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }

}