import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const TextBox = ({ label, text, showTextBox, onInputChange }) => {
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {
          m: 1,
          width: '100%',
          border: '2px solid rgb(213, 250, 215)',
          marginLeft: '-14px',
          marginRight: '2px',
        },
        display: showTextBox ? 'block' : 'none', // Show the TextBox only when showTextBox is true
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-multiline-flexible"
          label={label}
          multiline
          rows={6} // Set the initial number of visible text rows (you can adjust this as needed)
          value={text}
          onChange={onInputChange}
          inputProps={{
            style: {
              width: '100%',
            },
          }}
        />
      </div>
    </Box>
  );
};

TextBox.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showTextBox: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default TextBox;













// const TextBox = ({ text, buttonText, onButtonClick }) => {
//   return (
//     <div className="textBox">
//       <p>text</p>
//       {buttonText && (
//         <button onClick={onButtonClick}>
//           {buttonText}
//         </button>
//       )}
//     </div>
//   );
// };

// TextBox.propTypes = {
//   text: PropTypes.string.isRequired,
//   buttonText: PropTypes.string,
//   onButtonClick: PropTypes.func,
// };

// export default TextBox;
