import {
    GET_CLUBS_REQUEST,
    GET_CLUBS_SUCCESS ,
    GET_CLUBS_FAIL,
    CREATE_CLUB_REQUEST,
    CREATE_CLUB_SUCCESS,
    CREATE_CLUB_FAIL,
    RESET_CREATE_CLUB_STATE,
    DELETE_CLUB_REQUEST,
    DELETE_CLUB_SUCCESS,
    DELETE_CLUB_FAIL,
    EDIT_CLUB_FAIL,
    EDIT_CLUB_REQUEST,
    EDIT_CLUB_SUCCESS,
    EDIT_CLUB_RESET,
    GET_CLUB_FAIL,
    GET_CLUB_REQUEST,
    GET_CLUB_SUCCESS,
    GET_CLUB_RESET,
    GET_CLUBS_RESET,
    DELETE_CLUB_RESET
} from "../constants/clubConstants"

const initialState = {
  clubs: null,
  loading: false,
  error: null,
 
};

export const getClubsReducer = (state = initialState, action) =>{
    switch (action.type) {
        case GET_CLUBS_REQUEST:
          return { ...state, loading: true };
        case GET_CLUBS_SUCCESS:
          return { loading: false,success: true, clubs: action.payload };
        case GET_CLUBS_FAIL:
          return { loading: false, error: action.payload };
          case GET_CLUBS_RESET:
          return {initialState};
        default:
          return state;
      }
};


const createClubInitialState = {
  loading: false,
  error: null,
  club: {},
  success: false,
};

export const clubCreateReducer = (state = createClubInitialState, action) => {
  switch (action.type) {
    case CREATE_CLUB_REQUEST:
      return { ...state, loading: true };
    case CREATE_CLUB_SUCCESS:
      return { ...state, loading: false, success: true, club: action.payload };
    case CREATE_CLUB_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_CREATE_CLUB_STATE:
      return createClubInitialState; // Reset to initial state
    default:
      return state;
  }
};

const deleteClubInitialState = {
  clubs: null,
  loading: false,
  error: null,
 
};
export const deleteClubReducer = (state = deleteClubInitialState, action) => {
  switch (action.type) {
    case DELETE_CLUB_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_CLUB_SUCCESS:
      return { ...state, loading: false,success: true, clubs: action.payload };
    case DELETE_CLUB_FAIL:
      return { ...state, loading: false, error: action.payload };
      case DELETE_CLUB_RESET:
        return{deleteClubInitialState};
    default:
      return state;
  }
};

const editClubInitialState = {
  loading: false,
  error: null,
  club: {},
  success: false,
};

export const editClubReducer = (state = editClubInitialState, action) => {
  switch (action.type) {
    case EDIT_CLUB_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_CLUB_SUCCESS:
      return { ...state, loading: false,success: true, clubs: action.payload };
    case EDIT_CLUB_FAIL:
      return { ...state, loading: false, error: action.payload }
      case EDIT_CLUB_RESET:
        return{editClubInitialState};
    default:
      return state;
  }
};


const getClubinitialState = {
  clubs: null,
  loading: false,
  error: null,
 
};
export const getClubReducer = (state = getClubinitialState, action) =>{
  switch (action.type) {
      case GET_CLUB_REQUEST:
        return { ...state, loading: true };
      case GET_CLUB_SUCCESS:
        return { loading: false,success: true, clubs: action.payload };
      case GET_CLUB_FAIL:
        return { loading: false, error: action.payload };
        case GET_CLUB_RESET:
          return getClubinitialState;
          default:
        return state;
    }
};