import { FETCH_SCHEDULED_TASK_COMMENT_FAIL,
    FETCH_SCHEDULED_TASK_COMMENT_SUCCESS,
    FETCH_SCHEDULED_TASK_COMMENT_REQUEST,
    FETCH_SCHEDULED_TASK_COMMENT_RESET,
    CREATE_SCHEDULED_TASK_COMMENT_REQUEST,
    CREATE_SCHEDULED_TASK_COMMENT_SUCCESS,
    CREATE_SCHEDULED_TASK_COMMENT_FAIL,
    CREATE_SCHEDULED_TASK_COMMENT_RESET
} from "../../constants/staff/scheduledTaskCommentsConstants";

const initialState = {
    comment: {}, // Inicijalno postavite na null ili prazan objekat
    loading: false,
    error: null,
  };

  export const scheduledTaskCommentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SCHEDULED_TASK_COMMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SCHEDULED_TASK_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          comments: action.payload,
        };
      case FETCH_SCHEDULED_TASK_COMMENT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case FETCH_SCHEDULED_TASK_COMMENT_RESET:
          return initialState;
      default:
        return state;
    }
  };
  const createinitialState = {
    loading: false,
    error: null,
    comment: {},
    success: false,
  };
  export const createScheduledTaskCommentReducer = (state = createinitialState, action) => {
    console.log(state)
    switch (action.type) {
      case CREATE_SCHEDULED_TASK_COMMENT_REQUEST:
        return { ...state, loading: true };
      case CREATE_SCHEDULED_TASK_COMMENT_SUCCESS:
        return { ...state, loading: false, success: true, comment: action.payload };
      case CREATE_SCHEDULED_TASK_COMMENT_FAIL:
        return { ...state, loading: false, error: action.payload };
        case CREATE_SCHEDULED_TASK_COMMENT_RESET:
          return createinitialState; // Reset to initial state
      default:
        return state;
    }
  };
