import axios from "axios";
import { EDIT_PASSPORT_INFO_FAIL,
    EDIT_PASSPORT_INFO_REQUEST,
    EDIT_PASSPORT_INFO_SUCCESS

 } from "../constants/passportInfoConstants";
 
 export const editPassportInfo = (userid, passportInfoData) => async (dispatch, getState) =>{
  

  console.log(passportInfoData)

    try {
     dispatch({
         type: EDIT_PASSPORT_INFO_REQUEST,
     });
     const {
       userLogin: { userInfo },
     } = getState();
 
     const config = {
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${userInfo.token}`,
       },
     };
   
       const { data } = await axios.put(
       `/api/users/${userid}/passport`,
       passportInfoData,
       
         config
       );
      console.log(data)
       dispatch({
         type: EDIT_PASSPORT_INFO_SUCCESS,
         payload: data,
       });
 
    } catch (error) {
     dispatch({
       type: EDIT_PASSPORT_INFO_FAIL,
       payload:
         error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
     });
   }
 
 }