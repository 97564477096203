import { React, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Button from "../cofButton/Button";
import { fetchPlayerInfo, editPlayerInfo } from "../../actions/player/playerActions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import CustomInput from "../../components/customInput/CustomInput"
const PassportInfo = ({ passportData, updatePassportInfo }) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [passportNumber, setPassportNumber] = useState('');
  const [passportExpirationDate, setPassportExpirationDate] = useState('');
  const [nationalityValue, setNationalityValue] = useState('');

  useEffect(() => {
    if (passportData) {
      setPassportNumber(passportData.passportnum || '');
      setPassportExpirationDate(passportData.passportExpirationDate || '');
      setNationalityValue(passportData.nationality || '');
      setSelectedImage(passportData.passportPicture || null)
    }
  }, [passportData]);

  const handleUpdateInfo = () => {
    const updatedInfo = {
      passportnum: passportNumber,
      passportExpirationDate: passportExpirationDate,
      nationality: nationalityValue,
      passportPicture: selectedImage
    };
    console.log(updatedInfo)
    updatePassportInfo(updatedInfo);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setSelectedImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>

      <div className="text-center py-5">
        {selectedImage && (
          <img
            src={`${selectedImage}`}
            alt="Selected"
            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
          />
        )}
        <Col xs={10} md={8} className=" mx-auto py-3">
          <Form.Control
            id="image-file"
            type="file"
            label={t("Choose File")}
            custom
            onChange={uploadFileHandler}
            style={{ backgroundColor: "#415f74", border: "none", borderRadius: "0", color: "white" }}
          ></Form.Control>
           <h4 style={{paddingTop:"10px"}}>  {t('numberOfPassport')}</h4>
           <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                type="text"
                placeholder={t("PassportNum")}
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </Form.Group>
            <h4>  {t('Date of validity')}</h4>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                type="text"
                placeholder={t("ExpirationDate")}
                value={passportExpirationDate}
                onChange={(e) => setPassportExpirationDate(e.target.value)}
              />
            </Form.Group>
            <h4> {t('Nationality')}</h4>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                type="text"
                placeholder={t('Nationality')}
                value={nationalityValue}
                onChange={(e) => setNationalityValue(e.target.value)}
              />
            </Form.Group>
            <Button
          className="w-100 rounded-0 border-0"
          transparent={false}
          onClick={handleUpdateInfo}
        >
          {t("UpdateInfo")}
        </Button>
        </Col>

      
       
       
        <h3 className="py-1"> {t('Passport information')}</h3>
      </div>
    </div>
  )
}
export default PassportInfo;