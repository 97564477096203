import axios from "axios";

import {
    GET_CLUBS_DETAILS_REQUEST,
    GET_CLUBS_DETAILS_SUCCESS ,
    GET_CLUBS_DETAILS_FAIL,
} from "../constants/clubDetailsConstants"

export const getClubDetails = (clubId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_CLUBS_DETAILS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/admin/clubs/${clubId}`, config);
  
      dispatch({ type: GET_CLUBS_DETAILS_SUCCESS , payload: data });
    } catch (error) {
      dispatch({ type:GET_CLUBS_DETAILS_FAIL, payload: error.message });
    }
  };