// MenuItem.js
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../../theme/theme";

const MenuItem = ({ to, icon, title, onItemClick }) => {
  const handleItemClick = () => {
    if (onItemClick) {
      onItemClick();
    }
  };

  return (
    <Link
      to={to}
      className="menu-item"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "10px",
        textDecoration: "none",
        
      }}
      onClick={handleItemClick} // Dodajte onClick event koji će pozvati handleItemClick
    >
      <div
        className="menu-icon"
        style={{
          width: "65px",
          height: "65px",
          borderRadius: "50%",
          backgroundColor: "rgba(0,0,0,0)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          color: theme.colors.fontcolor,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: "35px", color: theme.colors.primaryColor }}
        />
      </div>
      <div
        className="menu-title"
        style={{
          fontSize: "14px",
          color: "white",
        }}
      >
        {title}
      </div>
    </Link>
  );
};

export default MenuItem;
