const getSetting = (key) => {
    const value = process.env[key];
    if (!value) {
        throw new Error(`settings, there is no value for key: ${key}`);
    }
    return value;
}
const settings = {
    apiBaseUrl: getSetting('REACT_APP_API_BASE_URL')
};
export default settings;