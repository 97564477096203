import { 
   FETCH_STAFF_MEMBER_INFO_FAIL,
   FETCH_STAFF_MEMBER_INFO_REQUEST,
   FETCH_STAFF_MEMBER_INFO_SUCCESS,
   EDIT_STAFF_MEMBER_INFO_FAIL,
   EDIT_STAFF_MEMBER_INFO_REQUEST,
   EDIT_STAFF_MEMBER_INFO_SUCCESS,
   EDIT_STAFF_MEMBER_INFO_RESET

} from '../../constants/staff/info/staffInfoConstants'

const editInitialState = {
    loading: false,
    error: null,
    success: false,
  };

  export const editStaffMemberInfoReducer = (state = editInitialState, action) => {
    switch (action.type) {
      case EDIT_STAFF_MEMBER_INFO_REQUEST:
        return { loading: true };
      case EDIT_STAFF_MEMBER_INFO_SUCCESS:
        return {  ...state, loading: false, success: true, trainer: action.payload };
      case EDIT_STAFF_MEMBER_INFO_FAIL:
        return { loading: false, error: action.payload };
        case EDIT_STAFF_MEMBER_INFO_RESET: 
        return {editInitialState};
      default:
        return state;
    }
  };
  const initialState = {
    loading: false,
    error: null,
  };
  
  export const fetchStaffMemberInfoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_STAFF_MEMBER_INFO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_STAFF_MEMBER_INFO_SUCCESS:
        return {
          ...state,
          loading: false,
          trainers: action.payload,
        };
      case FETCH_STAFF_MEMBER_INFO_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };