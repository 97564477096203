import React from 'react';
import { Container, Alert  } from 'react-bootstrap';
import DataTable from '../../components/table/DataTable'; // Podesite putanju prema DataTable komponenti
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import "../../screens/staff/PlayersScreen.css"

const StaffHomeScreen = ({ userInfo, context }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  console.log(userInfo);
  console.log(context);
  // Proverite da li context postoji i da li sadrži players
  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }
 
  if (!context || !context.players) {
    return (
      <Container>
        <p>{t("Loading...")}.</p>
      </Container>
    );
  }
  
  const infoPlayerHandler = (playerId) => {
    navigate(`/player/${playerId}`);
  };
  const todaytaskHandler = (scheduledTasksForTodayId) => {
    navigate(`/club/${userInfo.club}/scheduledtask/${scheduledTasksForTodayId}`);
  };
  const forweektaskHandler = (scheduledTasksForThisWeekId) => {
    navigate(`/club/${userInfo.club}/scheduledtask/${scheduledTasksForThisWeekId}`);
  };
  const { players, scheduledTasksForThisWeek, scheduledTasksForToday } = context;
  console.log(players)
  console.log(scheduledTasksForToday)
  console.log(context)
  console.log(context)
  console.log(scheduledTasksForThisWeek)
  console.log(scheduledTasksForThisWeek)

  const formattedData = scheduledTasksForThisWeek.map((task) => ({
    pocetak: new Date(task.startTime).toLocaleString(),
    description: task.description,
    imeZadatka: task.name,
    lokacija: task.location,
    _id:task._id
  }));
  const forTodayformattedData = scheduledTasksForToday.map((task) => ({
    pocetak: new Date(task.startTime).toLocaleString(),
    description: task.description,
    imeZadatka: task.name,
    lokacija: task.location,
    _id:task._id
  }));
  // Definišite kolone za DataTable igrača
  const playerColumns = [
    { label: t("Name").toUpperCase(), dataKey: 'name' },
    { label: t("Surname").toUpperCase(), dataKey: 'surname' },
    { label:t("Position").toUpperCase(), dataKey: 'position' },
    { label: t("age").toUpperCase(), dataKey: 'age' },
    { label: t("Status").toUpperCase(), dataKey: 'status' },
  ];

const actionHandlers = {
  [t("Info")]: infoPlayerHandler, // Dodajte ovu liniju

  
};

const todayactionHandlers = {
  [t("Info")]: todaytaskHandler};

const weekactionHandlers = {
  [t("Info")]: forweektaskHandler};

const todayActions = [
  {
    icon: faInfo,
    style: { backgroundColor: "rgb(0, 198, 19)" },
    label: t("Info"),
  }
];
const weekActions = [
  {
    icon: faInfo,
    style: { backgroundColor: "rgb(0, 198, 19)" },
    label: t("Info"),
  }
];

const buttonStyle = {
  width: '40px', // Postavite željenu širinu dugmadi
  backgroundColor: 'rgb(0,198,19)', 
};
const mappedPlayers = players.map((player) => {
  let status = player.dailyStatus;
  return {
    ...player,
    status: status,
  };
});

const statusStyleGetter = (player) => {
  let statusClass = "";

  if (player.status === "good") {
    statusClass = "good-status";
  } else if (player.status === "not good") {
    statusClass = "not-good-status";
  } else if (player.status === "not filled yet") {
    statusClass = "not-filled-yet-status";
  } else if (player.status === "not filled") {
    statusClass = "not-filled-status";
  }

  return statusClass;
};
  return (

    
    <Container>
      {/* Prikazivanje DataTable komponente sa podacima za igrače */}
      <h3>{t("PlayerList").toUpperCase()}</h3>
          <DataTable
          columns={playerColumns}
          data={mappedPlayers}
          actions={[
            { icon: faInfo, label: t("Info") }, // Dodajte ovu liniju za akciju "Info" za igrače
          ]}
          actionLabels={[t("Info")]} // Dodajte ovu liniju za oznaku akcije "Info"
          buttonStyle={buttonStyle} // Prazan objekat za stil dugmadi
          actionHandlers={actionHandlers} // Prazan objekat za rukovanje akcijama
          statusStyleGetter={statusStyleGetter} // Prazna funkcija za stil statusa
          
        />
      {/* Prikazivanje DataTable komponente sa podacima za zadatke za ovu nedelju */}
      <h3>{t("Tasks for this week")}</h3>
      <DataTable
        columns={[
          { label: t("StartTime").toUpperCase(), dataKey: "pocetak" },
          { label: t("Description").toUpperCase(), dataKey: "description" },
          { label: t("TaskName").toUpperCase(), dataKey: "imeZadatka" },
          { label: t("Location").toUpperCase(), dataKey: "lokacija" },
        ]}
        data={formattedData}
        actions={weekActions} // Prazan niz za akcije
        actionLabels={[t("Info")]} // Prazan niz za oznake akcija
        buttonStyle={buttonStyle} // Prazan objekat za stil dugmadi
        actionHandlers={weekactionHandlers} // Prazan objekat za rukovanje akcijama
      />

      {/* Prikazivanje DataTable komponente sa podacima za zadatke za danas */}
      <h3>{t("taskForToday")}</h3>
      <DataTable
         columns={[
          { label: t("StartTime").toUpperCase(), dataKey: "pocetak" },
          { label: t("Description").toUpperCase(), dataKey: "description" },
          { label: t("TaskName").toUpperCase(), dataKey: "imeZadatka" },
          { label: t("Location").toUpperCase(), dataKey: "lokacija" },
        ]}
        data={forTodayformattedData}
        actions={todayActions}
        actionLabels={[t("Info")]}// Prazan niz za oznake akcija
        buttonStyle={buttonStyle} // Prazan objekat za stil dugmadi
        actionHandlers={todayactionHandlers} // Prazan objekat za rukovanje akcijama
      />
    </Container>
  );
};

export default StaffHomeScreen;
